import React, { Component } from 'react';

import { Box, Button, Typography, Input, Icon, Menu, MenuItem, Select } from '@material-ui/core';

const options = ['Email Id', 'Course', 'Activity', 'Published to LMS?'];

class SearchForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            anchorEl: null,
            selectedIndex: 0,
            searchTerm: '',
        }
    }

    setAnchorEl = (element) => {
        this.setState({
            anchorEl: element
        })
    }

    setSelectedIndex = (index) => {
        this.setState({
            selectedIndex: index
        })
    }

    setSearchTerm = (value) => {
        this.setState({
            searchTerm: value
        })
    }

    handleClick = (event) => {
        this.setAnchorEl(event.currentTarget);
    }

    handleClose = () => {
        this.setAnchorEl(null);
    }

    handleMenuItemClick = (event, index) => {
        this.setSearchTerm('');
        this.setSelectedIndex(index);
        this.setAnchorEl(null);
    }

    handleInputChange = (event) => {
        this.setSearchTerm(event.target.value)
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let query = typeof (this.state.searchTerm) === "string" ? this.state.searchTerm.trim() : this.state.searchTerm;
        this.props.searchQuery(query, options[this.state.selectedIndex]);
    }


    render() {
        return (
            <form id="searchForm" noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                <Box display="flex">
                    <Box display="flex" mx={2} alignItems="center" flexWrap="nowrap" border={1} borderColor="grey.300" borderRadius={5}>
                        <Box bgcolor="grey.100" color="grey">
                            <Button size="large" color="default" style={{ textTransform: "none" }} disableRipple onClick={this.handleClick}>
                                <Typography noWrap component="span">{options[this.state.selectedIndex]}</Typography>
                                <Icon fontSize="small">expand_more</Icon>
                            </Button>
                        </Box>
                        <Box px={1} py="3px" minWidth={200} flexGrow={1} borderLeft={1} borderColor="grey.300">
                            {this.state.selectedIndex !== 3 &&
                                <Input
                                    onChange={this.handleInputChange}
                                    disableUnderline
                                    id="search"
                                    placeholder={`Search by ${options[this.state.selectedIndex]} ...`}
                                    fullWidth
                                    value={this.state.searchTerm}
                                >
                                </Input>
                            }

                            {this.state.selectedIndex === 3 &&
                                <Select
                                    onChange={this.handleInputChange}
                                    fullWidth
                                    disableUnderline
                                    value={this.state.searchTerm}>
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            }
                        </Box>
                        <Menu
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem
                                    className="highlight-bg-lightblue"
                                    key={option}
                                    selected={index === this.state.selectedIndex}
                                    onClick={event => this.handleMenuItemClick(event, index)}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Button disabled={this.props.isLoading || (this.state.selectedIndex !== 3 ? this.state.searchTerm.trim() === '' : this.state.searchTerm === '')} type="submit" variant="contained" size="large" color="primary">
                        <Box component="span" px={2}>Search</Box>
                    </Button>
                </Box>
            </form >
        )
    }
}

export default SearchForm;