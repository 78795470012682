import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

function Toast({ message, status }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const getSeverity = (activityStatus) => {
        switch (activityStatus) {
            case 'SUCCESSFULLY_UPLOADED':
            case 'SUCCESS':
            case 'IN_PROGRESS':
                return 'success';
            case 'SUCCESS_WITH_ERROR':
                return 'warning';
            case 'FAIL':
                return 'error';
            default:
                return 'info';
        }
    };

    useEffect(() => {
        closeSnackbar();

        const showSnackbar = () => {
            enqueueSnackbar(message, {
                variant: getSeverity(status),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000,
            });
        };

        if (message) {
            showSnackbar();
        }
    }, [enqueueSnackbar, message, status]);

    return null;
}

export default Toast;
