
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import {Dialog,DialogActions,DialogContent,DialogTitle,
    Typography,Button,IconButton,Box,Input,Radio,RadioGroup,FormControl,FormControlLabel} from '@material-ui/core';
import http from 'axios';




    const BootstrapDialog =  makeStyles((theme) => ({
        dialogClass:{
                '& .MuiDialogContent-root': {
                    padding: theme.spacing(2),
                },
                '& .MuiDialogActions-root': {
                    padding: theme.spacing(1),
                },
                '& .MuiDialog-paperWidthSm':{
                    maxWidth:'480px',
                    minWidth:'480px',
                    flexDirection:'column',
                    display:'flex',
                    boxShadow:'none'
                    
                },
               '& .MuiBackdrop-root':{
                backgroundColor:'rgba(52, 64, 84, 0.70)'
                }
                },
                button: {
                    backgroundColor: '#ffffff',
                    color: '#0B41AD',
                    border:'1px solid #0B41AD',
                    "&:hover": {
                        backgroundColor: "#0B41AD",
                        color: "#ffffff"
                    }
                },
                radio:{
                    '& .MuiRadio-colorSecondary.Mui-checked':{
                        color: '#2C6EF2'
                    }
                }
            }))


export default function DeleteAssessmentDialog(props) {
    const customDialog=BootstrapDialog();
    const [open, setOpen] = useState(props.open);
    const [deleteAssessmentData,setDeleteAssessmentData]=useState(props.deleteAssessmentData);
    

    

    const handleClose = () => {
        setOpen(false);
        props.handleDeleteDialogClose()
      };

    const deleteAssessment=()=>{
       
        http.post(`/integrations/ripplehire/delete_assessment?jobId=${deleteAssessmentData.rhJobId}&jobSource=${deleteAssessmentData.rhJobSource}&jobName=${deleteAssessmentData.rhJobName}`)
        .then((response)=>{
            if(response.status===200){
                if(response.data==="can not delete"){
                    props.handleDeleteDialogClose("Job role can't be deleted as some of the test takers are in shortlisted state",true,"FAIL")
                    // setToastMessage("You Can not delete this job ")
                    // setShowToast(true)
                }else{
                    props.handleDeleteDialogClose("Job Succefully Deleted ",true,"SUCCESS")
                    setTimeout(() => {
                                window.location.reload();
                            }, 4000);
                }
                console.log("delete Response",response.data);
            }
        })
        .catch((error)=>{
            props.handleDeleteDialogClose("Error While Deleting Job",true,"FAIL")
            // setToastMessage("Error While Deleting Job")
            // setShowToast(true)
            // setToastStatus("FAIL")
            console.error('Error:', error);
        })
        // .finally (()=>{
           
        //     setTimeout(() => {
        //         window.location.reload();
        //     }, 4000);
        // })
    }

    return(
        <div>
            <Dialog
            open={open}
            onClose={handleClose}
            className={customDialog.dialogClass}
           PaperProps={{
            style:{width:'480px',boxShadow:'none'}}}
            >
        <DialogTitle style={{padding:'16px 24px'}}>
            <Box style={{display:'flex',justifyContent:'space-between'}}>
           <Typography style={{fontSize:'16px',fontFamily:'NotoSans-Regular',color:'#001F52'}}>Alert</Typography>
           <IconButton aria-label="close"  style={{padding:'0px'}} onClick={handleClose} ><CloseIcon /></IconButton>
           </Box>
        </DialogTitle>
        
        <DialogContent style={{padding:'24px',flexDirection:'column',display:'flex',borderTop:'1px solid #EBEBEB',borderBottom:'1px solid #EBEBEB'}}>
        <Typography style={{fontSize:'14px',fontFamily:'NotoSans-Regular',color:'#001F52'}}>
        You are about to delete this assessment details.<br></br>
        All the related details will be deleted as well.<br></br><br></br>

        Do you still want to delete the details?
        </Typography>
          
        </DialogContent>
        <DialogActions style={{padding:'16px 24px'}}>
          <Button className={customDialog.button} style={{padding:'10px 16px',fontSize:'14px',fontFamily:'NotoSans-Regular',textTransform:'capitalize'}} onClick={handleClose}>
          No, Don’t Delete
          </Button>
          <Button style={{background:'#C53532',padding:'0px'}} onClick={deleteAssessment}>
          <Typography style={{padding:'10px 16px',fontSize:'14px',fontFamily:'NotoSans-Regular',color:'#fff'}}>Yes, Delete</Typography>
            </Button>
        </DialogActions>
      </Dialog>

     
        </div>
    )




}