import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import http from 'axios';
import EditNoteIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import MORE_APPS_CONFIG from '../../moreApps';
import InputAdornment from '@material-ui/core/InputAdornment';
import SerachIcon from '@material-ui/icons/Search';
import { Container, Box, Input, Icon, IconButton, Tooltip, Typography,Button,Grid } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    searchContainer: {
        marginBottom: 1
    },
    cell: {
        padding: '14px 45px', // Adjust the padding values here
    }
});

export default function AppList(props){
    const classes = useStyles();
    const classes1 = useStyles1();
    const [appList,setAppList]=useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');



    useEffect(() => {
      

        http.get('/integrations/apps/all-apps')
            .then((response) => {
                if (response.data) {
                    console.log("App List from Db...",response.data)
                    response.data.sort(function(a,b){
                        return a.name > b.name ? 1 : a.name <  b.name ? -1 : 0;
                    })
                    setAppList(response.data)
                    setFilteredData(response.data)
                }
            }).catch((error) => {
                
            })

    }, [])

    useEffect(() => {
        console.log("inside serch term")
        var filterdList = [...appList];
        filterdList = appList.filter((row) =>
            row.name.toLowerCase().includes(searchTerm.toLowerCase()));

        setFilteredData(filterdList);
    }, [searchTerm]);


    const addNewAppButtonClicked=()=>{
        props.addButtonClicked();
    }

    
    const handleEditButtonClick = (row) => {
        console.log("app Id..",row.id)
        // setLoading(true)
        http.get(`/integrations/apps/app?id=${row.id}`)
        .then((response) => {
            if (response.data) {
                console.log("get App from Db...",response.data)
                props.editButtonClicked(response.data);
            }
        }).catch((error) => {
            
        })
       
             
     }

    

     const handleSearchChange = (event) => {

        setSearchTerm(event.target.value);
    };

   

    return (<div>
        <Container component={Paper} maxWidth="false" style={{ position: 'sticky', top: '64px', zIndex: '1', height: '62px', padding:'11px 40px', borderBottom: 'solid 1px', borderBottomColor: '#BED3E4', boxShadow: 'none' }} >
            <div className={classes.searchContainer}>


                <Box style={{display:"flex",justifyContent:'space-between'}} >
                    <Box style={{display:'flex',alignItems:'center'}}>
                    <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath +'/images/integration/home.svg'} style={{paddingRight:'2px'}} alt="home"></img>
                    <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath +'/images/integration/chevron-right.svg'} style={{paddingRight:'2px'}} alt=""></img>
                    <Typography style={{ color: "#001F52", fontFamily: 'NotoSans-Regular',fontSize:'14px' }}>Global Settings</Typography>
                    <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath +'/images/integration/chevron-right.svg'} style={{paddingRight:'2px'}} alt=""></img>
                    <Typography style={{ color: "#001F52",  fontFamily: 'NotoSans-Regular',fontSize:'14px' }}>Integrations</Typography>
                    <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath +'/images/integration/chevron-right.svg'} style={{paddingRight:'2px'}} alt=""></img>
                    <Typography style={{ color: "#001F52",  fontFamily: 'NotoSans-Regular',fontSize:'14px' }}>Apps</Typography>
                    </Box>
                    <Box display="flex" mx={2} alignItems="center" flexWrap="nowrap" border={1} borderColor="#BED3E4" borderRadius={5} style={{ width: '460px', height: '40px', margin: '0px' }} >

                        <Box px={1} py="3px" minWidth={300} flexGrow={1} >


                            <Input
                                id="input-with-icon-adornment"
                                onChange={handleSearchChange}
                                disableUnderline
                                placeholder={`Search By Integration `}
                                fullWidth
                                value={searchTerm}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SerachIcon color='#4E6287' />
                                    </InputAdornment>
                                }
                                color= '#8096B2'
                                style={{ fontFamily: 'NotoSans-Medium', color: '#8096B2' }}
                            />

                        </Box>

                    </Box>
                </Box>
            </div>
        </Container>
    <TableContainer component={Paper}>

            <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: '#f8fafc'}} >
                    <TableRow >
                        {/* <TableCell className={classes.cell}>ID</TableCell> */}
                        <TableCell className={classes.cell} style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular',width:'10px' }}>
                        <Typography style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular',paddingLeft:'8px' }}> Id</Typography>

                        </TableCell>
                        <TableCell  style={{ color: '#4E6287', fontSize: '14px',fontFamily:'NotoSans',paddingLeft:'20px' }}>
                        <Typography style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular',paddingLeft:'8px' }}>App Name</Typography>
                        </TableCell>
                        {/* <TableCell className={classes.cell}>Email</TableCell> */}
                        
                        <TableCell className={classes.cell} style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular' }} align='right'>
                        <Grid container style={{marginTop:'5px'}}>
                            <Grid item xs={6}>
                            <Button variant="outlined" size="medium" onClick={() => addNewAppButtonClicked()} className={classes1.myClassName}
                             style={{ marginLeft: '120%', fontSize: '14px', fontFamily:'NotoSans-Regular', fontWeight: '500', textTransform: 'capitalize', width: 'max-content', border: '1px solid', borderColor: '#002c77' }}
                                                >
                                               <Typography style={{  fontSize: '14px', fontFamily: 'NotoSans-Regular' }}>Add </Typography>
                            </Button>
                            </Grid>
                            <Grid item xs={6} style={{paddingTop:'5px'}}>
                            <Typography style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular',paddingLeft:'8px' }}>Action
                            </Typography>
                        </Grid>
                        </Grid>
                        </TableCell>
                    </TableRow>


                </TableHead>
                <TableBody>
                    {filteredData.map((row,index) => (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {/* <TableCell className={classes.cell}>{row.id}</TableCell> */}
                            <TableCell className={classes.cell}>
                                <Box display="flex" flexWrap="nowrap" >
                                <Typography style={{ color: '#4E6287', fontFamily: 'NotoSans-Regular', fontSize: '16px' }}>
                                {index+1}
                                    </Typography>
                                   
                                </Box>
                            </TableCell>
                            {/* <TableCell className={classes.cell}>{row.email}</TableCell> */}
                            <TableCell >
                                <Box display="flex" flexWrap="nowrap" >
                                <Typography style={{ color: '#4E6287', fontFamily: 'NotoSans-Regular', fontSize: '16px'}}>
                                        {row.name}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align='right'>
                         <MsbTooltip title="Edit" arrow>
                                  <IconButton style={{ color: '#0B41AD' }} onClick={() => { handleEditButtonClick(row) }}>
                                       <EditNoteIcon></EditNoteIcon>
                                    </IconButton>
                                </MsbTooltip>
                
                                {/* <MsbTooltip title="Delete" arrow>
                                  <IconButton style={{ color: '#0B41AD' }} onClick={() => { handleDeleteButtonClick(row) }}>
                                       <DeleteIcon color='error'></DeleteIcon>
                                    </IconButton>
                                </MsbTooltip> */}
                               

                            </TableCell> 
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        {/* <Container component={Paper} maxWidth="false" style={{ bottom:'1px', position: 'sticky', zIndex: '1',background:'#00000000' }} >
            <div >


                 <Box display="flex" style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                    <Box display="flex" mx={2} alignItems="center" flexWrap="nowrap"  style={{ width: '485px', height: '40px' }} >

                        <Box px={1} py="3px" minWidth={300} flexGrow={1} borderLeft={1} borderColor="grey.300">
                        <MsbTooltip title="Create">
                        <IconButton style={{ color: '#0B41AD' }} onClick={() => { handleEditButtonClick() }}>
                        <AddCircleIcon fontSize='large'></AddCircleIcon>
                                    </IconButton>
                                </MsbTooltip>
                    

                         </Box>

                    </Box>
                </Box> 
            </div>
        </Container> */}
        </div>
    )
}



const MsbTooltip = withStyles((theme) => ({
    tooltip: {

        backgroundColor: '#002c77',
        color: '#fffff',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        fontFamily: 'NotoSans-Regular'
    },
    arrow:{
        color: '#002c77'
    }
}))(Tooltip);


const useStyles1 = makeStyles((theme) => ({
    myClassName: {
        backgroundColor: '#f8fafc',
        color: '#4E6287',
        "&:hover": {
            backgroundColor: '#0B41AD',
            color: '#ffffff'
        }
    }
}));