import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Box, Input } from '@material-ui/core';
import MORE_APPS_CONFIG from '../../moreApps';

import CloseIcon from '@material-ui/icons/Close';


const BootstrapDialog = makeStyles((theme) => ({
  dialogClass: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paperWidthSm': {
      minWidth: '480px',
      flexDirection: 'column',
      display: 'flex',
      boxShadow: 'none'

    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(52, 64, 84, 0.70)'
    }
  },
  buttonClass: {
    backgroundColor: '#ffffff',
    color: '#0B41AD',
    "&:hover": {
      backgroundColor: "#0B41AD",
      color: "#ffffff"
    },
    border: '1px solid #0B41AD',
    borderRadius: '4px',
    fontFamily: 'NotoSans-Regular',
    fontSize: '14px'

  },
}));

export default function AtsRedirectDialog(props) {
  const [open, setOpen] = React.useState(props.openResponseDialog);
  const customDialogClass = BootstrapDialog();
  const [isCopied, setIsCopied] = useState(false);

  

  
  






  const handleClose = () => {
    setOpen(false);
    props.responseDialogHandleClose()
  };

  
  const handleProceedClick=()=>{
    if(props.selectedAppData.name.toLowerCase()==="smartrecruiters"){
        window.open("https://www.smartrecruiters.com/account/sign-in", "_blank")
    }
  }

  useEffect(() => {
    console.log("inside response==>", props.atsDialogData)}
   )

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog className={customDialogClass.dialogClass}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{ padding: '0px' }}>
          <Box style={{ padding: '20px 32px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch' }}>
            
              <Typography style={{ fontFamily: 'NotoSans-Medium', fontSize: '16px', color: '#001F52' }}> Integration with {props.selectedAppData.name} </Typography>
           
            <IconButton style={{ fontFamily: 'NotoSans-Regular', color: '#001F52', padding: '0px', borderRadius: '0px' }} onClick={handleClose}><CloseIcon /></IconButton>
          </Box>
        </DialogTitle>

       
        <DialogContent style={{ padding: '24px 32px', alignSelf: 'stretch' }} dividers>
        
          <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#001F52', paddingBottom: '8px' }}>
          Do you want to Redirect to {props.selectedAppData.name} ?
          </Typography>


          
        </DialogContent>
      
        <DialogActions style={{ display: 'flex', padding: '16px 32px', alignItems: 'center', gap: '12px', alignSelf: 'stretch', justifyContent: 'flex-end' }}>
          <Button className={customDialogClass.buttonClass} style={{ padding: '10px 16px', gap: '8px', alignItems: 'center', display: 'flex', textTransform: 'capitalize', }} autoFocus onClick={handleClose}>
            Close
          </Button>
            <Button style={{
              padding: '10px 16px', gap: '8px', alignItems: 'center', display: 'flex', textTransform: 'capitalize', backgroundColor: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', border: '1px solid #0B41AD',
              borderRadius: '4px', color: '#ffffff'
            }} autoFocus onClick={() => { handleProceedClick() }}>
             Proceed
            </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}