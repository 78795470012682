import React, { useEffect,useState } from 'react'
import Alert from '@material-ui/lab/Alert/Alert';
import http from 'axios';
import {
    BrowserRouter as Router,
  Switch,
  Route,
  useLocation
  } from "react-router-dom"

import {
    Box, Container, Button
} from '@material-ui/core'


export default function SmartRecruitersAuthorize(props){
    const [open,setOpen] =useState(false);
    const [msg,setMsg] =useState("");
    const [user,setUser]= useState({
        firstName:"",
        lastName:"",
        emailId:"",
        validIntegrationsUser:false,
        clientEnabled:false,
        appEnabled:false,
        clientDto :{}
    });
    const [redirectUrl,setRedirectUrl]=useState("");
    const [companyId,setCompanyId]=useState("");
   
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(()=>{
    getCorporateUser();

    setRedirectUrl(queryParams.get("redirect"));
    setCompanyId(queryParams.get("companyId"))
    },[])

    useEffect(()=>{
        console.log("User..",user)
    },[user])

const getAuthorize=()=>{
    if(!user.validIntegrationsUser){
        setMsg("Please contact your administrator for permission to configure integrations.")
        setOpen(true)
    }else if(!user.clientEnabled){
        setMsg("Please contact your account manager to get integration enabled for your account.")
        setOpen(true)
    }else{
        setOpen(false)
        getAuthorizeUser();
    }
    
}

const getAuthorizeUser =() =>{
    http.post("/integrations/ats/smartrecruiters/authorize_client?companyId="+companyId)
      .then((response) => {console.log("user data..",response.data)
        if(response.data){
           
            console.log("user authorized..",response.data)
            if(response.data==="Integration Enabled"){
                window.location.href=redirectUrl
            }
        }else{
           
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status==401){
         
        }
      });
}
const getCorporateUser = () => {
    
    http.get("/integrations/user")
      .then((response) => {console.log("user data..",response.data)
        if(response.data){
            setUser(response.data)
            console.log("user authorized..",user)
        }else{
           
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status==401){
         
        }
      });
  }

    return (
        <div>
        <Container className="authorize-box" style={{ marginTop: '250px'}}>   
            <Box px={5} py={2} sx={{
                    width: 320,
                    height: 'auto',
                    minHeight:100,
                    marginTop: -75,
                    borderBottom: '4px solid #002C77',
                    boxShadow: '0px 0px 5px grey',
                    borderRadius: 5,
                    backgroundColor: 'white',
                    textAlign : 'center'
                }
            }>
                    <Box component="span" sx={{color:'#002C77', fontWeight:800}}>Enable your SmartRecruiters integration</Box>
                    <br></br>
                    <small>By clicking below, you will be enabling the integration between your Mercer | Mettl account with primary login email ID: {user.clientDto.email} </small><small style={{paddingLeft:'60px',display:'flex'}}>and your account</small>
                    <Button variant="contained" className="authorize-button bg-mettl-blue" mx={5}style={{textTransform:'initial'}} onClick={getAuthorize}>Enable</Button>
            </Box>
            
        </Container>
        <Container style={{justifyContent:'center',display:'flex',marginTop:'5px'}} > 
       { open && <Box style={{width:'400px'}}>
            <Alert onClose={() => {setOpen(false),setMsg("")}} severity="error">{msg}</Alert>
            </Box>}
        </Container>
        </div>
      
        )
}