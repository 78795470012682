import React from 'react';

import { Box, Grid, Icon, Select, Switch, Button,Input, Checkbox, Typography,
    Hidden,IconButton,Tooltip } from '@material-ui/core';
 import { withStyles} from "@material-ui/core/styles";
//import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';
import DialogComponent from './shared/dialogComponent';
import http from 'axios';


export default class ScheduleSettingUI extends React.Component {

constructor(props) {
    super(props);

    this.state = {
        showAddAssessmentModal: false,
        browsingToleranceCount: this.props.assessmentSetting.apiWebProctoring.enabled ?
            this.props.assessmentSetting.apiWebProctoring.count : "",
        visualProctring: this.props.assessmentSetting.proctoring.mode === "OFF" ? false : true,
        remainingCountEnabled: this.props.assessmentSetting.apiWebProctoring.showRemainingCounts,
        showCustomInput: false,
        showRemainingWarningCheckbox: this.props.assessmentSetting.apiWebProctoring.showRemainingCounts ? true : false,
        assessmentSetting: this.props.assessmentSetting,
        maxReattemptAllowed:this.props.assessmentSetting.maxReattemptAllowed== "" ? 1:this.props.assessmentSetting.maxReattemptAllowed,
        passPercentage:this.props.assessmentSetting.passPercentage== "" ? 0:this.props.assessmentSetting.passPercentage,
        cooldownPeriod:this.props.assessmentSetting.cooldownPeriod== "" ? 0:this.props.assessmentSetting.cooldownPeriod,
        days:0,
        hours:0,
        isCustomBtCount: false,
        updateAssesment:this.props.isScheduled,
        isReattemptSettings:this.props.assessmentSetting.maxReattemptAllowed >1 ? true:false,
        candidateAuthorization: this.props.assessmentSetting.proctoring.options.candidateAuthorization,
        candidateScreenCapture:this.props.assessmentSetting.proctoring.options.candidateScreenCapture,
        secureBrowser:this.props.assessmentSetting.secureBrowser.enabled,
        check360:this.props.assessmentSetting.proctoring.options.check360,
        mobileProctoring:this.props.assessmentSetting.proctoring.options.mobileProctoring
    }

}
    
componentDidMount = () => {
    this.setOpen(this.props.isOpen);
    this.checkIfCustomBtCount(this.state.browsingToleranceCount);
    if(this.props.assessmentSetting.cooldownPeriod!=0){
        let cooldownP=Number(this.props.assessmentSetting.cooldownPeriod);
        if(cooldownP % 24 ===0){
            this.setState({
                days: Math.trunc(Number(cooldownP/24)),
                hours:0
            });
        }else{
            this.setState({
                days:Math.trunc(Number(cooldownP/24)),
                hours:Number(cooldownP % 24)
            });
        }
    }
}

setOpen = (isOpen) => {
    this.setState({
        showAddAssessmentModal: isOpen
    });
}

checkIfCustomBtCount = (value) => {
    const defaultOptions = ["", 0, 1, 2, 4, 10];
    this.setState({
        isCustomBtCount: !defaultOptions.includes(parseInt(value)) && value !== "" ? true : false,
    });
}

handleCustomWarningChange = (event) => {
    let { name, value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    if (name === "customWarning") {
        this.setState(({
            browsingToleranceCount: value,
        }));
    }
}

handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let showInput = false;
    let showRemainingWarnCheck = false;
    console.log("name,value,type,checked==>",name,value,type,checked)
    if (type === "checkbox") {
        this.setState(({
            [name]: checked ? true : false,
        }))

        if(name==="visualProctring" && !checked){
            this.setState(({
                candidateAuthorization:false,
                candidateScreenCapture:false,
                check360:false,
                mobileProctoring:false
            }))
        }
    }
    else {
        if (name === "browsingToleranceCount") {
            this.checkIfCustomBtCount(value);
        }
        if (name === "browsingToleranceCount" && value == 11) {
            showInput = true;
        }
        if (name === "browsingToleranceCount" && value > 0) {
            showRemainingWarnCheck = true;
        }
        if(name ==="maxReattemptAllowed"){
            event.target.value=Math.trunc( value );
            if(value<=1){
            event.target.value=1
            this.setState({ 
                isReattemptSettings:false,
                passPercentage:0,
                cooldownPeriod:0,
                days:0,
                hours:0
            })
        }else if(value>99){
            event.target.value=99
        }else{
            
            
            //Math.trunc( value )
            this.setState({ 
                isReattemptSettings:true
                
            })
        }
    }  
        
        if(name ==="passPercentage"){
        
            event.target.value=Math.trunc( value );
            //parseFloat(value).toFixed(2);
            console.log( event.target.value)
            
            if(value<0){
                event.target.value=0
            }else if(value>100){
                event.target.value=100
            }
            
        }
        // if(name==="cooldownPeriod"){
        //     event.target.value=Math.trunc( value );
        //     if(value<0){
        //         event.target.value=0
        //     }else if(value>999){
        //         event.target.value=999
        //     }
            
        // }

        if(name==="days"){
            event.target.value=Math.trunc( value );
            if(value<0){
                event.target.value=0
            }else if(value>999){
                event.target.value=999
            }

            if(this.state.hours===0){
                this.setState({
                    cooldownPeriod:event.target.value * 24
                });
            }else{
                let hours=this.state.hours ;
                let daysIntoH=event.target.value *24;
                let totalHours= Number(daysIntoH) + Number(hours); 
                this.setState({
                    cooldownPeriod:totalHours
                });
            }
            
        }

        if(name==="hours"){
            event.target.value=Math.trunc( value );
            if(value<0){
                event.target.value=0
            }else if(value>23){
                event.target.value=23
            }
            if(this.state.days==0){
                this.setState({
                    cooldownPeriod:event.target.value
                });
            }else{
            let days=this.state.days ;
                let daysIntoH=days *24;
            let  totalHours= Number(daysIntoH) + Number(event.target.value); 
                this.setState({
                cooldownPeriod:totalHours
                });
            }
            
        }
        this.setState(({
            [name]: event.target.value,
            showCustomInput: showInput,
            showRemainingWarningCheckbox: showRemainingWarnCheck,
        }));
    }
}

createSchedule = (assessment, settings) => {
    var _this = this;
    var bodyFormData = new FormData();
    bodyFormData.set('assessmentId', assessment.id);
    bodyFormData.set('assessmentSetting', JSON.stringify(settings));
    bodyFormData.set('updateSchedule', this.state.updateAssesment);

    http.post("/lti/create-schedule", bodyFormData)
        .then((response) => {
            _this.props.onClose(assessment, settings,this.state.days,this.state.hours);
        })
        .catch((error) => {
            console.log(error);
        });
}

// updateAssessmentSetting = (btc, vp, showRemaining,mra,pp,cdp) => {

//     this.setState(prevState => ({
//         assessmentSetting: {
//             ...prevState.assessmentSetting,
//             proctoring: {
//                 ...prevState.assessmentSetting.proctoring,
//                 mode: vp ? "VIDEO" : "OFF"
//             },
//             apiWebProctoring: {
//                 ...prevState.assessmentSetting.apiWebProctoring,
//                 count: btc === "" ? 0 : parseInt(btc),
//                 enabled: btc === "" ? false : true,
//                 showRemainingCounts: showRemaining
//             },
//             maxReattemptAllowed:mra,
//             passPercentage:pp,
//             cooldownPeriod:cdp
//         }
//     }), () => {
//         this.createSchedule(this.props.assessment, this.state.assessmentSetting);
//     });

// }

updateAssessmentSetting = (stateObj) => {

    this.setState(prevState => ({
        assessmentSetting: {
            ...prevState.assessmentSetting,
            proctoring: {
                ...prevState.assessmentSetting.proctoring,
                mode: stateObj.visualProctring ? "PHOTO" : "OFF",
                options:{
                    ...prevState.assessmentSetting.proctoring.options,
                    candidateAuthorization: stateObj.candidateAuthorization,
                    candidateScreenCapture:stateObj.candidateScreenCapture,
                    check360:stateObj.check360,
                    mobileProctoring:stateObj.mobileProctoring
                }
            },
            apiWebProctoring: {
                ...prevState.assessmentSetting.apiWebProctoring,
                count: stateObj.browsingToleranceCount === "" ? 0 : parseInt(stateObj.browsingToleranceCount),
                enabled: stateObj.browsingToleranceCount === "" ? false : true,
                showRemainingCounts: stateObj.remainingCountEnabled
            },
            secureBrowser:{
                ...prevState.assessmentSetting.secureBrowser,
                enabled:stateObj.secureBrowser
            },
            maxReattemptAllowed:stateObj.maxReattemptAllowed,
            passPercentage:stateObj.passPercentage,
            cooldownPeriod:stateObj.cooldownPeriod
        }
    }), () => {
        this.createSchedule(this.props.assessment, this.state.assessmentSetting);
    });

}
 

handleClose = (isSaved) => {
    this.setOpen(false);
    if (isSaved) {
        // this.updateAssessmentSetting(this.state.browsingToleranceCount, this.state.visualProctring, this.state.remainingCountEnabled,
        //     this.state.maxReattemptAllowed,this.state.passPercentage,this.state.cooldownPeriod);
        this.updateAssessmentSetting(this.state);
    } else {
        this.props.onClose();
    }
};

 

scheduleDialogContent = () => {
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Box display="flex" alignItems="center" pt={1}>
                    <Icon>web</Icon>
                    <Typography style={{ paddingLeft: 5 }}>Mettl Secure Browser</Typography>
                <MsbTooltip  placement="top" interactive title={
                    <React.Fragment>
                        <Typography style={{fontSize:"12px"}}>This is a custom browser which turns candidate's system into a lock-down mode when the 
                        assessment is running. It enables multiple security features which prevents candidates to access unauthorized resources on his/her machine</Typography>
                 <Typography style={{textAlign:"center",color:"#fffff",fontSize:"12px"}}>
                    {<a style={{color:'#f44336'}} target="_blank" href='https://support.mettl.com/portal/en/kb/articles/mettl-secure-browser-msb' >Know More</a>}</Typography>
                    </React.Fragment>}
                 arrow>
                <IconButton aria-label="info" size="small" color="disabled">
                                    <Icon fontSize="inherit">info</Icon>
                                    </IconButton>
                </MsbTooltip>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                    <Switch
                        name="secureBrowser"
                        checked={this.state.secureBrowser}
                        onChange={this.handleChange}
                        color="primary"
                        inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                    />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box pt={1} display="flex" alignItems="center">
                    <Icon>credit_card</Icon>
                    <Typography style={{ paddingLeft: 5 }}>Browsing Tolerance</Typography>
                    <MsbTooltip interactive placement='top' sx={{style:{color:"black"}}}  
                                title={
                                    <Typography style={{fontSize:"12px"}}>
                                    Not recommended for Upload Type questions in test
                                    </Typography>} arrow>
                                    <IconButton aria-label="info" size="small" color="disabled">
                                    <Icon fontSize="inherit">info</Icon>
                                    </IconButton>
                                    
                    </MsbTooltip>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box mb={1} pl={1} display="flex" alignItems="center">
                    <Select
                        native
                        displayEmpty={true}
                        onChange={this.handleChange}
                        name="browsingToleranceCount"
                        defaultValue={this.state.browsingToleranceCount}
                    >
                        <option value="">Not Applicable</option>
                        <option value={0} >No Warning</option>
                        <option value={1}>Very low (1 warning)</option>
                        <option value={2}>Low (2 warnings)</option>
                        <option value={4}>Medium (4 warnings)</option>
                        <option value={10}>High (10 warnings)</option>
                        <option value={!this.state.isCustomBtCount ? 11 : this.state.browsingToleranceCount}>Custom Warning</option>
                    </Select>

                    {(this.state.showCustomInput || this.state.isCustomBtCount) &&

                        <Box component="span" width={50} ml={1}>
                            <Input type="number" name="customWarning" value={this.state.browsingToleranceCount}
                                onChange={this.handleCustomWarningChange} inputProps={{ min: 1, max: 99 }}></Input>
                        </Box>

                    }

                    {(this.state.showRemainingWarningCheckbox || this.state.browsingToleranceCount > 0) &&

                        <React.Fragment>
                            <Checkbox
                                onChange={this.handleChange}
                                checked={this.state.remainingCountEnabled}
                                name="remainingCountEnabled"
                                color="primary"
                                inputProps={{
                                    'aria-label': 'remaining warnings checkbox',
                                }}
                            />
                            <Typography variant="body2">Show remaining</Typography>
                        </React.Fragment>

                    }

                </Box>
            </Grid>

            <Grid item xs={6}>
                <Box display="flex" alignItems="center" pt={1}>
                    <Icon>videocam</Icon>
                    <Typography style={{ paddingLeft: 5 }}>Advanced Visual Proctoring</Typography>
                    <MsbTooltip interactive placement='top'
                                title={<Typography style={{fontSize:"12px"}}>
                                    You can watch candidate's live while taking the test or view 
                                 recorded snapshots in the candidate's report on demand. Mettl marks instances of candidate's suspicious activities using it's Artificial Intelligence algorithm
                                 </Typography>} arrow>
                                    <span>
                                    <IconButton aria-label="info" size="small" color="disabled" >
                                    <Icon fontSize="inherit">info</Icon>
                                    </IconButton>
                                    </span>
                                </MsbTooltip>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                    <Switch
                        name="visualProctring"
                        checked={this.state.visualProctring}
                        onChange={this.handleChange}
                        color="primary"
                        inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                    />
                    <Typography>{this.state.visualProctring ? 'Enabled' : 'Disabled'}</Typography>
                    {this.state.visualProctring &&
                        <Hidden only="xs">
                            <Box display="flex" alignItems="center" ml={1} fontSize={11}>
                                <span>Runs on :</span>
                                <span>
                                <MsbTooltip placement="top" interactive title={
                                <React.Fragment>
                                <Typography style={{fontSize:"12px"}}>We recommend candidates take the test in latest versions of Firefox, Chrome and Safari to provide a seamless experience. Candidates using older
                                versions of Firefox and Internet Explorer would be required to download a java applet.</Typography>
                                <Typography style={{textAlign:"center",color:"#0af3f7",fontSize:"12px",textDecoration:'none'}}>{<a style={{color:'#f44336'}} target="_blank" href='https://support.mettl.com/portal/en/kb/articles/system-requirement-for-mettl-assessment-platform' >Know More</a>}</Typography>
                                </React.Fragment>}
                                arrow>
                                <Button style={{fontSize:"11px"}}>
                                <span className="icon-browser icon-chrome"></span>
                                <span className="icon-browser icon-firefox"></span>
                                <span className="icon-browser icon-ie"></span>
                                <span className="icon-browser icon-safari"></span>
                                </Button>
                                </MsbTooltip>
                                </span>
                            </Box>
                        </Hidden>
                    }
                    </Box> 
                    {this.state.visualProctring &&
                    <React.Fragment>
                    <Grid container>
                        <Grid item xs={9} md={12}>
                            <Box display="flex" alignItems="center"  ml={1}>
                                <Checkbox size="small" name="candidateScreenCapture" color="primary" 
                                    checked={this.state.candidateScreenCapture} onChange={this.handleChange}  style={{padding: "1px",width: "10px", height: "10px"}}/>
                                <Typography style={{ fontSize:"10px",paddingLeft:"2px"}}>Capture Candidate Screen </Typography>
                                <MsbTooltip interactive placement='top' sx={{style:{color:"black"}}}  
                                title={<Typography style={{fontSize:"12px"}}>Best viewed in latest versions of Firefox and Chrome. The test will not run 
                                on mobile devices and safari browser if the capture candidate's screen option is selected.</Typography>} arrow>
                                    <IconButton aria-label="info" size="small" color="disabled">
                                    <Icon fontSize="inherit">info</Icon>
                                    </IconButton>
                                    
                                </MsbTooltip>
                            </Box>
                        </Grid>
                        <Grid item xs={12} pt={1}>
                            <Box display="flex" alignItems="center"  ml={1}>
                                <Checkbox type="checkbox" name="candidateAuthorization" color="primary" size="small"
                                checked={this.state.candidateAuthorization} 
                                    onChange={this.handleChange} style={{padding: "1px",width: "10px", height: "10px"}}/>
                                <Typography style={{ fontSize:"10px",paddingLeft:"2px"}}>Candidate Authorization </Typography>
                                <IconButton aria-label="info" size="small" color="disabled">
                                <Icon fontSize="inherit" >person</Icon>
                                </IconButton>
                            <Typography style={{ fontSize:"10px",color:"grey"}}>Requires Proctor Surveillance</Typography>
                            
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:"2px"}}>
                            <Box display="flex" alignItems="center"  ml={1}>
                                <Checkbox type="checkbox" name="check360" color="primary" size="small"
                                checked={this.state.check360} 
                                    onChange={this.handleChange} style={{padding: "1px",width: "10px", height: "10px"}}/>
                                <Typography style={{ fontSize:"10px",paddingLeft:"2px"}}>Mandatory 360 Check </Typography>
                                
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:"2px"}}>
                            <Box display="flex" alignItems="center"  ml={1}>
                                <Checkbox type="checkbox" name="mobileProctoring" color="primary" size="small"
                                checked={this.state.mobileProctoring} 
                                    onChange={this.handleChange} style={{padding: "1px",width: "10px", height: "10px"}}/>
                                <Typography style={{ fontSize:"10px",paddingLeft:"2px"}}>Mobile Camera Proctoring </Typography>
                                <MsbTooltip interactive  title={<Typography style={{fontSize:"12px"}}>
                        Candidate will be required to setup a mobile camera,
                        Audio/Video from mobile would be recorded on demand
                        and will be available in reports.
                            </Typography>} arrow>
                                <IconButton aria-label="info" size="small" color="disabled">
                                <Icon fontSize="inherit">info</Icon>
                                </IconButton>
                                
                            </MsbTooltip> 
                            </Box>
                        </Grid>
                        {/* <Grid item xs={6} pt={1}>
                            <Box display="flex" alignItems="center"  ml={1}>
                            <Icon sx={{size:"10px"}}>person</Icon>
                            <Typography style={{ fontSize:"8px"}}>Requires Proctor Surveillance</Typography>
                            </Box>
                        </Grid> */}
                    </Grid>
                    </React.Fragment> 
}
                
            </Grid>
            
            <Grid item xs={6}>
                <Box display="flex" alignItems="center" pt={1}>
                <Icon>autorenew</Icon>
                    <Typography style={{ paddingLeft: 5 }}>Attempt(s) Allowed</Typography>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{width:40}} display="flex" alignItems="center" ml={1}>
                    <Input type="number"  name= "maxReattemptAllowed" min="1" inputProps={{ style: {textAlign: 'center',paddingBottom:"3px",width:'35px' }}}
                    value={this.state.maxReattemptAllowed} onChange={this.handleChange}
                        />
                </Box>


                { this.state.isReattemptSettings &&
                <React.Fragment>
                <Grid container>
            <Grid item xs={5.5}>
                <Box display="flex" alignItems="center" pt={1} ml={1}>
                <Icon fontSize="small">percent</Icon>
                    <Typography style={{ paddingLeft: 5,fontSize:"10px"}}>Pass Percentage </Typography>
                    <MsbTooltip interactive placement='top' sx={{style:{color:"black"}}}  
                                title={<Typography style={{fontSize:"12px"}}>Entering a non-zero pass percentage means that if the candidate fails, they are allowed to attempt the assessment again until they exhaust the available attempts. No further attempts are allowed if the candidate passes.
                               <br></br> To allow multiple attempts regardless of the pass percentage, please enter a value of zero.</Typography>} arrow>
                                    <IconButton aria-label="info" size="small" color="disabled" style={{fontSize:"12px"}}>
                                    <Icon fontSize="inherit">info</Icon>
                                    </IconButton>
                                    
                                </MsbTooltip>
                </Box>
            </Grid>
                <Grid item xs={6}>
                <Box sx={{fontSize:"10px",width:40,paddingLeft:"0px"}} display="flex" alignItems="center" >
                    <Input inputProps={{ style: {fontSize:"10px", textAlign: 'center',paddingBottom:"6px",fontWeight:410 }}} type="number"  name= "passPercentage"  margin="none"
                    value={this.state.passPercentage} onChange={this.handleChange} />
                </Box>
            </Grid> 
            </Grid>
            <Grid container>
            <Grid item xs={5}>
                <Box  sx={{fontSize:"10px"}}display="flex" alignItems="center" pt={1} ml={1}>
                <Icon sx={{fontSize:"small"}}>access_time</Icon>
                    <Typography style={{ paddingLeft: 5,fontSize:"10px" }}>Cooldown Period</Typography>
                </Box>
            </Grid>

{/* {this.state.isReattemptSettings &&
            <Grid item xs={3}>
                <Box display="flex" alignItems="center" sx={{ width:40 }}>
                    <Input type="number"  name= "cooldownPeriod" 
                    value={this.state.cooldownPeriod} onChange={this.handleChange} />
                </Box>Day(s)
            </Grid>
            } */}
            <Grid item xs={3.5} >
                <Box sx={{fontSize:"8px",width:70 }} display="flex" alignItems="center"  >
                    <Input inputProps={{ style: {fontSize:"10px", textAlign: 'center',paddingBottom:"6px",fontWeight:410 }}} type="number"  name= "days" 
                    value={this.state.days} onChange={this.handleChange}/>
                    <Typography style={{fontSize:"10px",paddingLeft:"1px"}}> Day(s)</Typography>
                </Box> 
            </Grid>

            <Grid item xs={3.5}>
                <Box sx={{fontSize:"8px",width:70,paddingLeft:"5px" }} display="flex" alignItems="center"  >
                    <Input inputProps={{ style: {fontSize:"10px", textAlign: 'center',paddingBottom:"6px",fontWeight:410 }}} type="number"  name= "hours" 
                    value={this.state.hours} onChange={this.handleChange} />
                        <Typography style={{fontSize:"10px",paddingLeft:"1px"}}> Hour(s)</Typography>
                </Box>
            </Grid>
            </Grid>
            </React.Fragment>
                }
            </Grid>

            
        </Grid>
        
    
    )
}


render() {

    return (
        <div>
            {this.state.showAddAssessmentModal &&
                <DialogComponent
                    dialogTitle={this.props.assessment.name}
                    dialogContent={this.scheduleDialogContent}
                    dialogButtonText={this.props.isScheduled ? "Update" : "Add To Course"}
                    dialogButtonColor="primary"
                    open={this.state.showAddAssessmentModal} onClose={this.handleClose}
                ></DialogComponent>}
        </div>
    );
}
}


const MsbTooltip = withStyles((theme)=>({
    tooltip: {
        // backgroundColor: '#f5f5f9',
        // color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: '#3d3d40',
        color: '#fffff',
         boxShadow: theme.shadows[1],
        fontSize: 11,
    }
  }))(Tooltip);


  

  