import { Box, Container, Divider } from "@material-ui/core";
import React from "react";
const FullWidthContainerWrapper = (props) => {
    return (
        
            <Container sx={{ py: 5, ...props.sx }} maxWidth={false}>
                {props.children}
            </Container>


    );
};

export default FullWidthContainerWrapper;


