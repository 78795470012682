import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Typography,IconButton,Box} from '@material-ui/core';


import CloseIcon from '@material-ui/icons/Close';
import LmsIntegrationResponseDialog from './LmsIntegrationResponseDialog';
import http from 'axios';

const BootstrapDialog =  makeStyles((theme) => ({
    dialogClass:{
            '& .MuiDialogContent-root': {
                padding: theme.spacing(2),
            },
            '& .MuiDialogActions-root': {
                padding: theme.spacing(1),
            },
            '& .MuiDialog-paperWidthSm':{
                maxWidth:'480px',
                flexDirection:'column',
                display:'flex',
                boxShadow:'none'
                
            },
           '& .MuiBackdrop-root':{
            backgroundColor:'rgba(52, 64, 84, 0.70)'
            }
            },
            buttonClass: {
                backgroundColor: '#ffffff',
                color: '#0B41AD',
                "&:hover": {
                    backgroundColor: "#0B41AD",
                    color: "#ffffff"
                },
                border:'1px solid #0B41AD',
                borderRadius:'4px',
                fontFamily:'NotoSans-Regular',
                fontSize:'14px'
                
            },
}));

export default function LmsIntegrationProceedDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const [openResponseDialog, setopenResponseDialog] = React.useState(false);
  const customDialogClass = BootstrapDialog();
  const LmsData=props.lmsData;
  const [clientData,setClientData]=React.useState({
                                                    publickKey:"",
                                                    secret:"",
                                                    name:props.lmsData.name
                                                  })
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.onLmsDialogClose(false)
  };

  const onClickProceed=()=>{
    console.log("inside proceed button")
    setOpen(false);
    callingLmsConfig()
    
  }

  


  const callingLmsConfig=()=>{

  //   var bodyFormData = new FormData();
  // bodyFormData.set('appData', JSON.stringify(LmsData));
  // http.post("/integrations/apps/configure",  JSON.stringify(LmsData),{
  //   headers: {
  //         'Content-Type': 'application/json'
  //       }
  // })
  // .then((response) => {

  //     if (response.status == 200) {
  //       setClientData(
  //         (prevState) => {
  
  //           return ({
  //               ...prevState,
  //               publicKey: response.data['publicKey'],
  //               secret:response.data['secret']
                
  //           })
  //       }
  //       )
  //       console.log(response.data);
  //       setopenResponseDialog(true)
  //     }})
  //     .catch((error) => {
       
  //   });
  // }
  fetch("/integrations/apps/configure",{
    method:'post',
    body:JSON.stringify(LmsData),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => response.json())
    .then((data) => {
      setClientData(
        (prevState) => {

          return ({
              ...prevState,
              publicKey: data['publicKey'],
              secret:data['secret']
              
          })
      }
      )
      console.log(data);
      setopenResponseDialog(true)
      
    })
    .catch((error) => console.log(error));
  }




  return (
    <React.Fragment>
    
      <Dialog className={customDialogClass.dialogClass}
        onClose={handleClose}
       
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{padding:'0px'}}>
         <Box style={{padding:'20px 32px',display:'flex',alignItems:'center',justifyContent:'space-between',alignSelf:'stretch'}}>
         <Typography style={{fontFamily:'NotoSans-Medium',fontSize:'16px',color:'#001F52'}}> Integration with {LmsData.name}</Typography>
         <IconButton style={{fontFamily:'NotoSans-Regular',color:'#001F52',padding:'0px',borderRadius:'0px'}} onClick={handleClose}><CloseIcon /></IconButton>
         </Box>
        </DialogTitle>
        
        <DialogContent style={{display:'flex',padding:'24px 32px',alignItems:'center',gap:'24px',alignSelf:'stretch'}} dividers>
          <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'14px',color:'#001F52'}}>
          Do you want to integrate assessments in your Mercer | Mettl account with your {LmsData.name} LMS via LTI?
          </Typography>
         
        </DialogContent>
        <DialogActions style={{display:'flex',padding:'16px 32px',alignItems:'center',gap:'12px',alignSelf:'stretch',justifyContent:'flex-end'}}>
          <Button className={customDialogClass.buttonClass}style={{padding:'10px 16px',gap:'8px',alignItems:'center',display:'flex',textTransform: 'capitalize',}} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button className={customDialogClass.buttonClass} style={{padding:'10px 16px',gap:'8px',alignItems:'center',display:'flex',textTransform: 'capitalize',}} autoFocus onClick={onClickProceed}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
       {openResponseDialog && <LmsIntegrationResponseDialog  responseDialogHandleClose={handleClose} openResponseDialog={openResponseDialog} clientData={clientData}></LmsIntegrationResponseDialog>} 
    </React.Fragment>
  );
}