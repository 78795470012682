import { Box, Breadcrumbs, Button, Card, CardContent, Container, Grid, Input, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SerachIcon from '@material-ui/icons/Search';
import http from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MORE_APPS_CONFIG from '../moreApps';
import AiccSheduleSettingUI from './AiccScheduleSettingUI';
import CustomizedPagination from './shared/CustomizedPagination';
import CustomizedSnackbar from './shared/CustomizedSnackbar';


const useStyles1 = makeStyles((theme) => ({
    myClassName: {
        backgroundColor: '#ffffff',
        color: '#0B41AD',

        "&:hover": {
            backgroundColor: "#0B41AD",
            color: "#ffffff",
            "& span span img": {
                content: `url("${MORE_APPS_CONFIG.lmsFullPath}/download-hover.svg")`
            }
        }
    }
}));

const useStyles = makeStyles({

    searchContainer: {
        marginBottom: 25
        // marginTop: 20
    }
});


function handleClick(event) {
    event.preventDefault();
    //window.location.reload(true)
    window.location.href = '/integrations/'
}



export default function AssessmentList(props) {
    const classes = useStyles();
    const classes1 = useStyles1();
    const theme = useTheme();

    const [isUpdatCall, setIsUpdateCall] = useState(false)
    const [allAssessments, setAllAssessments] = useState(props.allAssessments)

    const [corporateUser, setCorporateUser] = useState(props.corporateUser)
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(props.allAssessments);
    const [showScheduleSettingModal, setShowScheduleSettingModel] = useState(false)
    const [openAllZip, setOpenAllZip] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [allAssessmentsZipList, setAllAssessmentsZipList] = useState([]);
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" });
    const [offset, setOffset] = useState(20)
    //const [numberOfPages,setNumberOfPages]=useState(1)
    const [listUpdating, setListUpdating] = useState(true)



    //pagination

    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [pageNumber, setPageNumber] = useState(1); // let's say

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(20);

    useEffect(() => {
        console.log("all assessments useeffect=>", allAssessments)
        // props.numberOfPages=Math.ceil(allAssessments.length/itemsPerPage)
    }, [allAssessments])

    const onChangePaginationProps = () => {

    }
    useEffect(() => {
        let responseArray = []
        if (props.isNextAvailable) {
            loadRemainingAssessments(offset, responseArray);
        }
    }, [])

    const loadRemainingAssessments = (offset, responseArray) => {
        let limit = 50;
        http.get('/integrations/aicc/assessments', { params: { limit: limit, offset: offset } })
            .then((response) => {
                if (response.data) {
                    let filterdAssessments = response.data.assessments.filter(val1 => {
                        return val1.assessmentType != "TASK" && val1.assessmentType != "PRACTICE"
                    })

                    if (responseArray.length === 0) {
                        responseArray = filterdAssessments;
                        // responseArray=response.data.assessments;
                    } else {
                        responseArray = responseArray.concat(filterdAssessments)
                        //responseArray=responseArray.concat(response.data.assessments)
                    }

                    console.log("response array", responseArray)

                    if (response.data.nextAvailable) {
                        let offSetLimit = offset + limit;
                        setOffset(offSetLimit)
                        loadRemainingAssessments(offSetLimit, responseArray)
                    } else {
                        setAllAssessments(allAssessments.concat(responseArray))
                        setListUpdating(false)
                    }

                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    props.redirectToLogin();
                }
            });
    }

    const onclickDownloadAssesment = (event, assessment) => {

        http.get('/integrations/aicc/link', {
            params: { assessmentId: assessment.id }
        })
            .then((response) => {
                if (response.data) {

                    if (response.data.length == 0) {
                        setOpen(true);
                        setOpenAllZip(false);
                        setIsUpdateCall(false)
                        setSelectedAssessment(assessment);
                        setShowScheduleSettingModel(true);
                    } else {
                        setOpen(false);
                        setIsUpdateCall(true)
                        setSelectedAssessment(assessment);
                        setAllAssessmentsZipList(response.data)
                        setOpenAllZip(true)
                        setShowScheduleSettingModel(true);
                    }



                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    props.isUserLoggedIn(false)
                    props.redirectToLogin();
                } else {
                    setSnackbarProps({ open: true, message: error.response.data.message, variant: "error" })
                }
            });


        // props.showScheduleSettingModal
    }

    const isUserLoggeInForSchedule = (val) => {
        props.isUserLoggedIn(val)
    }

    const handleCloseDialog = (val, snackbar) => {

        setSnackbarProps(snackbar)
        setShowScheduleSettingModel(false);
        setOpen(false)

    }



    const handleCloseSnackbar = () => {
        setSnackbarProps({ ...snackbarProps, open: false, variant: "", message: "" });

    }

    const handleSearchChange = (event) => {

        setSearchTerm(event.target.value);
    };


    useEffect(() => {

        if (searchTerm != "") {
            var filterdList = [...allAssessments];
            filterdList = allAssessments.filter((row) =>
                row.name.toLowerCase().includes(searchTerm.toLowerCase()));

            setFilteredData(filterdList);
        } else {
            var paginatedData = [...allAssessments];
            paginatedData = allAssessments.slice(startIndex, endIndex)
            setFilteredData(paginatedData);
        }
    }, [searchTerm]);


    //for pagination




    const onNextClick = (val) => {
        let obj = val
        if ((pageNumber + 1) <= Math.ceil(allAssessments.length / itemsPerPage)) {
            setPageNumber(pageNumber + 1)
            setStartIndex(obj.startIdx)
            setEndIndex(obj.endIdx)
            var filterdList = [...allAssessments];
            filterdList = filterdList.slice(obj.startIdx, obj.endIdx)
            console.log("start stop", obj.startIdx, obj.endIdx)
            console.log("filterdata", filterdList)
            setFilteredData(filterdList);
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }

    const onPrevsClick = (val) => {
        let obj = val
        if ((pageNumber - 1) >= 1) {
            setPageNumber(pageNumber - 1)
            setStartIndex(obj.startIdx)
            setEndIndex(obj.endIdx)
            var filterdList = [...allAssessments];
            filterdList = filterdList.slice(obj.startIdx, obj.endIdx)
            console.log("filterdata", filterdList)
            setFilteredData(filterdList);
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }

    //

    return (
        <React.Fragment>

            {snackbarProps.open &&
                <CustomizedSnackbar onClose={handleCloseSnackbar} {...snackbarProps}></CustomizedSnackbar>}

            <Container component={Paper} maxWidth="false" style={{ position: 'sticky', top: '64px', zIndex: '1', height: '62px', paddingLeft: '40px', paddingTop: '11px', paddingBottom: '11px', borderBottom: 'solid 1px', borderBottomColor: '#BED3E4', boxShadow: 'none' }} >
                <div className={classes.searchContainer}>


                    <Box display="flex" >
                        <Box display="flex" mx={2} alignItems="center" flexWrap="nowrap" border={1} borderColor="#BED3E4" borderRadius={5} style={{ width: '485px', height: '40px', margin: '0px' }}  >

                            <Box px={1} py="3px" minWidth={300} flexGrow={1}>


                                <Input
                                    id="input-with-icon-adornment"
                                    onChange={handleSearchChange}
                                    disableUnderline
                                    placeholder={`Search By Test Name `}
                                    fullWidth
                                    value={searchTerm}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SerachIcon color='#4E6287' />
                                        </InputAdornment>
                                    }
                                    style={{ color: '#8096B2', fontFamily: 'NotoSans-Medium' }}
                                />

                            </Box>

                        </Box>
                    </Box>

                </div>
            </Container>
            <Container component={Paper} maxWidth="false" style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: '126px', zIndex: '1', height: '52px', paddingLeft: '40px', paddingTop: '11px', paddingBottom: '11px', borderBottom: 'solid 1px', borderBottomColor: '#BED3E4', boxShadow: 'none' }} >
                <Box style={{ display: 'flex' }}>

                    <div role="presentation" onClick={handleClick}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb" >
                            <Link underline="hover" style={{ textDecoration: 'none', color: "#001F52", fontFamily: 'NotoSans-Regular' }} to={"/integrations"}>
                                Integrations
                            </Link>

                            <Typography style={{ color: "#001F52", fontWeight: 600, fontFamily: 'NotoSans-Regular' }}>AICC</Typography>
                        </Breadcrumbs>
                    </div>
                </Box>
            </Container>
            <Container style={{ marginBottom: '60px', paddingTop: '10px', paddingBottom: '10px' }}>
                <div>
                    {filteredData.map((elem) => (
                        <Box style={{ paddingTop: '15px' }}>
                            <Card sx={{ display: 'flex' }} style={{ height: '112px', boxShadow: 'none', border: 'solid 1px', borderColor: '#BED3E4' }}>
                                <Box style={{ display: 'flex' }}>
                                    <Box sx={{ display: 'flex', height: '80px', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '40px' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }} style={{ padding: '0px' }}>
                                            <Typography component="div" variant="h5" style={{ fontSize: '16px', fontWeight: '600', color: '#001F52', paddingLeft: '2px', fontFamily: 'NotoSans-Regular' }}>
                                                {elem.name}
                                            </Typography>
                                            <Grid container style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                                                <Grid item xs={6}>

                                                    <Button style={{ borderRadius: '50px', backgroundColor: '#8246af', color: '#ffff', fontSize: '12px', height: '20px', textTransform: 'capitalize', fontFamily: 'NotoSans-Regular', fontWeight: '500' }}>{elem.assessmentType.toLowerCase()}</Button>
                                                </Grid>

                                            </Grid>

                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <img height="20px" width="20px" src={MORE_APPS_CONFIG.lmsFullPath + '/Q.svg'} alt="" />
                                                <Typography style={{ fontSize: '14px', color: '#4E6287', fontFamily: 'NotoSans-Regular', paddingLeft: '8px', fontWeight: '400' }}>{elem.questionCount}</Typography><img style={{ margin: '0px 8px' }} height="20px" width="20px" src={MORE_APPS_CONFIG.lmsFullPath + '/Divider.svg'} alt="" /> <img height="20px" width="20px" src={MORE_APPS_CONFIG.lmsFullPath + '/Section.svg'} alt="" />
                                                <Typography style={{ fontSize: '14px', color: '#4E6287', fontFamily: 'NotoSans-Regular', paddingLeft: '8px', fontWeight: '400' }}> {elem.sectionCount}</Typography><img height="20px" width="20px" src={MORE_APPS_CONFIG.lmsFullPath + '/Divider.svg'} alt="" /> <img height="20px" width="20px" src={MORE_APPS_CONFIG.lmsFullPath + '/Time.svg'} alt="" />
                                                <Typography style={{ fontSize: '14px', color: '#4E6287', fontFamily: 'NotoSans-Regular', paddingLeft: '8px', fontWeight: '400', display: 'flex' }}> <b>{elem.hoursDuration}</b>hr&nbsp;<b>{elem.minsDuration}</b>min</Typography>

                                            </Box>
                                        </CardContent>

                                    </Box>
                                    <Box sx={{ display: 'flex', height: '80px', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '65%', alignItems: 'center' }}>
                                        <Button variant="outlined" size="medium" onClick={event => onclickDownloadAssesment(event, elem)} style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular', fontWeight: '500', textTransform: 'capitalize', width: '124px', height: '40px', border: '1px solid', borderColor: '#002c77' }}
                                            className={classes1.myClassName} startIcon={<img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/download.svg'} alt="" />}>
                                            <Typography style={{ fontFamily: 'NotoSans-Medium', fontSize: '14px' }}>Download</Typography>
                                        </Button>

                                    </Box>
                                </Box>
                            </Card>
                        </Box>))}
                </div>
            </Container>
            <Container component={Paper} maxWidth="false" style={{ paddingTop: '10px', paddingBottom: '10px', position: 'fixed', bottom: '0px', zIndex: '1', backgroundColor: '#fff', border: '1px solid', borderColor: '#BED3E4', borderRadius: '0px', boxShadow: '2px 2px 17px 6px rgb(0 0 0 / 12%)' }} >
                {/* <Pagination count={Math.ceil(allAssessments.length/itemsPerPage)} page={pageNumber} onChange={pageChange} size="large" variant="outlined" color="primary" /> */}

                <CustomizedPagination currentPage={pageNumber} numberOfPages={Math.ceil(allAssessments.length / itemsPerPage)} onNextClick={onNextClick} onPrevsClick={onPrevsClick} recordsPerPage={20} ></CustomizedPagination>
            </Container>

            {showScheduleSettingModal &&
                <AiccSheduleSettingUI open={open} isUpdatCall={isUpdatCall} openAllZip={openAllZip} allAssessmentsZipList={allAssessmentsZipList} selectedAssessment={selectedAssessment} handleCloseDialog={handleCloseDialog} isUserLoggeInForSchedule={isUserLoggeInForSchedule} redirectToLogin={props.redirectToLogin}></AiccSheduleSettingUI>}

        </React.Fragment >
    )




}