import React, { useState, useEffect }  from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Typography,IconButton,Box,Input} from '@material-ui/core';
import MORE_APPS_CONFIG from '../../moreApps';

import CloseIcon from '@material-ui/icons/Close';


const BootstrapDialog =  makeStyles((theme) => ({
    dialogClass:{
            '& .MuiDialogContent-root': {
                padding: theme.spacing(2),
            },
            '& .MuiDialogActions-root': {
                padding: theme.spacing(1),
            },
            '& .MuiDialog-paperWidthSm':{
                maxWidth:'480px',
                flexDirection:'column',
                display:'flex',
                boxShadow:'none'
                
            },
           '& .MuiBackdrop-root':{
            backgroundColor:'rgba(52, 64, 84, 0.70)'
            }
            },
            buttonClass: {
                backgroundColor: '#ffffff',
                color: '#0B41AD',
                "&:hover": {
                    backgroundColor: "#0B41AD",
                    color: "#ffffff"
                },
                border:'1px solid #0B41AD',
                borderRadius:'4px',
                fontFamily:'NotoSans-Regular',
                fontSize:'14px'
                
            },
}));

export default function LmsIntegrationResponseDialog(props) {
  const [open, setOpen] = React.useState(props.openResponseDialog);
  const customDialogClass = BootstrapDialog();
  const [isCopied, setIsCopied] = useState(false);
  




  


  const handleClose = () => {
    setOpen(false);
    props.responseDialogHandleClose()
  };


useEffect(()=>{
    console.log("inside response==>",props.clientData)
},[])

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog className={customDialogClass.dialogClass}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        
        
        <DialogContent style={{padding:'24px 26px',alignSelf:'stretch'}} dividers>
        <Box style={{ display:'flex', alignItems:'center',justifyContent:'space-between',alignSelf:'stretch',paddingBottom:'24px'}}>
         <Typography style={{fontFamily:'NotoSans-Medium',fontSize:'16px',color:'#001F52'}}> {props.clientData.name} Integration </Typography>
         <IconButton style={{fontFamily:'NotoSans-Regular',color:'#001F52',padding:'0px',borderRadius:'0px'}} onClick={handleClose}><CloseIcon /></IconButton>
         </Box>
        
          <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'14px',color:'#101828',paddingBottom:'16px'}}>
          Please use the below details to configure your LTI Tool on your {props.clientData.name} LMS​
          </Typography>
          <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'12px',color:'#4E6287',paddingBottom:'8px'}}>
          Tool Provided Url
          </Typography>
        <Box style={{ display:'flex', alignItems:'center',justifyContent:'space-between',alignSelf:'stretch',padding:'10px 16px',gap:'10px',border:'1px solid #D0D5DD',borderRadius:'4px',marginBottom:'30px'}}> 
        <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'14px',color:'#344054'}}>
          {MORE_APPS_CONFIG.lmsFullPath}/lti/launch
          </Typography>
             <IconButton variant="outlined" size="medium" onClick={() => {navigator.clipboard.writeText(`${MORE_APPS_CONFIG.lmsFullPath}`+'/lti/launch')}} style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular',borderRadius:'0px',padding:'0px' }}>
                                           <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/copy.svg'} alt="" />
            </IconButton>                                               
          {/* <Button >Copy to Clipboard</Button>
      {isCopied && <p>Text copied to clipboard!</p>} */}
      </Box> 
          {/* <Box style={{ display:'flex', alignItems:'center',justifyContent:'space-between',alignSelf:'stretch',paddingBottom:'26px'}}> */}
          <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'12px',color:'#4E6287',paddingBottom:'8px'}}>
          LTI Consumer Key
          </Typography>
        <Box style={{ display:'flex', alignItems:'center',justifyContent:'space-between',alignSelf:'stretch',padding:'10px 16px',gap:'10px',border:'1px solid #D0D5DD',borderRadius:'4px',marginBottom:'30px'}}> 
        <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'14px',color:'#344054'}}>
          {props.clientData.publicKey}
          </Typography>
             <IconButton variant="outlined" size="medium" onClick={() => {navigator.clipboard.writeText(props.clientData.publicKey)}} style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular',borderRadius:'0px',padding:'0px' }}>
                                           <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/copy.svg'} alt="" />
            </IconButton>                                               
          {/* <Button >Copy to Clipboard</Button>
      {isCopied && <p>Text copied to clipboard!</p>} */}
      </Box> 
      <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'12px',color:'#4E6287',paddingBottom:'8px'}}>
          LTI Consumer Secret
          </Typography>
      <Box style={{ display:'flex', alignItems:'center',justifyContent:'space-between',alignSelf:'stretch',padding:'10px 16px',gap:'10px',border:'1px solid #D0D5DD',borderRadius:'4px',marginBottom:'20px'}}> 
        <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'14px',color:'#344054'}}>
        {props.clientData.secret}
          </Typography>
             <IconButton variant="outlined" size="medium" onClick={() => {navigator.clipboard.writeText(props.clientData.secret)}} style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular',borderRadius:'0px',padding:'0px' }}>
                                           <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/copy.svg'} alt="" />
            </IconButton> 
       </Box> 
        </DialogContent>
       
      </Dialog>
    </React.Fragment>
  );
}