import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Container, Box, Input, Icon, IconButton, Tooltip, Typography, Paper, InputLabel,
    Card, CardContent, Button, CardActions, Grid, Switch, Checkbox, Select, FormControl, TextField
} from '@material-ui/core';

import useHistory from 'react-router-dom'

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CloseIcon from '@material-ui/icons/Close'
import PauseIcon from '@material-ui/icons/Pause';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import http from 'axios';
import axios from 'axios';
import MORE_APPS_CONFIG from '../moreApps';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const useStyles1 = makeStyles((theme) => ({
    myClassName: {
        backgroundColor: '#ffffff',
        color: '#0B41AD',
        "&:hover": {
            backgroundColor: "#0B41AD",
            color: "#ffffff"
        }
    },
    downloadImg: {
        backgroundColor: '#ffffff',
        color: '#0B41AD',
        "&:hover": {
            backgroundColor: "#0B41AD",
            color: "#ffffff",
            "& span img": {
                content: `url("${MORE_APPS_CONFIG.lmsFullPath}/download-hover.svg")`
            }
        }

    },
    inputBoxUnderline: {
        '& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root:after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:visited:not(.Mui-disabled):after': {
            borderBottom: 'none',
        },
        '& .MuiInputBase-root-MuiInput-root:after': {
            borderBottom: 'none',
        }
    },
    checkboxColor: {
        "&.MuiCheckbox-colorPrimary.Mui-checked": {
            color: '#2C6EF2'
        }
    },
    materialUISwitch: {

        '& .MuiSwitch-thumb': {
            width: '16px',
            height: '16px',
            color: '#ffffff',
            border: '1px solid #2C6EF2',
            borderRadius: '2px',
            boxShadow: 'none',
            marginTop: '2px'

        },
        '& .MuiSwitch-track': {
            backgroundColor: '#BED3E4',
            opacity: 1,
            // border: '1px solid #2C6EF2',
            borderRadius: '2px',
            height: '15px',
            width: '32px'
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#2C6EF2',
            opacity: 1
        }
    }
}));

const useStyles = makeStyles({

    dialogBoxContainer: {
        marginBottom: '25px',
        marginTop: '16px'
    },
    table: {
        minWidth: 595,
        "& .MuiTableCell-root": {
            borderBottom: "1px solid #bed3e4"
        }

    },
    cell: {
        padding: '12px 24px', // Adjust the padding values here
    }

});



export default function AiccScheduleSettingUI(props) {
    const [open, setOpen] = useState(false);
    const [openAllZip, setOpenAllZip] = useState(false);
    const [checked, setChecked] = useState(true);
    const [isCustomBtCount, setIsCustomBtCount] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [showRemainingWarnCheck, setShowRemainingWarnCheck] = useState(false);
    const [visualProctoring, setVisualProctoring] = useState(false)
    const [allZipAssessmentList, setAllAssessmentsZipList] = useState(props.allAssessmentsZipList)
    const [isLinkNameDuplicate, setIsLinkNameDuplicate] = useState(false);
    const [isLinkNameEmpty, setIsLinkNameEmpty] = useState(false);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedAssessment, setSelectedAssessment] = useState(props.selectedAssessment)
    const [prevScheduleSetting, setPrevScheduleSetting] = useState({})
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" })
    const [closeSelectTag, setCloseSelectTag] = useState(true)


    const scheduleSettingObj = {
        zipLinkName: "",
        zipLinkId: null,
        maxReattemptAllowed: 1,
        cooldownPeriod: 0,
        passPercentage: 0,
        apiWebProctoring: {
            count: "",
            enabled: false,
            showRemainingCounts: false
        },
        proctoring: {
            mode: "OFF",
            options: {
                candidateAuthorization: false,
                candidateScreenCapture: false
            }
        },
        secureBrowser: {
            enabled: false
        }
    }

    const [scheduleSettings, setScheduleSettings] = useState(scheduleSettingObj)


    const classes = useStyles();
    const classes1 = useStyles1();



    const MenuProps = {
        PaperProps: {
            scroll: 'body',
            style: {
                maxHeight: '153px',
                width: '344px',
            },
        },

    };


    useEffect(() => {
        setOpen(props.open);
        setIsUpdate(props.isUpdatCall)
        setOpenAllZip(props.openAllZip)

    }, [])




    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        // let isUpdate=props.isUpdatCall
        if (scheduleSettings.zipLinkName != "") {
            if (!isUpdate) {
                createNewSchedule("autosave")
            } else {
                updateSchedule("autosave")
            }
        }
        setOpen(false);
        setOpenAllZip(false)
        props.handleCloseDialog(false, snackbarProps);
    };

    const handleCloseAllZipDialog = () => {

        setOpen(false);
        setOpenAllZip(false);
        props.handleCloseDialog(false, snackbarProps);

    }
    const onClickZipLink = (row) => {

        setScheduleSettings(row)
        let days = Math.trunc(Number(row.cooldownPeriod / 24))
        let hrs = Number(row.cooldownPeriod % 24)
        setHours(hrs);
        setDays(days)
        setVisualProctoring(row.proctoring.mode === "OFF" ? false : true)
        setPrevScheduleSetting(row)
        setOpen(true);
        setOpenAllZip(false)
        setIsLinkNameDuplicate(false);
        setIsLinkNameEmpty(false)
    }
    const handleDownloadZip = () => {
        let linkAssessment = {
            assessmentId: selectedAssessment.id,
            assessmentSetting: scheduleSettings
        }

        if (scheduleSettings.zipLinkName != "") {
            if (!isUpdate) { //create new Schedule

                createNewSchedule("handleDownloadZip");
            } else {
                updateSchedule("handleDownloadZip")
            }
        } else {
            setIsLinkNameDuplicate(false);
            setIsLinkNameEmpty(true)
        }


    };

    const updateSchedule = (eventName) => {
        let linkAssessment = {
            assessmentId: selectedAssessment.id,
            assessmentSetting: scheduleSettings
        }

        if (!(JSON.stringify(prevScheduleSetting) === JSON.stringify(scheduleSettings))) {
            http.patch('/integrations/aicc/link', linkAssessment)
                .then((response) => {
                    if (response.status == 200) {

                        if (isUpdate && eventName === "handleDownloadZip") {
                            createZipFile(scheduleSettings)
                        }

                        props.handleCloseDialog(false, {
                            open: true,
                            message: "Schedule Updated Sucessfully",
                            variant: "success"
                        })


                    }
                })
                .catch((error) => {

                    if (error.response.status == 401) {
                        props.isUserLoggeInForSchedule(false)
                        props.redirectToLogin()
                    }
                });
        } else {
            console.log("no change in schedule settings")
        }
    }

    const createNewSchedule = (eventName) => {

        let linkAssessment = {
            assessmentId: selectedAssessment.id,
            assessmentSetting: scheduleSettings
        }

        if (scheduleSettings.zipLinkName != "") {
            http.post('/integrations/aicc/link', linkAssessment)
                .then((response) => {

                    if (response.status == 200) {

                        setScheduleSettings((prevState) => {
                            return ({
                                ...prevState,
                                zipLinkId: response.data.assessmentSetting.zipLinkId
                            })
                        })
                        props.handleCloseDialog(false, {
                            open: true,
                            message: "Schedule Created Sucessfully",
                            variant: "success"
                        })
                        if (!isUpdate && eventName === "handleDownloadZip") {
                            createZipFile(response.data.assessmentSetting);
                        }

                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        props.isUserLoggeInForSchedule(false)
                        props.redirectToLogin()
                    }
                });
        } else {
            console.log("please add zip file name")
        }

    }
    const createZipFile = (scheduleSettings) => {
        let linkAssessment = {
            assessmentId: selectedAssessment.id,
            assessmentSetting: scheduleSettings
        }
        const axiosConfig = {
            responseType: 'arraybuffer'
        }

        axios.post('/integrations/aicc/link/zip', linkAssessment, { responseType: 'arraybuffer' })
            .then((response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', linkAssessment.assessmentSetting.zipLinkName + ".zip"); //set download attribute to link
                document.body.appendChild(link);
                link.click(); // this will download file.zip


                props.handleCloseDialog(false, {
                    open: true,
                    message: "Zip file Downloaded Sucessfully",
                    variant: "success"
                })

            }))
            .catch((error) => {
                if (error.response.status == 401) {
                    props.isUserLoggeInForSchedule(false)
                    props.redirectToLogin()
                }
            });

    }

    const handleAllZipClicked = () => {
        http.get('/integrations/aicc/link', {
            params: { assessmentId: selectedAssessment.id }
        })
            .then((response) => {
                if (response.data) {
                    setAllAssessmentsZipList(response.data)
                    setOpen(false);
                    setOpenAllZip(true);
                    setIsUpdate(true);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    props.isUserLoggeInForSchedule(false)
                    props.redirectToLogin()
                }
            });




    }

    const allZipBackButtonClicked = () => {
        if (isUpdate) {
            setOpen(false);
            setOpenAllZip(false);
            props.handleCloseDialog(false, snackbarProps);
        } else {
            setOpen(true);
            setOpenAllZip(false);
        }
    }

    const handleDownloadZipClick = (row) => {
        setScheduleSettings(row)
        createZipFile(row);
    }
    const onClickpauseAccess = (row, name) => {
        setScheduleSettings(row);

        let linkAssessment = {
            assessmentId: selectedAssessment.id,
            assessmentSetting: row
        }

        if (name === "pause") {
            let active = false
            setAllAssessmentsZipList(
                allZipAssessmentList.map((item) => {
                    if (item.zipLinkId === row.zipLinkId) {
                        return { ...item, active };
                    } else {
                        return item;
                    }
                })
            );
        } else {
            let active = true
            setAllAssessmentsZipList(
                allZipAssessmentList.map((item) => {
                    if (item.id === row.id) {
                        return { ...item, active };
                    } else {
                        return item;
                    }
                })
            );
        }
        axios.post(name === "pause" ? '/integrations/aicc/link/disable' : '/integrations/aicc/link/enable', linkAssessment)
            .then((response => {
                console.log("enabled disabled..", response.data);

            }))
            .catch((error) => {

            });

    }
    const handleCreateNewSchedule = (row) => {
        setIsUpdate(false)
        setScheduleSettings(scheduleSettingObj);
        setOpen(true);
        setOpenAllZip(false);

    }

    const handleDuplicateName = (event) => {
        const { name, value, type, checked } = event.target;

        if (allZipAssessmentList.length == 0) {
            setScheduleSettings(
                { ...scheduleSettings, [name]: value }
            )
            console.log("inside ziplink name new 1", value)
        } else {

            console.log("inside ziplink name edit", isUpdate)
            console.log("inside ziplink name edit", prevScheduleSetting.zipLinkName)

            let zipFileName = null
            zipFileName = allZipAssessmentList.filter((val) => {
                return val.zipLinkName === value
            })
            if (zipFileName.length != 0) {
                setIsLinkNameDuplicate(true);
                setIsLinkNameEmpty(false)
                if (isUpdate) {
                    let value = prevScheduleSetting.zipLinkName
                    setScheduleSettings(
                        { ...scheduleSettings, [name]: value })
                } else {
                    let value = ""
                    setScheduleSettings({ ...scheduleSettings, [name]: value })
                }
            } else {
                setIsLinkNameDuplicate(false);
                setIsLinkNameEmpty(false)
                setScheduleSettings(
                    { ...scheduleSettings, [name]: value }
                )
            }


        }

    }

    const onCloseSelectTag = (event) => {
        setCloseSelectTag(true)
    }

    const onOpenSelectTag = (event) => {
        setCloseSelectTag(false)
    }
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setShowInput(false)
        let showRemainingWarnCheckBox = false
        setChecked(event.target.checked);

        if (name === "zipLinkName") {
            setScheduleSettings(
                { ...scheduleSettings, [name]: value }
            )

            allZipAssessmentList.forEach(element => {
                if (element.zipLinkName != value) {
                    setIsLinkNameDuplicate(false);
                    setIsLinkNameEmpty(false)
                }
            })
        }
        if (name === "visualProctoring") {
            setVisualProctoring(checked);
            setScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    proctoring: {
                        mode: checked ? "PHOTO" : "OFF",
                        options: {
                            candidateAuthorization: false,
                            candidateScreenCapture: false
                        }
                    }
                })
            })
        }
        if (name === "candidateAuthorization") {
            setScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    proctoring: {
                        mode: scheduleSettings.proctoring.mode ? "PHOTO" : "OFF",
                        options: {
                            candidateAuthorization: checked,
                            candidateScreenCapture: scheduleSettings.proctoring.options.candidateScreenCapture
                        }
                    }
                })
            })


        }
        if (name === "candidateScreenCapture") {


            setScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    proctoring: {
                        mode: scheduleSettings.proctoring.mode ? "PHOTO" : "OFF",
                        options: {
                            candidateAuthorization: scheduleSettings.proctoring.options.candidateAuthorization,
                            candidateScreenCapture: checked
                        }
                    }
                })
            })

        }
        if (name === "browsingToleranceCount") {
            //setBrowsingToleranceCount(value)
            checkIfCustomBtCount(value);
            if (name === "browsingToleranceCount" && value == 11) {
                setShowInput(true)
            }
            if (name === "browsingToleranceCount" && value > 0) {
                showRemainingWarnCheckBox = true

            }

            setScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    apiWebProctoring: {
                        count: value,
                        enabled: value === "" ? false : true,
                        showRemainingCounts: false
                    },
                })
            })

        }



        if (name === "remainingCountEnabled") {

            setScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    apiWebProctoring: {
                        count: scheduleSettings.apiWebProctoring.count,
                        enabled: scheduleSettings.apiWebProctoring.enabled,
                        showRemainingCounts: checked
                    },
                })
            })
        }

        setShowRemainingWarnCheck(showRemainingWarnCheckBox)

        // schedule setting obj...
        if (name === "passPercentage") {

            event.target.value = Math.trunc(value);
            if (value < 0) {
                event.target.value = 0
            } else if (value > 100) {
                event.target.value = 100
            }
            let val = event.target.value
            setScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    passPercentage: val
                })
            })
        }

        if (name === "maxReattemptAllowed") {
            event.target.value = Math.trunc(value);
            if (value <= 1) {
                event.target.value = 1
                let val = event.target.value

                setScheduleSettings((prevState) => {

                    return ({
                        ...prevState,
                        passPercentage: 0,
                        cooldownPeriod: 0,
                        maxReattemptAllowed: val
                    })
                })
                setDays(0);
                setHours(0);

            } else if (value > 99) {
                event.target.value = 99
                let val = event.target.value
                setScheduleSettings((prevState) => {

                    return ({
                        ...prevState,
                        maxReattemptAllowed: val
                    })
                })
            } else {
                setScheduleSettings((prevState) => {

                    return ({
                        ...prevState,
                        maxReattemptAllowed: value
                    })
                })
            }
        }
        if (name === "days") {
            event.target.value = Math.trunc(value);
            if (value < 0) {
                event.target.value = 0
            } else if (value > 999) {
                event.target.value = 999
            }
            setDays(event.target.value)
            if (hours === 0) {
                let hrs = event.target.value * 24
                setScheduleSettings((prevState) => {

                    return ({
                        ...prevState,
                        cooldownPeriod: hrs
                    })
                })

            } else {

                let daysIntoH = event.target.value * 24;
                let totalHours = Number(daysIntoH) + Number(hours);
                setScheduleSettings((prevState) => {

                    return ({
                        ...prevState,
                        cooldownPeriod: totalHours
                    })
                })
            }

        }

        if (name === "hours") {
            event.target.value = Math.trunc(value);
            if (value < 0) {
                event.target.value = 0
            } else if (value > 23) {
                event.target.value = 23
            }
            setHours(event.target.value)
            if (days == 0) {
                let days = event.target.value
                setScheduleSettings((prevState) => {

                    return ({
                        ...prevState,
                        cooldownPeriod: days
                    })
                })

            } else {
                let daysIntoH = days * 24;
                let totalHours = Number(daysIntoH) + Number(event.target.value);
                setScheduleSettings((prevState) => {

                    return ({
                        ...prevState,
                        cooldownPeriod: totalHours
                    })
                })
            }
        }

        if (name === "secureBrowser") {
            setScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    secureBrowser: {
                        enabled: checked

                    }
                })
            })
        }

    }




    const checkIfCustomBtCount = (value) => {
        const defaultOptions = ["", 0, 1, 2, 4, 10];
        let val = !defaultOptions.includes(parseInt(value)) && value !== "" ? true : false
        setIsCustomBtCount(val)

    }

    const handleCustomWarningChange = (event) => {
        let { name, value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        if (name === "customWarning") {

            // setBrowsingToleranceCount(value)

            setScheduleSettings((prevState) => {

                return ({
                    ...prevState,
                    apiWebProctoring: {
                        count: value,
                    },
                })
            })

        }
    }

    return (
        <div>
            <div>
                <Dialog PaperProps={{ style: { maxHeight: "100%", minWidth: "700px", margin: "0px 0px 0px auto", borderRadius: '0px' } }}
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    scroll="paper"
                    onClose={() => { handleCloseDialog(false, snackbarProps) }}
                    aria-describedby="alert-dialog-slide-description"

                >
                    <DialogTitle style={{ borderBottom: 'solid 1px', borderColor: '#BED3E4', padding: '16px 24px', fontSize: '16px' }}>
                        <Typography style={{ fontSize: '16px', color: '#001F52', fontFamily: 'NotoSans-Medium' }}>
                            Download Zip <IconButton
                                aria-label="close"
                                onClick={() => { handleCloseDialog(false, snackbarProps) }}
                                style={{ marginLeft: '76.5%', padding: '0px', background: 'transparent' }}

                            >
                                <img height="24" width="24" src={MORE_APPS_CONFIG.lmsFullPath + '/close.svg'} alt="close" />
                            </IconButton>
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ height: '600px', padding: '24px' }}>

                        <React.Fragment>
                            <Container maxWidth="false" style={{ paddingLeft: '0px', paddingRight: '0px' }} >
                                <div>

                                    <Typography style={{ color: '#4E6287', fontSize: '12px', fontFamily: 'NotoSans-Regular', display: 'flex', paddingBottom: '8px' }}>
                                        Zip File Name
                                        {isLinkNameDuplicate && <Typography style={{ color: 'red', fontSize: '10px', marginLeft: '360px', paddingTop: '3px' }}>
                                            File Name Already Present
                                        </Typography>}
                                        {isLinkNameEmpty && <Typography style={{ color: 'red', fontSize: '10px', marginLeft: '360px', paddingTop: '3px' }}>
                                            Please Add File Name
                                        </Typography>}
                                    </Typography>




                                    <Box display="flex" alignItems="center" flexWrap="nowrap" border={1} borderColor="#BED3E4" borderRadius={5} style={{ padding: '10px 16px', height: '20px' }} >


                                        <Input placeholder="Sample File Name" type="text" name="zipLinkName" value={scheduleSettings.zipLinkName} onChange={handleChange} onBlur={handleDuplicateName}
                                            style={{ fontFamily: 'NotoSans-Regular', color: '#001F52' }}
                                            disableUnderline />


                                    </Box>
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Box display="flex" flexWrap="nowrap"
                                                style={{ height: '40px', padding: '8px 16px', backgroundColor: '#F8FAFC', marginTop: '16px', alignItems: 'center', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '5px' }} >
                                                <img height="20" width="20" style={{ padding: '10px 10px 10px 5px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/msb.svg'} alt="error" />
                                                <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium' }}>Mettl Secure Browser</Typography>
                                                <MsbTooltip placement="top" style={{ marginRight: '50%' }} interactive title={
                                                    <React.Fragment>
                                                        <Typography style={{ fontSize: "12px", fontFamily: 'NotoSans-Regular' }}>This is a custom browser which turns candidate's system into a lock-down mode when the
                                                            assessment is running. It enables multiple security features which prevents candidates to access unauthorized resources on his/her machine</Typography>
                                                        <Typography style={{ textAlign: "center", color: "#fffff", fontSize: "12px" }}>
                                                            {<a style={{ color: '#ffffff' }} target="_blank" href='https://support.mettl.com/portal/en/kb/articles/mettl-secure-browser-msb' >Know More</a>}</Typography>
                                                    </React.Fragment>}
                                                    arrow>
                                                    {/* <InfoIcon aria-label="info" style={{ fontSize: 'medium', paddingLeft: '10px' }} color='action' /> */}
                                                    <img height="20" width="20" style={{ padding: '10px 10px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />
                                                </MsbTooltip>

                                                <span style={{ marginLeft: 'auto' }}>
                                                    <Switch
                                                        name="secureBrowser"
                                                        checked={scheduleSettings.secureBrowser.enabled}
                                                        onChange={handleChange}
                                                        color="primary"
                                                        inputProps={{ 'aria-labelledby': 'switch-list-label', marginLeft: '50%' }}
                                                        className={classes1.materialUISwitch}
                                                    />
                                                </span>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box display="flex" flexWrap="nowrap"
                                                style={{ backgroundColor: '#F8FAFC', marginTop: '16px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '5px', padding: '20px 21px' }} >

                                                <Grid container style={{ display: 'flex' }}>
                                                    <Grid item xs={12} >
                                                        <Box display="flex">
                                                            <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/avp.svg'} alt="error" />
                                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>Advanced Visual Proctoring</Typography>
                                                            <MsbTooltip interactive placement='top'
                                                                title={<Typography style={{ fontSize: "12px", fontFamily: 'NotoSans-Regular' }}>
                                                                    You can watch candidate's live while taking the test or view
                                                                    recorded snapshots in the candidate's report on demand. Mettl marks instances of candidate's suspicious activities using it's Artificial Intelligence algorithm
                                                                </Typography>} arrow>
                                                                <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />

                                                            </MsbTooltip>
                                                            <span style={{ marginLeft: 'auto', marginTop: '-10px', marginRight: '-5px' }}>
                                                                <Switch
                                                                    name="visualProctoring"
                                                                    checked={visualProctoring}
                                                                    onChange={handleChange}
                                                                    color="primary"
                                                                    inputProps={{ 'aria-labelledby': 'switch-list-label', marginLeft: '50%' }}
                                                                    className={classes1.materialUISwitch}
                                                                />
                                                            </span>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ padding: '21px 0px 13px 40px', display: 'flex', alignItems: 'center' }}>
                                                        <Checkbox className={classes1.checkboxColor} name="candidateScreenCapture" color="primary"
                                                            checked={scheduleSettings.proctoring.options.candidateScreenCapture} onChange={handleChange} style={{ padding: "0px", fontSize: '20px' }} />
                                                        <Typography style={{ fontSize: "14px", paddingLeft: "12px", fontFamily: 'NotoSans-Medium', color: '#001F52' }}>Capture Candidate Screen </Typography>

                                                    </Grid>
                                                    <Grid item xs={12} style={{ padding: '0px 0px 25px 40px', display: 'flex', alignItems: 'center' }} >

                                                        <Checkbox className={classes1.checkboxColor} type="checkbox" name="candidateAuthorization" color="primary"
                                                            checked={scheduleSettings.proctoring.options.candidateAuthorization}
                                                            onChange={handleChange} style={{ padding: "0px", fontSize: '20px' }} />
                                                        <Typography style={{ fontSize: "14px", fontFamily: 'NotoSans-Medium', paddingLeft: "12px", color: '#001F52' }}>Candidate Authorization </Typography>

                                                    </Grid>
                                                    <Grid item xs={12}  >
                                                        <Box display="flex" style={{ height: '18px', padding: '0px 0px 3px 40px', alignItems: 'center', color: '#002C77', fontSize: '12px' }}>
                                                            Runs On :
                                                            <img height="18" width="108" src={MORE_APPS_CONFIG.lmsFullPath + '/Browser-logo.svg'} style={{ marginLeft: '11px' }} alt="error" />
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                            </Box>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" flexWrap="nowrap"
                                                style={{ backgroundColor: '#F8FAFC', marginTop: '16px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '5px' }} >

                                                <Grid container style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                                                    <Grid item xs={5} style={{ display: 'flex' }} >
                                                        <img height="20" width="20" style={{ padding: '0px 10px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/browsing_tolerance.svg'} alt="error" />
                                                        <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium' }}>Browsing Tolerance</Typography>
                                                        <MsbTooltip interactive style={{ marginRight: '50%' }} placement='top'
                                                            title={<Typography style={{ fontSize: "12px" }}>
                                                                Not recommended for Upload Type questions in test
                                                            </Typography>} arrow>
                                                            <img height="20" width="20" style={{ paddingLeft: '10px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />
                                                        </MsbTooltip>
                                                    </Grid>
                                                    <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>

                                                        <FormControl style={{ border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', width: '336px' }}>
                                                            <Select
                                                                name="browsingToleranceCount"
                                                                value={scheduleSettings.apiWebProctoring.count}
                                                                onChange={handleChange}
                                                                onClose={onCloseSelectTag}
                                                                onOpen={onOpenSelectTag}
                                                                displayEmpty={true}
                                                                disableUnderline
                                                                inputProps={{ style: { border: 'solid 1px', borderColor: '#BED3E4' } }}
                                                                style={{ padding: '5px 16px', color: '#8096B2', fontFamily: 'NotoSans-Regular' }}
                                                                MenuProps={MenuProps}
                                                            >
                                                                <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value="">

                                                                    Not Applicable
                                                                    {!closeSelectTag && scheduleSettings.apiWebProctoring.count === "" && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                                                </MenuItem>
                                                                <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={0}>No Warning
                                                                    {!closeSelectTag && scheduleSettings.apiWebProctoring.count === 0 && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                                                </MenuItem>
                                                                <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={1}>Very low (1 warning)
                                                                    {!closeSelectTag && scheduleSettings.apiWebProctoring.count === 1 && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                                                </MenuItem>
                                                                <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={2}>Low (2 warnings)
                                                                    {!closeSelectTag && scheduleSettings.apiWebProctoring.count === 2 && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                                                </MenuItem>
                                                                <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={4}>Medium (4 warnings)
                                                                    {!closeSelectTag && scheduleSettings.apiWebProctoring.count === 4 && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                                                </MenuItem>
                                                                <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={10}>High (10 warnings)
                                                                    {!closeSelectTag && scheduleSettings.apiWebProctoring.count === 10 && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                                                </MenuItem>
                                                                <MenuItem style={{ color: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', padding: '10px 16px', justifyContent: 'space-between' }} value={!isCustomBtCount ? 11 : scheduleSettings.apiWebProctoring.count}>Custom Warning
                                                                    {!closeSelectTag && isCustomBtCount && <img height="20" width="13" style={{ marginRight: '16px' }} src={MORE_APPS_CONFIG.lmsFullPath + '/checkmark.svg'} alt="error" />}
                                                                </MenuItem>
                                                            </Select>

                                                        </FormControl>
                                                        {(showInput || isCustomBtCount) &&

                                                            <Box component="span" width={50} ml={1}>
                                                                <Input type="number" name="customWarning" value={scheduleSettings.apiWebProctoring.count}
                                                                    onChange={handleCustomWarningChange} inputProps={{ min: 1, max: 99, style: { color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Regular' } }} ></Input>
                                                            </Box>

                                                        }
                                                        {(showRemainingWarnCheck || scheduleSettings.apiWebProctoring.count > 0) &&

                                                            <React.Fragment>
                                                                <Checkbox
                                                                    onChange={handleChange}
                                                                    checked={scheduleSettings.apiWebProctoring.showRemainingCounts}
                                                                    name="remainingCountEnabled"
                                                                    color="primary"
                                                                    inputProps={{
                                                                        'aria-label': 'remaining warnings checkbox',
                                                                    }}
                                                                />
                                                                {!showInput && <Typography style={{ display: 'flex', alignItems: 'center', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }} variant="body2">Show remaining</Typography>}
                                                                {showInput && <Typography style={{ display: 'flex', alignItems: 'center', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }} variant="body2">Show remaining</Typography>}
                                                            </React.Fragment>

                                                        }

                                                    </Grid>
                                                </Grid>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" flexWrap="nowrap"
                                                style={{ padding: '16px', backgroundColor: '#F8FAFC', marginTop: '16px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px' }} >
                                                <React.Fragment>
                                                    <Grid container>
                                                        <Grid item xs={5} style={{ padding: '10px 10px', display: 'flex' }} >
                                                            <img height="25" width="25" src={MORE_APPS_CONFIG.lmsFullPath + '/attempts_allowed.svg'} alt="error" />
                                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>Attempts Allowed</Typography>
                                                            <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />
                                                        </Grid>
                                                        <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input type="number" name="maxReattemptAllowed" min="1" disableUnderline inputProps={{
                                                                style: {
                                                                    width: '305px',
                                                                    border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', padding: '10px 16px', color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Regular'
                                                                }
                                                            }}
                                                                value={scheduleSettings.maxReattemptAllowed} onChange={handleChange}
                                                            />

                                                        </Grid>

                                                        <Grid item xs={5} style={{ marginTop: '16px', display: 'flex', padding: '10px 10px', alignItems: 'center' }} >

                                                            <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/pass_percentage.svg'} alt="error" />
                                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>Pass Percentage</Typography>
                                                            <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />
                                                        </Grid>
                                                        <Grid item xs={7} style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>


                                                            <Input type="number" name="passPercentage" min="1" disableUnderline inputProps={{
                                                                style: {
                                                                    width: '305px',
                                                                    border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', padding: '10px 16px', color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Regular'
                                                                }
                                                            }}
                                                                value={scheduleSettings.passPercentage} onChange={handleChange}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={5} style={{ marginTop: '16px', display: 'flex', padding: '10px 10px', alignItems: 'center' }} >

                                                            <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/cp.svg'} alt="error" />
                                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>Cooldown Period</Typography>
                                                            <img height="20" width="20" src={MORE_APPS_CONFIG.lmsFullPath + '/info.svg'} alt="error" />
                                                        </Grid>

                                                        <Grid item xs={7} style={{ marginTop: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                            <Box style={{ display: 'flex', columnGap: '20px', maxWidth: '97%' }}>
                                                                <TextField className={classes1.inputBoxUnderline} id="standard-number" label="Day(s)" name="days" type="number" value={days} onChange={handleChange} disableUnderline
                                                                    inputProps={{ style: { border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', padding: '10px 16px', fontSize: '14px', color: '#001F52', fontFamily: 'NotoSans-Regular' } }} InputLabelProps={{ style: { shrink: true, fontSize: '12px', color: '#4E6287', fontFamily: 'NotoSans-Regular' } }} variant="standard" />

                                                                <TextField className={classes1.inputBoxUnderline} id="standard-number" label="Hour(s)" name="hours" type="number" value={hours} onChange={handleChange} disableUnderline
                                                                    inputProps={{ style: { border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', padding: '10px 16px', fontSize: '14px', color: '#001F52', fontFamily: 'NotoSans-Regular' } }} InputLabelProps={{ style: { shrink: true, fontSize: '12px', color: '#4E6287', fontFamily: 'NotoSans-Regular' } }} variant="standard" />

                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            </Box>
                                        </Grid>
                                    </Grid>


                                </div>
                            </Container>
                        </React.Fragment>
                    </DialogContent>
                    <DialogActions style={{ padding: '16px 24px', display: 'flex', justifyContent: 'space-between', boxShadow: '2px 2px 17px 6px rgb(0 0 0 / 12%)' }}>
                        <Button variant='outlined' className={classes1.myClassName} style={{ fontSize: '14px', textTransform: 'capitalize', fontFamily: 'NotoSans-Regular' }} onClick={() => { handleAllZipClicked(false) }}>All Zips</Button>

                        <Button variant='contained' style={{ backgroundColor: '#0B41AD', color: '#ffff', fontSize: '14px', fontFamily: 'NotoSans-Regular', textTransform: 'capitalize' }} onClick={() => { handleDownloadZip(false) }}>Download Zip</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>

            </div>
            <Dialog PaperProps={{ style: { maxHeight: "100%", minWidth: "700px", margin: "0px 0px 0px auto", borderRadius: '0px' } }}
                open={openAllZip}

                keepMounted
                scroll="paper"
                onClose={() => { handleCloseAllZipDialog(false) }}
                aria-describedby="alert-dialog-slide-description"

            >
                <DialogTitle style={{ borderBottom: 'solid 1px', borderColor: '#BED3E4', padding: '16px 24px', fontSize: '16px' }}>

                    <Typography style={{ fontSize: '16px', color: '#001F52', fontFamily: 'NotoSans-Medium' }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => { allZipBackButtonClicked(false) }}
                            style={{ padding: '0px 10px 0px 0px', background: 'transparent' }}
                        >
                            <img height="24" width="24" src={MORE_APPS_CONFIG.lmsFullPath + '/back.svg'} alt="error" />
                        </IconButton>
                        All Zips
                        <IconButton
                            aria-label="close"
                            onClick={() => { handleCloseAllZipDialog(false) }}
                            style={{ marginLeft: '82.5%', padding: '0px', background: 'transparent' }}

                        >
                            <img height="24" width="24" src={MORE_APPS_CONFIG.lmsFullPath + '/close.svg'} alt="error" />
                        </IconButton></Typography>

                </DialogTitle>
                <DialogContent style={{ height: '600px', padding: '0px' }}>

                    <React.Fragment>
                        <TableContainer style={{ overflowX: 'hidden' }} >

                            <Table className={classes.table} aria-label="simple table">
                                <TableHead >

                                </TableHead>
                                <TableBody >
                                    {allZipAssessmentList.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

                                            <TableCell className={classes.cell} style={{ color: '#001F52', fontSize: '14px' }} onClick={() => { onClickZipLink(row) }}>{row.zipLinkName}</TableCell>

                                            <TableCell className={classes.cell} align='right'>
                                                {row.active && <MsbTooltip placement="top" style={{ marginRight: '50%' }} interactive title={
                                                    <React.Fragment>
                                                        <Typography style={{ fontSize: "12px" }}>Pause Access</Typography>
                                                    </React.Fragment>}
                                                    arrow>
                                                    <IconButton className={classes1.myClassName} style={{ border: '1px solid', borderRadius: '4px', padding: '5px 7px', marginRight: '15px' }} onClick={() => { onClickpauseAccess(row, "pause") }} >
                                                        <Icon fontSize="small">pause</Icon>
                                                    </IconButton>
                                                </MsbTooltip>}
                                                {!row.active && <MsbTooltip placement="top" style={{ marginRight: '50%' }} interactive title={
                                                    <React.Fragment>
                                                        <Typography style={{ fontSize: "12px" }}>Unpause Access</Typography>
                                                    </React.Fragment>}
                                                    arrow>
                                                    <IconButton style={{ border: '1px solid', borderRadius: '4px', padding: '5px 7px', marginRight: '15px', color: 'disabled' }} onClick={() => { onClickpauseAccess(row, "unpause") }} >
                                                        <Icon fontSize="small">pause</Icon>
                                                    </IconButton>
                                                </MsbTooltip>}
                                                <IconButton className={classes1.downloadImg} style={{ border: '1px solid', borderRadius: '4px', padding: '5px 7px' }} onClick={() => { handleDownloadZipClick(row) }}>
                                                    <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/download.svg'} alt="" />
                                                </IconButton>


                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {(allZipAssessmentList.length == 0) && (
                                        <React.Fragment>
                                            <Grid container alignItems="center">

                                                <Grid container style={{ paddingTop: "130px" }} className='text-center' alignItems="center"  >
                                                    <Grid item sm={12} style={{ textAlign: "center", height: "40vh" }}>
                                                        <img height="150" width="150" src={MORE_APPS_CONFIG.lmsFullPath + "/404.png"} alt="No zip created yet" />
                                                        <Typography style={{ fontSize: "14px", marginTop: "-20px", color: '#001F52' }}>No zips created yet</Typography>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                        </React.Fragment>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>

                </DialogContent>
                <DialogActions style={{ padding: '16px 24px', display: 'flex', boxShadow: '2px 2px 17px 2px rgb(0 0 0 / 12%)' }}>
                    <Button variant='contained' style={{ textTransform: 'capitalize', fontFamily: 'NotoSans-Regular', fontSize: '14px', backgroundColor: '#0B41AD', color: '#ffff' }} onClick={() => { handleCreateNewSchedule(false) }}>Generate Another Zip</Button>
                </DialogActions>
            </Dialog>


        </div>
    );
}

const MsbTooltip = withStyles((theme) => ({
    tooltip: {

        backgroundColor: '#002c77',
        color: '#fffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: '8px 16px',
        alignItems: 'center',
        gap: '8px'
    },
    arrow: {
        color: '#002c77'
    }
}))(Tooltip);


