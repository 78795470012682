const moreAppsComConfig={
"examine":"https://mettl.com/corporate/dashboard",
"360View":"https://mettl.com/360feedback",
"xathon":"https://mettl.com/hackathon/admin",
"certiCheck":"https://mettl.com/certification/admin/dashboard",
"examiner":"https://mettl.com/corporate/v2/examiner/dashboard",
"interviews":"https://mettl.com/interviews",
"integration":"https://mettl.com/integrations",
"logout":"https://mettl.com/corporate/SignOut",
"support":"https://support.mettl.com/portal/en/kb/induslynk-training-services-p-ltd/client1/integration"
}

const moreAppsEuConfig={
  "examine":"https://mercermettl.eu/corporate/dashboard",
  "360View":"https://mercermettl.eu/360feedback",
  "xathon":"https://mercermettl.eu/hackathon/admin",
  "certiCheck":"https://mercermettl.eu/certification/admin/dashboard",
  "examiner":"https://mercermettl.eu/corporate/v2/examiner/dashboard",
  "interviews":"https://mercermettl.eu/interviews",
  "integration":"https://mercermettl.eu/integrations",
  "logout":"https://mercermettl.eu/corporate/SignOut",
  "support":"https://support.mettl.com/portal/en/kb/induslynk-training-services-p-ltd/client1/integration"
  }

const moreAppsXyzConfig={
    "examine":"https://mettl.xyz/corporate/dashboard",
    "360View":"https://mettl.xyz/360feedback",
    "xathon":"https://mettl.xyz/hackathon/admin",
    "certiCheck":"https://mettl.xyz/certification/admin/dashboard",
    "examiner":"https://mettl.xyz/corporate/v2/examiner/dashboard",
    "interviews":"https://mettl.xyz/interviews",
    "integration":"https://mettl.xyz/integrations",
    "logout":"https://mettl.xyz/corporate/SignOut",
    "support":"https://support.mettl.com/portal/en/kb/induslynk-training-services-p-ltd/client1/integration"
    }

  const moreAppsProConfig={
      "examine":"https://mettl.pro/corporate/dashboard",
      "360View":"https://mettl.pro/360feedback",
      "xathon":"https://mettl.pro/hackathon/admin",
      "certiCheck":"https://mettl.pro/certification/admin/dashboard",
      "examiner":"https://mettl.pro/corporate/v2/examiner/dashboard",
      "interviews":"https://mettl.pro/interviews",
      "integration":"https://mettl.pro/integrations",
      "logout":"https://mettl.pro/corporate/SignOut",
      "support":"https://support.mettl.com/portal/en/kb/induslynk-training-services-p-ltd/client1/integration"
      }


      const hostname = window && window.location && window.location.hostname;
      let lmsFullPath=""
      if(hostname!="localhost"){
        if(hostname.includes("lms")){
          lmsFullPath=location.protocol+'//'+location.hostname
        }else{
          lmsFullPath=location.protocol+'//'+'lms.'+location.hostname
        }
         
      }else{
       lmsFullPath=location.protocol+'//'+location.host

      }
    console.log("inside more apps config")

    let MORE_APPS_CONFIG={}
  if(hostname=== 'mettl.com'){
     MORE_APPS_CONFIG= moreAppsComConfig;
     MORE_APPS_CONFIG['lmsFullPath']=lmsFullPath
    }else if(hostname=== 'mercermettl.eu'){
    MORE_APPS_CONFIG= moreAppsEuConfig;
    MORE_APPS_CONFIG['lmsFullPath']=lmsFullPath
    }else if(hostname=== 'mettl.xyz'){
     MORE_APPS_CONFIG= moreAppsXyzConfig;
     MORE_APPS_CONFIG['lmsFullPath']=lmsFullPath
    }else if(hostname=== 'mettl.pro'){
      MORE_APPS_CONFIG= moreAppsProConfig;
      MORE_APPS_CONFIG['lmsFullPath']=lmsFullPath
     }else{
      MORE_APPS_CONFIG= moreAppsComConfig;
      MORE_APPS_CONFIG['lmsFullPath']=lmsFullPath
     }

    

    console.log("inside more apps config...host name,full path..",hostname,lmsFullPath)

    export default MORE_APPS_CONFIG;