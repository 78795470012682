import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
export default function ArrowTooltip(props) {
    return (
        <MsbTooltip title={props.text} arrow placement="bottom-end">
            {props.children}
        </MsbTooltip>
    );
}
const MsbTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#002c77',
        color: '#fffff',
        padding: "8px 16px",
        fontFamily: "NotoSans-Regular",
        fontSize: 12,
    },
    arrow: {
        color: '#002c77',
    }
}))(Tooltip);