import { AppBar, Box, Button, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Apps from '@material-ui/icons/Apps';
import http from 'axios';
import React, { useEffect, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MORE_APPS_CONFIG from '../moreApps';



export default function IntegrationHeader(props) {
  const [corporateUser, setCorporateUser] = useState({});
  const classes = useStyles();
  const [balance, setBalance] = useState({
    visiblity: "",
    balance: "0",
    currency: null
  });
  // for more apps menu
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = ['Examine', '360View', 'X-ATHON', 'CertiCheck', 'ExamineER', 'Interviews', 'Integration'];


  useEffect(() => {
    http.get('/integrations/user')
      .then((response) => {
        if (response.data) {
          setCorporateUser(response.data);
          getBalance()
        }
      }).catch((error) => {
        if (error.response.status == 401) {

        }
      })

  }, []);


  const getBalance = () => {
    http.post('/integrations/get-balance')
      .then((response) => {
        if (response.data) {
          console.log("your Balance===>", response.data)
          setBalance(response.data);

        }
      }).catch((error) => {

      })
  }


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);

  };

  const handleCloseMenuItem = (val) => {
    setAnchorElUser(null);
    if (val === "Examine") {
      window.open(MORE_APPS_CONFIG.examine, '_blank')
    } else if (val === "360View") {
      window.open(MORE_APPS_CONFIG["360View"], '_blank')
    } else if (val === "X-ATHON") {
      window.open(MORE_APPS_CONFIG.xathon, '_blank')
    } else if (val === "CertiCheck") {
      window.open(MORE_APPS_CONFIG.certiCheck, '_blank')
    } else if (val === "ExamineER") {
      window.open(MORE_APPS_CONFIG.examiner, '_blank')
    } else if (val === "Interviews") {
      window.open(MORE_APPS_CONFIG.interviews, '_blank')
    }
  }

  const refreshPage = () => {
    window.location.href = "/integrations/"
  }

  // for acount toolbar menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };


  const handleLogout = (event) => {
    http.post(MORE_APPS_CONFIG.logout)
      .then((response) => {
        console.log("You have signed out successfully");
        window.location.href = "/";
      })
  }

  return (
    <AppBar position='sticky' style={{ background: "#002c77", height: '64px', paddingLeft: '40px', paddingRight: '40px', paddingTop: '14px', paddingBottom: '14px' }}>

      <Box style={{ height: '36px', display: 'flex', justifyContent: 'space-between' }}>

        <Box style={{ height: '36px', width: '414px', display: 'flex', alignItems: 'center' }}>
          <img height="20px" width="136px" src={MORE_APPS_CONFIG.lmsFullPath + '/mercer-mettl_logo_colored.png'} alt="mettl-logo" />

          <Box style={{ height: '36px', width: '256', display: 'flex', alignItems: 'center' }}>
            <Divider style={{ backgroundColor: "#4E6287", marginLeft: '15px', width: '1px', height: '32px', marginRight: '16px' }} orientation="vertical" />

            <Button onClick={refreshPage} variant="contained" edge="start" size="medium" style={{ backgroundColor: '#001f52', color: '#ffffff', fontSize: '14px', fontWeight: '600', textTransform: 'capitalize', boxShadow: 'none', padding: '8px 16px' }}>
              <Typography style={{ color: "#ffffff", fontSize: '14px', fontFamily: 'NotoSans-Regular' }} >Integrations</Typography>
            </Button>

            <Button variant="contained" size="medium" onClick={handleOpenUserMenu} style={{ color: '#ffffff', fontSize: '14px', fontWeight: '600', textTransform: 'capitalize', width: 'max-content', boxShadow: 'none', padding: '8px 16px' }}
              className={classes.myClassName} endIcon={<ArrowDropDownIcon />}>
              <Typography style={{ color: "#ffffff", fontSize: '14px', fontFamily: 'NotoSans-Regular' }} >More Apps </Typography>
            </Button>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                style: {
                  marginTop: `42px`,
                  width: '204px',
                  marginLeft: '6%'

                }
              }}
            >
              <Box style={{ padding: '0px 8px' }}>
                <Typography style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#8096B2', fontSize: '12px', fontWeight: '600' }}>
                  MORE APPS
                </Typography>
                <MenuItem name="Examine" onClick={() => { handleCloseMenuItem("Examine") }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
                  <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/Examine.svg'} alt="Examine-logo" style={{ paddingRight: '12px' }} />
                  Examine
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenuItem("360View") }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
                  <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/360View.svg'} alt="360View-logo" style={{ paddingRight: '12px' }} />
                  360View
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenuItem("X-ATHON") }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
                  <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/Xathone.svg'} alt="Xathone-logo" style={{ paddingRight: '12px' }} />
                  X-ATHON
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenuItem("CertiCheck") }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
                  <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/Certicheck.svg'} alt="Certicheck-logo" style={{ paddingRight: '12px' }} />
                  CertiCheck
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenuItem("ExamineER") }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
                  <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/Examiner.svg'} alt="Examiner-logo" style={{ paddingRight: '12px' }} />
                  ExamineER
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenuItem("Interviews") }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
                  <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/Interviews.svg'} alt="Interviews-logo" style={{ paddingRight: '12px' }} />
                  Interviews
                </MenuItem>
                <MenuItem onClick={() => { handleCloseMenuItem("Integration") }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
                  <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/Integration.svg'} alt="Integration-logo" style={{ paddingRight: '12px' }} />
                  Integration
                </MenuItem>
              </Box>
            </Menu>

          </Box>
        </Box>

        <Box style={{ height: '32px', width: 'auto', display: 'flex', alignItems: 'center' }}>

          <IconButton style={{ color: 'white', fontSize: '30px', borderRadius: '5px', padding: '5px', paddingBottom: '8px' }} onClick={() => { window.open(MORE_APPS_CONFIG.support, '_blank') }}>

            <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/Support.svg'} alt="" />
          </IconButton>
          <Divider style={{ backgroundColor: "#4E6287", marginLeft: '15px', width: '1px', height: '28px' }} orientation="vertical" />

          {balance.currency && <Typography style={{ color: '#A2B7CD', fontFamily: 'NotoSans-Regular', fontSize: '10px', padding: '0px 24px' }}>BALANCE
            <Typography style={{ color: '#A2B7CD', fontFamily: 'NotoSans-Regular', fontSize: '12px', color: '#FFF' }}>{balance.currency.toUpperCase()} {balance.balance}</Typography>
          </Typography>}

          <IconButton style={{ color: 'white', fontSize: '30px', borderRadius: '5px' }}
            onClick={handleClick}

            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}

          >

            {Object.keys(corporateUser).length === 0 && <AccountCircle style={{ fontSize: 'medium', height: '31px', width: '31px' }} />}
            {Object.keys(corporateUser).length != 0 && <Avatar alt="Remy Sharp" src=" " style={{ fontSize: 'medium', height: '31px', width: '31px' }}>{(corporateUser.firstName != null ? corporateUser.firstName.charAt(0) : "") + (corporateUser.lastName != null ? corporateUser.lastName.charAt(0) : "")}</Avatar>}
            <ArrowDropDownIcon fontSize='medium' />
          </IconButton>


          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
              style: {
                marginTop: `42px`,
                width: '305px',
                marginLeft: '25px',
                display: 'flex',
                padding: '8px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                borderRadius: '4px',
                border: '1px solid var(--Borders-Blue-Gray-300, #BED3E4)',
                background: '#fff',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)'

              }
            }}
            MenuListProps={{
              style: {
                width: '100%'
              }
            }}
          >
            {(Object.keys(corporateUser).length != 0) && <MenuItem style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px', paddingBottom: '16px', borderBottom: '1px solid #D1E0EC' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <circle cx="16" cy="16" r="16" fill="#8246AF" />
                <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white">{(corporateUser.firstName != null ? corporateUser.firstName.charAt(0) : "") + (corporateUser.lastName != null ? corporateUser.lastName.charAt(0) : "")}</text>
              </svg>
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '2px', paddingLeft: '12px' }}>
                <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', fontStyle: 'normal', fontWeight: 500, lineHeight: '20px', color: '#001F52' }}>
                {(corporateUser.firstName != null ? corporateUser.firstName : "") +" "+ (corporateUser.lastName != null ? corporateUser.lastName : "")}
                </Typography>
                <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '12px', fontStyle: 'normal', fontWeight: 500, lineHeight: '20px', color: '#4E6287' }}>
                  {corporateUser.emailId}
                </Typography>
              </Box>

            </MenuItem>}
            {(Object.keys(corporateUser).length != 0 && corporateUser.appPageAccessible) && <MenuItem onClick={() => { window.location.href = "/integrations/apps" }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px', marginTop: '10px' }}>
              <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/addPartner.svg'} alt="addPartner-logo" style={{ paddingRight: '12px' }} />Add Partner
            </MenuItem>}
            {(Object.keys(corporateUser).length != 0 && corporateUser.supportUser) && <MenuItem onClick={() => { window.location.href = "/integrations/map-client" }} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
              <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/mapClient.svg'} alt="addPartner-logo" style={{ paddingRight: '12px' }} />Map Client Integrations
            </MenuItem>}
            {Object.keys(corporateUser).length != 0 && <MenuItem onClick={handleLogout} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
              <img height="24px" width="24px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/logout.svg'} alt="Logout-logo" style={{ paddingRight: '12px' }} />Logout
            </MenuItem>}
            {
              Object.keys(corporateUser).length === 0 && <MenuItem onClick={handleLogout} style={{ padding: ' 8px', fontFamily: 'NotoSans-Regular', color: '#001F52', fontSize: '14px' }}>
                <ExitToApp fontSize="medium" style={{ paddingRight: '12px' }} />Login
              </MenuItem>
            }
          </Menu>

        </Box>
      </Box>

    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  myClassName: {
    backgroundColor: '#002c77',
    "&:hover": {
      backgroundColor: "#001f52",
    }
  }
}));