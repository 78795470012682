import React, { useState, useEffect } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import http from 'axios';
import MORE_APPS_CONFIG from '../../moreApps';
import { Container, Box, Input, IconButton, Typography, Button, Grid, TextField } from '@material-ui/core';
import CustomizedSnackbar from '../shared/CustomizedSnackbar';



function AddApp(props) {
    // Initialize state variables to store input values
    const classes = useStyles();
    const [appDto, setAppDto] = useState({
        id: null,
        name: null,
        protocolName: null,
        protocolVersion: null,
        domainName: null,
        logoUrl: null,
        supportPageUrl: null,
        integrationBuiltBy: "Partner",
        appNameAlias: null,
        configFieldsJson: '{"message":"","keyRequired":"false","fields":[] }'
    });
    const [formErrors, setFormErrors] = useState({
        name: "name is required",
        protocolName: "protocolName is Required"
    });
    const [editApp, setEditApp] = useState(props.appEditData)
    const [open, setOpen] = useState(false);
    const [prevImg, setPrevImg] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" })



    useEffect(() => {
        console.log("outside edit...", props.isEditApp)
        setOpen(props.open)
        if (props.isEditApp) {
            console.log("inside edit...", editApp)
            setAppDto(editApp);

            setAppDto((prevState) => {
                return {
                    ...prevState,
                    configFieldsJson: JSON.stringify(editApp['configFieldsJson'])
                }
            })
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    name: '',
                    protocolName: ''
                }
            })
        }
    }, [])

    const handleChange = (event) => {
        console.log(event.target.value);
        setIsSubmit(false)
        const { name, value, type, checked } = event.target;
        setAppDto(
            { ...appDto, [name]: value }
        )


        if (name === "name" || name === "protocolName") {
            if (value.trim() === '') {
                setFormErrors({
                    ...formErrors,
                    [name]: `${name} is required.`,
                });
            } else {
                setFormErrors({
                    ...formErrors,
                    [name]: '',
                });
            }
        }
    }

    const checkValidJson = () => {

        let isValidJSON = true;
        try {
            if (appDto.configFieldsJson != "") {
                JSON.parse(appDto.configFieldsJson)
            }
        }
        catch (e) {
            isValidJSON = false
            setAppDto(
                { ...appDto, configFieldsJson: "" })
        }
        return isValidJSON;
    }

    const onclicksubmit = (e) => {

        console.log("submit button clicked", appDto)
        setIsSubmit(true);
        let isValidJSON = checkValidJson()

        if (Object.values(formErrors).every((error) => error === '')) {
            if (isValidJSON) {

                let objectField = appDto.configFieldsJson === "" ? "" : JSON.parse(appDto.configFieldsJson)

                let appDtoObj = {
                    id: appDto['id'],
                    name: appDto.name,
                    protocolName: appDto.protocolName.toUpperCase(),
                    protocolVersion: appDto.protocolVersion,
                    domainName: appDto.domainName,
                    logoUrl: appDto.logoUrl,
                    supportPageUrl: appDto.supportPageUrl,
                    configFieldsJson: objectField,
                    integrationBuiltBy: appDto.integrationBuiltBy,
                    appNameAlias: appDto.appNameAlias
                }

                console.log("object fields==>", objectField)
                // if (selectedFile != null) {
                //     uploadImageOnS3()
                // }else{
                submitAppData(appDtoObj)
                // }

                console.log('Form submitted:');
            } else {
                console.log('Form has validation errors.');
            }



        } 
    }


    const submitAppData = (appDtoObj) => {
        http.post('/integrations/apps/add-app', appDtoObj)
            .then((response) => {
                if (response.status == 200) {
                    console.log("App added into Db...", response.data)
                    // window.location.href = "/integrations/apps"
                    if (props.isEditApp) {
                        setSnackbarProps({ ...snackbarProps, open: true, variant: "success", message: "App updated Succesfully" });
                    } else {
                        setSnackbarProps({ ...snackbarProps, open: true, variant: "success", message: "App created Succesfully" });
                    }

                }
            }).catch((error) => {
                if (props.isEditApp) {
                    setSnackbarProps({ ...snackbarProps, open: true, variant: "danger", message: "Error while creating App " });
                } else {
                    setSnackbarProps({ ...snackbarProps, open: true, variant: "danger", message: "Error while updating App" });
                }
            })
    }

    const handleCloseDialog = () => {
        setOpen(false);
        props.handleCloseDialog(false);
    };

    const handleLogoUpload = (event) => {

        const fileInput = event.target.files[0];
        if (event.target.files.length === 0) {
            return;
        }
        uploadImageOnS3(fileInput, event)
    };

    const uploadImageOnS3 = (fileInput, event) => {

        const formData = new FormData();
        formData.append('logoFile', fileInput);
        formData.append('logoName', appDto.name)

        http.post('/integrations/apps/upload-logo', formData)
            .then((response) => {
                console.log('Logo URL:', response.data);
                setAppDto(
                    { ...appDto, logoUrl: response.data }
                );
                setSnackbarProps({...snackbarProps, open: true, variant: "success", message:"Logo uploaded successfully"})
                setPrevImg(URL.createObjectURL(event.target.files[0]))
            })
            .catch((error) => {
                setSnackbarProps({...snackbarProps, open: true, variant: "error", message:error.response.data.message})
            })

    }

    const handleCloseSnackbar = () => {
        setSnackbarProps({ ...snackbarProps, open: false, variant: "", message: "" });

    }

    return (


        <div>
            {snackbarProps.open &&
                <CustomizedSnackbar onClose={handleCloseSnackbar} {...snackbarProps}></CustomizedSnackbar>}

            <Dialog PaperProps={{ style: { maxHeight: "100%", minWidth: "700px", margin: "0px 0px 0px auto", borderRadius: '0px' } }}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                scroll="paper"
                onClose={() => { handleCloseDialog(false) }}
                aria-describedby="alert-dialog-slide-description"

            >
                <DialogTitle style={{ borderBottom: 'solid 1px', borderColor: '#BED3E4', padding: '16px 24px', fontSize: '16px' }}>
                    <Typography style={{ fontSize: '16px', color: '#001F52', fontFamily: 'NotoSans-Medium' }}>
                        Add/Edit App <IconButton
                            aria-label="close"
                            onClick={() => { handleCloseDialog(false) }}
                            style={{ marginLeft: '76.5%', padding: '0px', background: 'transparent' }}

                        >
                            <img height="24" width="24" src={MORE_APPS_CONFIG.lmsFullPath + '/close.svg'} alt="close" />
                        </IconButton>
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ height: '600px', padding: '24px' }}>

                    <React.Fragment>
                        <Container maxWidth="false" style={{ paddingLeft: '0px', paddingRight: '0px' }} >

                            <div>

                                <Grid container style={{ backgroundColor: '#F8FAFC', marginTop: '16px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '5px', padding: '20px 21px' }} >


                                    <Grid item xs={6}>
                                        <Box display="flex" flexWrap="nowrap">
                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>App Name</Typography>
                                            {isSubmit && <Typography style={{ color: 'red', fontSize: '12px', paddingTop: '3px', fontFamily: 'NotoSans-Medium' }}>
                                                {formErrors.name}
                                            </Typography>}

                                        </Box>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display="flex" flexWrap="nowrap">

                                            <Input name="name" type="text" value={appDto.name} onChange={handleChange} disableUnderline
                                                inputProps={{
                                                    style: {
                                                        width: '248px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px',
                                                        padding: '10px 16px', fontSize: '14px', color: '#8096B2', fontFamily: 'NotoSans-Regular', background: '#fff'
                                                    }
                                                }}
                                                variant="standard" />
                                        </Box>

                                    </Grid>

                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box display="flex" flexWrap="nowrap">
                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>Protocol Name</Typography>
                                            {isSubmit && <Typography style={{ color: 'red', fontSize: '12px', paddingTop: '3px', fontFamily: 'NotoSans-Medium' }}>
                                                {formErrors.protocolName}
                                            </Typography>}
                                        </Box>

                                    </Grid>
                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box display="flex" flexWrap="nowrap">

                                            <Input name="protocolName" type="text" value={appDto.protocolName} onChange={handleChange} disableUnderline
                                                inputProps={{
                                                    style: {
                                                        width: '248px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px',
                                                        padding: '10px 16px', fontSize: '14px', color: '#8096B2', fontFamily: 'NotoSans-Regular', background: '#fff'
                                                    }
                                                }}
                                                variant="standard" />
                                        </Box>

                                    </Grid>

                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box display="flex" flexWrap="nowrap">
                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>Support Page Url</Typography>

                                        </Box>

                                    </Grid>
                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box display="flex" flexWrap="nowrap">

                                            <Input name="supportPageUrl" type="text" value={appDto.supportPageUrl} onChange={handleChange} disableUnderline
                                                inputProps={{
                                                    style: {
                                                        width: '248px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px',
                                                        padding: '10px 16px', fontSize: '14px', color: '#8096B2', fontFamily: 'NotoSans-Regular', background: '#fff'
                                                    }
                                                }}
                                                variant="standard" />
                                        </Box>

                                    </Grid>

                                    {appDto.protocolName && appDto.protocolName.toUpperCase() === "ATS" &&
                                        <>
                                            <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                                <Box display="flex" flexWrap="nowrap">
                                                    <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>Mandatory Fields Json</Typography>

                                            </Box>

                                            </Grid>
                                            <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                                <Box display="flex" flexWrap="nowrap">

                                                <TextField disableUnderline className={classes.textfield} id="filled-multiline-static" multiline value={appDto.configFieldsJson} maxRows={5} onChange={handleChange} name='configFieldsJson' inputProps={{ style: { height: '80px' } }}
                                                    style={{ width: '248px', padding: '10px 16px', textAlign: 'center', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', background: '#fff' }} />

                                            </Box>

                                            </Grid>
                                        </>}


                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box display="flex" flexWrap="nowrap">
                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}> Upload Logo</Typography>

                                        </Box>

                                    </Grid>
                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box style={{ display: "flex", flexWrap: "nowrap", gap: '40px' }}>
                                            <form id="img-upload-form" style={{ paddingTop: '10px' }}>
                                                <Input disableUnderline accept=".jpg, .jpeg, .png ,.svg" id="upload-data" type="file" style={{ display: 'none' }} onChange={handleLogoUpload} />

                                                <label htmlFor="upload-data">

                                                    <Typography style={{ padding: "8px 16px", fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#8096B2', display: "inline", border: '1px solid #BED3E4', borderRadius: '4px', background: '#fff' }}>Upload Logo</Typography>
                                                </label>

                                            </form>
                                            {/* {(props.isEditApp && (appDto.logoUrl != null || prevImg != null)) && <img style={{ height: '50px', width: '50px', border: '1px solid #BED3E4', borderRadius: '4px' }} src={appDto.logoUrl != null ? appDto.logoUrl : prevImg} />}
                                            {(!props.isEditApp && prevImg != null) && <img style={{ height: '50px', width: '50px', border: '1px solid #BED3E4', borderRadius: '4px' }} src={prevImg} />} */}
                                            {(props.isEditApp && appDto.logoUrl!=null) && <img style={{ height: '50px', width: '50px', border: '1px solid #BED3E4', borderRadius: '4px' }} src={prevImg === null ? appDto.logoUrl : prevImg} />}
                                            {(!props.isEditApp && prevImg != null) && <img style={{ height: '50px', width: '50px', border: '1px solid #BED3E4', borderRadius: '4px' }} src={prevImg} />}
                                        </Box>

                                    </Grid>

                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box display="flex" flexWrap="nowrap">
                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}> Integration Built By</Typography>

                                        </Box>

                                    </Grid>
                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium'}}>{appDto.integrationBuiltBy}</Typography>
                                    </Grid>

                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box display="flex" flexWrap="nowrap">
                                            <Typography style={{ color: '#001F52', fontSize: '14px', fontFamily: 'NotoSans-Medium', padding: '0px 10px' }}>App Name Alias</Typography>

                                        </Box>

                                    </Grid>
                                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                                        <Box display="flex" flexWrap="nowrap">

                                            <Input name="appNameAlias" type="text" value={appDto.appNameAlias} onChange={handleChange} disableUnderline
                                                inputProps={{
                                                    style: {
                                                        width: '248px', border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px',
                                                        padding: '10px 16px', fontSize: '14px', color: '#8096B2', fontFamily: 'NotoSans-Regular', background: '#fff'
                                                    }
                                                }}
                                                variant="standard" />
                                        </Box>

                                    </Grid>



                                </Grid>


                            </div>

                        </Container>
                    </React.Fragment>
                </DialogContent>
                <DialogActions style={{ padding: '16px 24px', display: 'flex', justifyContent: 'space-between', boxShadow: '2px 2px 17px 6px rgb(0 0 0 / 12%)' }}>

                    <Button variant="contained" style={{ backgroundColor: '#0B41AD', color: '#ffff', fontSize: '14px', fontFamily: 'NotoSans-Regular', textTransform: 'capitalize' }} onClick={onclicksubmit} className={classes.myClassName}>
                        <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px' }}>Submit</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
}

export default AddApp;

const useStyles = makeStyles((theme) => ({
    myClassName: {
        backgroundColor: '#ffffff',
        color: '#0B41AD',
        "&:hover": {
            backgroundColor: "#0B41AD",
            color: "#ffffff"
        }
    },
    select: {
        backgroundColor: '#ffffff',
        color: '#8096B2',
        border: '1px solid #BED3E4',
        borderRadius: '4px',
        padding: '10px 16px',
        '& .MuiSelect-select.MuiSelect-select': {
            padding: '0px',
            width: '248px'
        }
    },
    textfield: {
        '& .MuiInput-root': {
            fontSize: '14px',
            fontFamily: 'NotoSans-Regular',
            color: '#8096B2'
        },

        '& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root:after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:visited:not(.Mui-disabled):after': {
            borderBottom: 'none',
        },
        '& .MuiInputBase-root-MuiInput-root:after': {
            borderBottom: 'none',
        }

    }
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});