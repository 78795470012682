import { Box,Checkbox, Button, Container, IconButton, Input, Tooltip, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Menu,MenuItem} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import SerachIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import MORE_APPS_CONFIG from '../moreApps';
import Oauth2WebhookDialog from './Oauth2WebhookDialog';
import CustomizedSnackbar from './shared/CustomizedSnackbar';

import http from 'axios';
import IntegrationRequest from './integration/IntegrationRequest';
import LmsIntegrationProceedDialog from './integration/LmsIntegrationProceedDialog';
import LmsIntegrationResponseDialog from './integration/LmsIntegrationResponseDialog';
import DefaultScheduleSettings from './integration/DefaultScheduleSettings';
import AtsIntegrationKeyDialog from './integration/AtsIntegrationKeyDialog';
import AtsMandatoryFieldsDialog from './integration/AtsMandatoryFieldsDialog';
import AtsRedirectDialog from './integration/AtsRedirectDialog'


const fullPath = MORE_APPS_CONFIG.lmsFullPath

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderBottom: "1px solid #bed3e4"
        }

        //marginTop: 20
    },
    searchContainer: {
        marginBottom: 1
        // marginTop: 20
    },
    cell: {
        padding: '12px 40px', // Adjust the padding values here
    },
    button: {
        backgroundColor: '#ffffff',
        color: '#0B41AD',
        "&:hover": {
            backgroundColor: "#0B41AD",
            color: "#ffffff"
        }
    }
});



// const data = [
//     { id: 1, name: 'AICC', link: 'https://support.mettl.com/portal/en/kb/articles/aicc-configuration', imgUrl: "NA", enabled: true },
//     { id: 2, name: 'Ashby', link: 'https://support.mettl.com/portal/en/kb/articles/integration-with-ashby', imgUrl: MORE_APPS_CONFIG.lmsFullPath + '/ashby.svg', enabled: false },
//     { id: 3, name: 'Greenhouse', link: 'https://support.mettl.com/portal/en/kb/articles/integration-with-greenhouse', imgUrl: MORE_APPS_CONFIG.lmsFullPath + '/gh.svg', enabled: false },
//     { id: 4, name: 'RippleHire', link: 'https://support.mettl.com/portal/en/kb/articles/', imgUrl: "NA", enabled: true },
// ];


export default function IntegrationsList(props) {

    const [corporateUser, setCorporateUser] = useState({});
    // const [allAssessments, setAllAssessments] = useState([])
    // const [showAssessmentList, setShowAssessmentList] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" });
    const [openOauth2Dialog,setOpenOauth2Dialog]=useState(false);    const [anchorElForType, setAnchorElForType] = React.useState(null);
    const [anchorElForSort, setAnchorElForSort] = React.useState(null);
    const [anchorElForMore, setAnchorElForMore] = React.useState(null);
    const [checkType,setCheckType]=React.useState("");
    const [checkSort,setCheckSort]=React.useState("");
    const [appList,setAppList]=useState([]);
    const [lmsData,setLmsData]=useState(null);
    const [openResponseDialog,setOpenResponseDialog]=useState(false)
    const [showScheduleSettings,setShowScheduleSettings]=useState(false)
    const [selectedAppData, setSelectedAppData]=useState({})
    const [isNewSchedule,setIsNewSchedule]=useState(false)
    const [updateData,setUpdateData]=useState({})
    const [isAtsKeyClicked,setIsAtsKeyClicked]=useState(false)
    const [atsDialogData,setAtsDialogData]=useState({})
    const [isMandatory,setIsMandatory]=useState(false)
    const [mandatoryFields,setMandatoryFields]=useState({})
    const [openRedirectDialog,setOpenRedirectDialog]=useState(false)
    const [moreUrls,setMoreUrls]=useState({
        supportPageUrl:null,
        link:null
    })
    const [clientData,setClientData]=React.useState({
        publickKey:"",
        secret:"",
        name:""
      })
   
    const classes = useStyles();

    const openType = Boolean(anchorElForType);
    const openSort = Boolean(anchorElForSort);
    const openMore = Boolean(anchorElForMore);
    
    const [showAppList, setShowAppList] = useState(false);
    
    const handleClickOnMenu = (event,name,row) => {
        console.log("name==>",name)
        if(name==="Type"){
            setAnchorElForType(event.currentTarget);
        }else if(name==="Sort"){
            setAnchorElForSort(event.currentTarget);
        }else{
            setMoreUrls(
                (prevState) => {

                    return ({
                        ...prevState,
                        supportPageUrl: row['supportPageUrl'],
                        link:null
                        
                    })
                })
            setAnchorElForMore(event.currentTarget)
        }
       
    };
  
  
    const handleCloseMenu = (event,name) => {
        console.log("name==>",name)
        if(name==="Type"){
            setAnchorElForType(null);
        }else if(name==="Sort"){
            setAnchorElForSort(null);
        }else{
            setAnchorElForMore(null)
        }
    };

    const handleMenuItemChange = (event) => {
        console.log("checkbox clicked..")
        const { name, value, type, checked } = event.target;
        var localName=name;
        if(["All","LMS","ATS"].includes(name) ){
            if(checked){
                setCheckType(name);
            }else{
                localName=""
                setCheckType("");
            }
            filterAppListBasedOnTypeAndSort(localName,"Type")
           
        }else if(["Mettl","Partner"].includes(name)){
           
            if(checked){
                setCheckSort(name);
            }else{
                localName=""
                setCheckSort("");
            }
            filterAppListBasedOnTypeAndSort(localName,"Sort")
        }
        
    }

    const handleSearchChange = (event) => {

        setSearchTerm(event.target.value);
    };


    useEffect(() => {
        setCorporateUser(props.corporateUser)
        http.get('/integrations/apps/list')
        .then((response) => {
            if (response.data) {
               response.data.sort(function(a, b) {
                return a.name.localeCompare(b.name);
              })
                console.log("App List from Db...",response.data)
                setAppList(response.data)
                setFilteredData(response.data)
                setShowAppList(true)
                
            }
        }).catch((error) => {
            
        })
    }, [])

   


    useEffect(() => {
        console.log("inside serch term")
        var filterdList = [...appList];
        filterdList = appList.filter((row) =>
            row.name.toLowerCase().includes(searchTerm.toLowerCase()));

        setFilteredData(filterdList);
    }, [searchTerm]);

    const filterAppListBasedOnTypeAndSort=(name,filterType)=>{
        var filterdList = [];
        if(name!="" && name!="All" && filterType==="Type"){
            
            if(checkSort===""){
                filterdList = appList.filter((row) =>
                row.protocolName.toLowerCase().includes(name==="LMS"?"lti":name.toLowerCase()));
            }else{
                filterdList = filteredData.filter((row) =>{
                    return(
                row.protocolName.toLowerCase().includes(name==="LMS"?"lti":name.toLowerCase())
                && row.integrationBuiltBy.toLowerCase().includes(checkSort.toLowerCase())  
                )
            });
         
            }
               
                setFilteredData(filterdList);
            
           
        }else if(name!="" && filterType==="Sort"){
            if(checkType===""){
                filterdList = appList.filter((row) =>
                row.integrationBuiltBy.toLowerCase().includes(name.toLowerCase()));
            }else{
                filterdList = appList.filter((row) =>{
                return(
                row.integrationBuiltBy.toLowerCase().includes(name.toLowerCase())&&
                row.protocolName.toLowerCase().includes(checkType==="LMS"?"lti":checkType.toLowerCase())
                )});
            }
           
            setFilteredData(filterdList);
        }else if(name==="" &&  filterType==="Sort"){
            if(checkType!=""){
                filterdList = appList.filter((row) =>
                row.protocolName.toLowerCase().includes(checkType==="LMS"?"lti":checkType.toLowerCase()));
            }else{
                filterdList = [...appList]
            }
            setFilteredData(filterdList); 
        }else if((name===""|| name ==="All") &&  filterType==="Type"){
            if(checkSort!=""){
                filterdList = appList.filter((row) =>
                row.protocolName.toLowerCase().includes(checkSort.toLowerCase()));
            }else{
                filterdList = [...appList]
            }
            setFilteredData(filterdList); 
        }
        

       
    }




    const handleConfigurationButtonClick = (row) => {
        // setLoading(true)
        let appsAllowed=[];
        if('appsAllowed' in props.corporateUser.clientDto){
            appsAllowed= props.corporateUser.clientDto.appsAllowed.match(/\w+(?:\s+\w+)*/g);
        }

        if (!props.corporateUser.clientDto.integrationProvisionEnabled) {
            setSnackbarProps({ open: true, message: "Please contact your account manager to enable integration provision", variant: "error" })
        } else if (!props.corporateUser.validIntegrationsUser) {
            setSnackbarProps({ open: true, message: "Please contact your account manager to enable role permissions", variant: "error" })
        } else if (!appsAllowed.includes(row.name)) {
            setSnackbarProps({ open: true, message: "Currently, integration is not enabled in your account. Please contact your account manager to enable it", variant: "error" })
        } else if (!props.corporateUser.clientEnabled) {
            setSnackbarProps({ open: true, message: "Please contact your account manager to enable Integrations", variant: "error" })
        } else {
            if(row.name.toLowerCase()=="lever"){
                http.get(`/integrations/atsgeneral/leverurl?clientId=${props.corporateUser.clientDto.mettlClientId}`)
                .then((response) => {
                    console.log("response==>",response)
                    window.open(`${response.data}`, "_blank")
                })
                .catch((error) => {
                    console.log(error);
                });
            } else if(row.protocolName==="LTI" && !row.enabled){
                setLmsData(row)
            } else if(row.protocolName==="ATS" && !row.enabled && row.integrationBuiltBy.toLowerCase()!="partner"){
                    setIsNewSchedule(true);
                  var fieldsArray=  row.configFieldsJson.fields;
                  console.log("fieldsArray==>",fieldsArray)
                  var mandatoryFields=fieldsArray.filter((val)=>{
                    return val.mandatory==="true"
                  })
                  console.log("mandatoryFields==>",mandatoryFields)
                  if(mandatoryFields.length===0){
                    setShowScheduleSettings(true)
                    setSelectedAppData(row)
                  }else{
                   
                    setMandatoryFields(mandatoryFields)
                    setSelectedAppData(row)
                    setIsMandatory(true);
                  }    
            }else if(row.protocolName==="ATS" && row.enabled && row.integrationBuiltBy.toLowerCase()!="partner"){
                setIsNewSchedule(false);
                editConfiguration(row)
            }
            else{
                let appName = row.name.toLowerCase();
                console.log(appName)
                window.location.href = `/integrations/${appName}`
            }
            
        }

    }

    const handleKeyButtonClick=(row)=>{
        let appsAllowed=[];
        if('appsAllowed' in props.corporateUser.clientDto){
            appsAllowed= props.corporateUser.clientDto.appsAllowed.match(/\w+(?:\s+\w+)*/g);
        }

        if (!props.corporateUser.clientEnabled) {
            setSnackbarProps({ open: true, message: "Please contact your account manager to enable Integrations", variant: "error" })
        } else if (!appsAllowed.includes(row.name)) {
            setSnackbarProps({ open: true, message: "Currently, integration is not enabled in your account. Please contact your account manager to enable it", variant: "error" })
        } else if (!props.corporateUser.validIntegrationsUser) {
            setSnackbarProps({ open: true, message: "Please contact your account manager to enable role permissions", variant: "error" })
        } else {
            if(row.protocolName==="LTI" && row.enabled){
                onClickedLmsKey(row)
              
            } else if((row.protocolName==="ATS" && row.enabled)||(row.integrationBuiltBy.toLowerCase()==="partner")){
                onClickedAtsKey(row)
                
            }
            
        }
        
    }
    const editConfiguration=(row)=>{
        http.get(`/integrations/apps/client_config?appId=${row.id}`)
            .then((response) => {
                console.log("response==>",response)
            //    props.closeDefaultSettings(false, {
            //     open: true,
            //     message: "Schedule Settings saved Sucessfully",
            //     variant: "success"
            // })
            let defaultSettings=JSON.parse(response.data.defaultScheduleSettings)
            console.log("response==>",defaultSettings)
            setSelectedAppData(row);
            setUpdateData(response.data)
            setShowScheduleSettings(true)
           
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const onClickedAtsKey=(atsData)=>{
    fetch("/integrations/apps/configure",{
        method:'post',
        body:JSON.stringify(atsData),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((data) => {
            setAtsDialogData(
                (prevState) => {
        
                    return ({
                        ...prevState,
                        ['apiKey']: data['userName'],
                        ['name']:atsData.name,
                        ['appId']:atsData.id,
                        ['builtBy']:atsData.integrationBuiltBy,
                        ['password']: data['password'],
                        ['publicKey']: data['publicKey'],
                        ['privateKey']: data['privateKey'],
                        ['email']:corporateUser.emailId
                    })
                }
            )
            setIsAtsKeyClicked(true)

            
         
          
        })
        .catch((error) => console.log(error));
      }

      const onClickedLmsKey=(LmsData)=>{
        fetch("/integrations/apps/configure",{
            method:'post',
            body:JSON.stringify(LmsData),
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then((response) => response.json())
            .then((data) => {
              setClientData(
                (prevState) => {
        
                  return ({
                      ...prevState,
                      publicKey: data['publicKey'],
                      secret:data['secret'],
                      name:LmsData.name
                      
                  })
              }
              )
              console.log(data);
              setOpenResponseDialog(true)
              
            })
            .catch((error) => console.log(error));
          }
        
    
    

    const onLmsDialogClose = (value) => {
        setLmsData(null);

    }
    const responseDialogHandleClose=()=>{
        setOpenResponseDialog(false)
        setIsAtsKeyClicked(false)
        setOpenRedirectDialog(false)
       
    }

    const mandatoryDialogHandleClose=()=>{
        setIsMandatory(false)
    }

    const closeButtonClicked=()=>{
        setShowScheduleSettings(false);
    }

    const closeDefaultSettings=(val,snackbar)=>{
        setShowScheduleSettings(false)
         setSnackbarProps(snackbar)
        if(selectedAppData.name.toLowerCase()==="smartrecruiters"){
            setOpenRedirectDialog(true);
        }
        if(selectedAppData.name.toLowerCase()==="ripplehire"){
            window.location.href='/integrations/ripplehire'
        }
       
    }

    

    const validateDialogHandle=(val)=>{
        setIsMandatory(false)
        setMandatoryFields(val);
        setShowScheduleSettings(true);
    }
    const handleCloseSnackbar = () => {
        setSnackbarProps({ ...snackbarProps, open: false, variant: "", message: "" });

    }

    const onClickOpenOauth2Dialog=()=>{
        setOpenOauth2Dialog(true)
    }

    const handleOauth2DialogClose =(snackBarProps)=>{
        setOpenOauth2Dialog(false)
        setSnackbarProps({ ...snackbarProps, open: snackBarProps.open, variant: snackBarProps.variant, message: snackBarProps.message })
    }
    

    

    return (<div>
        {snackbarProps.open &&
            <CustomizedSnackbar onClose={handleCloseSnackbar} {...snackbarProps}></CustomizedSnackbar>}


        <Backdrop style={{ zIndex: 2 }}
            sx={{ color: '#fff', zIndex: 2 }}
            open={loading}>
            <CircularProgress style={{ color: "white" }} />
        </Backdrop>
      
      {openOauth2Dialog && <Oauth2WebhookDialog open={openOauth2Dialog} handleOauth2DialogClose={handleOauth2DialogClose}></Oauth2WebhookDialog>}
       
        <Container component={Paper} maxWidth="false" style={{ position: 'sticky', top: '64px', zIndex: '1', height: '62px', padding:'11px 40px', borderBottom: 'solid 1px', borderBottomColor: '#BED3E4', boxShadow: 'none' }} >
            <div className={classes.searchContainer}>


                <Box style={{display:"flex",justifyContent:'space-between'}} >
                    {/* <Box style={{display:'flex',alignItems:'center'}}>
                    <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath +'/images/integration/home.svg'} style={{paddingRight:'2px'}} alt="home"></img>
                    <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath +'/images/integration/chevron-right.svg'} style={{paddingRight:'2px'}} alt=""></img>
                    <Typography style={{ color: "#001F52", fontFamily: 'NotoSans-Regular',fontSize:'14px' }}>Global Settings</Typography>
                    <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath +'/images/integration/chevron-right.svg'} style={{paddingRight:'2px'}} alt=""></img>
                    <Typography style={{ color: "#001F52",  fontFamily: 'NotoSans-Regular',fontSize:'14px' }}>Integrations</Typography>
                    </Box> */}
                    <Box display="flex" mx={2} alignItems="center" flexWrap="nowrap" border={1} borderColor="#BED3E4" borderRadius={5} style={{ width: '460px', height: '40px', margin: '0px' }} >

                        <Box px={1} py="3px" minWidth={300} flexGrow={1} >


                            <Input
                                id="input-with-icon-adornment"
                                onChange={handleSearchChange}
                                disableUnderline
                                placeholder={`Search By Integration `}
                                fullWidth
                                value={searchTerm}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SerachIcon color='#4E6287' />
                                    </InputAdornment>
                                }
                                color= '#8096B2'
                                style={{ fontFamily: 'NotoSans-Medium', color: '#8096B2' }}
                            />

                        </Box> 
                    </Box>
                    
                    <Box style={{paddingRight:'16px'}}>
                        <Button variant="outlined" size="medium"  style={{padding:'10px 16px',  fontSize: '14px', fontFamily:'NotoSans', fontWeight: '500', textTransform: 'capitalize', width: 'max-content', border: '1px solid', borderColor: '#0B41AD' }}
                                                className={classes.button} onClick={onClickOpenOauth2Dialog}>
                                               <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'14px'}}>Manage Outh2.0 in Webhook</Typography> 
                        </Button>
                    </Box>


                </Box>
            </div>
        </Container>
        
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>

            <Table  className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: '#f8fafc' }} >
                    <TableRow >

                        <TableCell className={classes.cell}>
                            <Typography style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular',display:'contents' }}>Name
                            </Typography>
                            <img height="20px" width="20px" src={MORE_APPS_CONFIG.lmsFullPath + '/DownArrow.svg'} style={{ position: 'absolute' }} alt=""></img>
                        </TableCell>
                        <TableCell className={classes.cell} >
                            <Typography  style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular',display:'contents' }}>Type</Typography>
                            <IconButton name="Type" onClick={(e)=>handleClickOnMenu(e,"Type")} style={{padding:'0px 0px 7px 7px',background:'transparent',minWidth:'0px'}}>
                            <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/filter.svg'} alt="type"></img>
                            {checkType!="" && <img height="8px" width="8px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/danger.svg'} style={{paddingBottom:'15px',paddingLeft:'15px',position:'absolute'}} alt="type"></img>}
                            </IconButton> 
                            <Menu 
                                id="basic-menu"
                                anchorEl={anchorElForType}
                                open={openType}
                                onClose={(e)=>handleCloseMenu(e,"Type")}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  
                                MenuListProps={{
                                style:{
                                    padding:'0px'
                                }
                               
                                }}
                                style={{marginTop:'35px'}}
                                PaperProps={{
                                    style: {
                                     width: '180px',
                                     padding:'8px',
                                     border: '1px solid #BED3E4',
                                     borderRadius:'4px',
                                     boxShadow:'0px 2px 4px 0px rgba(0, 0, 0, 0.05)'
                                     
                                    }
                                }}
                            >
                              
                                <MenuItem style={{ padding: "10px 8px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }}  >
                                <Checkbox  name="All" color="primary" onChange={handleMenuItemChange}
                                checked={"All"===checkType} style={{ padding: "0px", fontSize: '20px' }} />
                                    <Typography style={{ padding: "0px 0px 0px 12px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }}>All</Typography>
                                </MenuItem>
                                <MenuItem style={{ padding: "10px 8px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }} >
                                <Checkbox  name="LMS" color="primary" onChange={handleMenuItemChange}
                                checked={"LMS"===checkType} style={{ padding: "0px", fontSize: '20px' }} />
                                    <Typography style={{ padding: "0px 0px 0px 12px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }}>LMS</Typography>
                               
                                </MenuItem>
                                <MenuItem style={{ padding: "10px 8px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }}>
                                <Checkbox  name="ATS" color="primary" onChange={handleMenuItemChange}
                                checked={"ATS"===checkType} style={{ padding: "0px", fontSize: '20px' }} />
                                    <Typography style={{ padding: "0px 0px 0px 12px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }}>ATS</Typography>
                               
                                </MenuItem>
                               
                            </Menu>
                            
                        </TableCell>
                        <TableCell className={classes.cell} >
                        <Typography  style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular',display:'contents' }}>Built By</Typography>
                            <IconButton name="Sort" onClick={(e)=>handleClickOnMenu(e,"Sort")} style={{padding:'0px 0px 7px 7px',background:'transparent',minWidth:'0px'}}>
                            <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/filter.svg'} alt="type"></img>
                            {checkSort!="" && <img height="8px" width="8px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/danger.svg'} style={{paddingBottom:'15px',paddingLeft:'15px',position:'absolute'}} alt="type"></img>}
                            </IconButton>
                            <Menu 
                                id="basic-menu"
                                anchorEl={anchorElForSort}
                                open={openSort}
                                onClose={(e)=>handleCloseMenu(e,"Sort")}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  
                                MenuListProps={{
                                style:{
                                    padding:'0px'
                                }
                               
                                }}
                                style={{marginTop:'35px'}}
                                PaperProps={{
                                    style: {
                                     width: '180px',
                                     padding:'8px',
                                     border: '1px solid #BED3E4',
                                     borderRadius:'4px',
                                     boxShadow:'0px 2px 4px 0px rgba(0, 0, 0, 0.05)'
                                     
                                    }
                                }}
                            >
                              
                                <MenuItem style={{ padding: "10px 8px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }} >
                                <Checkbox  name="Mettl" color="primary" onChange={handleMenuItemChange}
                                checked={"Mettl"===checkSort} style={{ padding: "0px", fontSize: '20px' }} />
                                    <Typography style={{ padding: "0px 0px 0px 12px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }}>Mettl</Typography>
                                </MenuItem>
                                <MenuItem style={{ padding: "10px 8px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }} >
                                <Checkbox  name="Partner" color="primary" onChange={handleMenuItemChange}
                                checked={"Partner"===checkSort} style={{ padding: "0px", fontSize: '20px' }} />
                                    <Typography style={{ padding: "0px 0px 0px 12px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }}>Partner</Typography>
                               
                                </MenuItem>
                                
                            </Menu>
                        </TableCell>
                        <TableCell className={classes.cell} style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular' }}>
                        <Typography style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular' }}>Status
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.cell} style={{ width:'50px' }} align='left'>
                        <Typography style={{ color: '#4E6287', fontSize: '14px', fontFamily: 'NotoSans-Regular',paddingLeft:'8px' }}>Action
                            </Typography>
                            </TableCell>
                    </TableRow>
                </TableHead>
               { filteredData.length>0 &&  <TableBody>
                    { filteredData.map((row) => (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >

                            <TableCell className={classes.cell}>
                                <Box display="flex" flexWrap="nowrap" >
                                    <img height="42" width="42" src={row.logoUrl != null ? row.logoUrl : `${MORE_APPS_CONFIG.lmsFullPath}/Rectangle.svg`} alt="error" />
                                    {row.logoUrl === null && <Typography style={{ color: '#001F52', fontFamily: 'inherit', fontSize: '16px', paddingLeft: '15px', paddingTop: '8px', position: 'absolute' }}>{row.name.charAt(0).toUpperCase()}</Typography>}
                                    <Typography style={{ color: '#001F52', fontFamily: 'NotoSans-Regular', fontSize: '16px', paddingLeft: '15px', paddingTop: '8px' }}>
                                        {row.appNameAlias?row.appNameAlias:row.name}
                                    </Typography>
                                </Box>
                            </TableCell>

                            <TableCell className={classes.cell}>
                                <Box display="flex" flexWrap="nowrap" >
                                    
                                    <Typography style={{ color: '#4E6287', fontFamily: 'NotoSans-Regular', fontSize: '14px'}}>
                                       {row.protocolName}
                                    </Typography>
                                </Box>
                            </TableCell>

                            <TableCell className={classes.cell}>
                                <Box display="flex" flexWrap="nowrap" >
                                   <Typography style={{ color: '#4E6287', fontFamily: 'NotoSans-Regular', fontSize: '14px' }}>
                                       {row.integrationBuiltBy}
                                    </Typography>
                                </Box>
                            </TableCell>

                            <TableCell className={classes.cell}>
                               {(!row.enabled && row.integrationBuiltBy!="Partner")&& <Box display="flex" flexWrap="nowrap" >
                                    <img style={{paddingRight:'8px'}} src={`${MORE_APPS_CONFIG.lmsFullPath}`+'/images/integration/action-pink.svg'}></img>
                                    <Typography style={{padding:'6px 0px', color: '#4E6287', fontFamily: 'NotoSans-Regular', fontSize: '14px' }}>
                                      Action Required
                                    </Typography>
                                </Box>}
                                {(row.enabled && row.integrationBuiltBy!="Partner") && <Box style={{display:'inline-flex',padding:'6px 16px',alignItems:'center',borderRadius:'16px',background:'rgba(0, 224, 49, 0.10)'}} >
                                   
                                    <Typography style={{ color: '#14853D', fontFamily: 'NotoSans-Regular', fontSize: '14px' }}>
                                     Configured
                                    </Typography>
                                </Box>}
                            </TableCell>

                            <TableCell className={classes.cell} style={{paddingLeft:'35px',display:'flex',gap:'16px',justifyContent:'end'}}align='left'>
                               { ((row.enabled && row.protocolName==="LTI")||(row.enabled && row.protocolName==="ATS" && row.configFieldsJson?.keyRequired==="true")||(row.integrationBuiltBy.toLowerCase()==="partner"))
                               &&
                               <MsbTooltip title="Copy Key" arrow>
                                 <IconButton style={{ color: '#0B41AD',padding:'10px 12px',borderRadius:'4px' }} onClick={() => { handleKeyButtonClick(row) }}>
                                                <img height="18px" width="18px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/key.svg'} alt="key" />
                                    </IconButton>
                                    </MsbTooltip>}
                               {!(row.enabled && row.protocolName==="LTI" ||  row.integrationBuiltBy.toLowerCase()==="partner")
                                 &&<MsbTooltip title={(row.enabled && row.protocolName==="ATS")?"Update Configuration":"Configure"} arrow>
                                    <IconButton style={{ color: '#0B41AD',padding:'10px 12px',borderRadius:'4px' }} onClick={() => { handleConfigurationButtonClick(row) }}>
                                        <img height="18px" width="18px" src={MORE_APPS_CONFIG.lmsFullPath + '/config.svg'} alt="config" />
                                    </IconButton>
                                </MsbTooltip>}
                                {/* {!row.enabled && <MsbTooltip title="Contact your Account Manager/Account Administrator to enable" arrow>
                                    <IconButton style={{ color: '#0B41AD',padding:'10px 12px',borderRadius:'4px' }} href={`mailto:mettl-delivery@mercer.com?subject= Enable ${row.name} Integration in my account&body=Hi Mercer | Mettl Client Delivery Team,`+ 
                                    `I am looking to enable ${row.name} integration but I don’t seem to have sufficient permissions. Kindly help \n with the same.\nPrimary Login Email ID – \nCompany Name -`}>
                                        <img height="18px" width="18px" src={MORE_APPS_CONFIG.lmsFullPath + '/config.svg'} alt="config" />
                                    </IconButton>
                                </MsbTooltip>} */}
                                <MsbTooltip title="More" arrow>
                                <IconButton name="More" onClick={(e)=>handleClickOnMenu(e,"More",row)} style={{borderRadius:'4px'}}>
                                    <img height="18px" width="18px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/more.svg'} alt="type"></img>
                                   
                                </IconButton>
                                </MsbTooltip>
                                <Menu 
                                id="basic-menu"
                                anchorEl={anchorElForMore}
                                open={openMore}
                                onClose={(e)=>handleCloseMenu(e,"More")}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  
                                MenuListProps={{
                                style:{
                                    padding:'0px'
                                }
                               
                                }}
                                style={{marginTop:'35px'}}
                                PaperProps={{
                                    style: {
                                     width: '180px',
                                     padding:'8px',
                                     border: '1px solid #BED3E4',
                                     borderRadius:'4px',
                                     boxShadow:'0px 2px 4px 0px rgba(0, 0, 0, 0.05)'
                                     
                                    }
                                }}
                            >
                                <MenuItem style={{ padding: "8px 8px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52'}}  onClick={() => {
                                if (moreUrls.supportPageUrl) window.open(`${moreUrls.supportPageUrl}`, "_blank");
                                else setSnackbarProps({ open: true, message: "No support article present", variant: "error" });
                                }} >
                                        <img height="20px" width="20px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/support.svg'} alt="support" style={{padding:'4px'}} />
                                    <Typography style={{ padding: "0px 0px 0px 12px", fontSize: '14px',fontFamily:'NotoSans-Regular',color:'#001F52' }}>Help & Support</Typography>
                                </MenuItem>
                            </Menu>
                            

                                {/* <MsbTooltip title="Support Article" arrow>
                                    <IconButton style={{ color: '#0B41AD' }} onClick={() => window.open(`${row.link}`, "_blank")}>

                                        <img height="18px" width="18px" src={MORE_APPS_CONFIG.lmsFullPath + '/help.svg'} alt="help" />
                                        <a href={row.link} target="_blank" />
                                    </IconButton>
                                </MsbTooltip> */}

                            </TableCell>
                        </TableRow>
                    ))}
                    
                </TableBody>}
             
            </Table>
        </TableContainer>
        {filteredData.length===0 && showAppList && <IntegrationRequest IntegrationName={searchTerm}></IntegrationRequest>}
        {lmsData != null && <LmsIntegrationProceedDialog  onLmsDialogClose={onLmsDialogClose} open={true} lmsData={lmsData}></LmsIntegrationProceedDialog> } 
        {openResponseDialog && <LmsIntegrationResponseDialog  responseDialogHandleClose={responseDialogHandleClose} openResponseDialog={openResponseDialog} clientData={clientData}></LmsIntegrationResponseDialog>} 
   {showScheduleSettings && <DefaultScheduleSettings closeDefaultSettings={closeDefaultSettings} closeButtonClicked={closeButtonClicked} selectedAppData={selectedAppData} isNewSchedule={isNewSchedule} updateData={updateData} mandatoryFields={mandatoryFields}></DefaultScheduleSettings>}
    {isAtsKeyClicked &&<AtsIntegrationKeyDialog responseDialogHandleClose={responseDialogHandleClose} openResponseDialog={isAtsKeyClicked} atsDialogData={atsDialogData}></AtsIntegrationKeyDialog>}
   {isMandatory && <AtsMandatoryFieldsDialog selectedAppData={selectedAppData} openResponseDialog={isMandatory} mandatoryFields={mandatoryFields} responseDialogHandleClose={mandatoryDialogHandleClose} validateDialogHandle={validateDialogHandle}></AtsMandatoryFieldsDialog>}
   {openRedirectDialog &&<AtsRedirectDialog responseDialogHandleClose={responseDialogHandleClose} openResponseDialog={openRedirectDialog} selectedAppData={selectedAppData} ></AtsRedirectDialog>}
    </div>
    );
}


const MsbTooltip = withStyles((theme) => ({
    tooltip: {

        backgroundColor: '#002c77',
        color: '#fffff',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        fontFamily: 'NotoSans-Regular'
    },
    arrow:{
        color: '#002c77'
    }
}))(Tooltip);