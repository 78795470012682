import React, { useEffect } from 'react';
import { Container, IconButton, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


const CustomizedPagination = (props) => {
  const classes = useStyles();
  //   const { currentPage, maxPageLimit, minPageLimit} = props;
  // const totalPages = props.response.totalPages-1;
  // const data = props.response.data;
  const { currentPage, numberOfPages, recordsPerPage } = props

  useEffect(() => {
    console.log("pagination props,", props)
  }, [])

  useEffect(() => {
    console.log("pagination props,", props)
  }, [numberOfPages])

  const handleNextPageClick = () => {

    if (props.currentPage <= numberOfPages) {
      // currentPage=currentPage-1

      let endIdx = (props.currentPage + 1) * recordsPerPage
      let startIdx = endIdx - recordsPerPage
      props.onNextClick({ startIdx, endIdx })
    }

  }

  const handlePreviousPageClick = () => {

    if (props.currentPage >= 1) {

      let endIdx = (props.currentPage - 1) * recordsPerPage
      let startIdx = endIdx - recordsPerPage
      props.onPrevsClick({ startIdx, endIdx })
    }

  }
    return(
       
             <Container >
             <Box style={{display:'flex',justifyContent:"space-between",alignItems:"center"}}>
             
              <Typography style={{color: '#4E6287',fontSize:'14px',fontFamily:'NotoSans-Medium'}}>
                Page {currentPage} of {numberOfPages}
              </Typography>
            
              <Box style={{display:'flex',flexDirection:'row'}}>
             <Button variant="contained" size="medium" onClick={handlePreviousPageClick} style={{ backgroundColor:'#ffffff',color: '#002c77', fontSize: '12px', fontFamily:'NotoSans-Medium', textTransform: 'capitalize', marginRight:'10px',border:'1px solid',width:'110px',justifyContent:'space-between'}}
                 startIcon={<NavigateBeforeIcon />}>
                Previous
              </Button>
             
             <Button variant="contained" size="medium" onClick={handleNextPageClick} style={{ backgroundColor:'#ffffff',color: '#002c77', fontSize: '12px', fontFamily:'NotoSans-Medium', textTransform: 'capitalize', border:'1px solid',width:'110px',justifyContent:'space-between'}}
                 endIcon={<NavigateNextIcon />}>
                Next
              </Button>
              </Box>
              </Box>
             </Container>
       
    )
}
export default CustomizedPagination;

const useStyles = makeStyles((theme) => ({
  myClassName: {
    backgroundColor: '#002c77',
    "&:hover": {
      backgroundColor: "#001f52"
    }
  }
}));