import React from 'react'

import {
    Box, Typography, Icon, Divider, Table, TableRow, TableBody, TableCell, TableHead,TableContainer,Paper, Checkbox, Button, makeStyles
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSync } from '@fortawesome/free-solid-svg-icons'

import { useTable, useSortBy, useRowSelect, usePagination, useFilters } from 'react-table';

const useStyles = makeStyles(theme => ({
    tableCell: {
        padding: theme.spacing(2, 1),
        maxWidth: "150px",
        minWidth:"70px"
        
    }
}));

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <Checkbox
                indeterminate={indeterminate}
                ref={resolvedRef}
                {...rest}
                disableRipple
                color="primary"
                style={{ padding: 0 }} ></Checkbox>
        )
    }
)

export default function SupportTable({ columns, data, handleSelectedRows }) {

    const classes = useStyles();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page, //we are using page instead of rows which has only rows for active page

        // props for pagination
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        selectedFlatRows,
        state: { pageIndex, selectedRowIds, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 }
        },
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.flatColumns.push(columns => [
                {
                    accessor: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    ),
                    disableSortBy: true,
                    disableFilters: true,
                    id: "checkbox"
                },
                ...columns,
            ])
        }
    )

    const handleRowSelection = (action) => {

        //prop handled in parent component
        handleSelectedRows(selectedFlatRows, action);
    }

    const createPaginationButtons = (count) => {
        let buttons = [];

        for (let i = 0; i < count; i++) {
            buttons.push(
                <React.Fragment key={i}>
                    <Button
                        style={{ minWidth: "auto", padding: "1px 8px" }}
                        onClick={() => gotoPage(i)} color="primary" variant={pageIndex === i ? "outlined" : "text"}>
                        {i + 1}
                    </Button>
                </React.Fragment>
            )
        }

        return buttons;
    }

    const emptyRows = pageSize - Math.min(pageSize, rows.length - pageIndex * pageSize);

    return (
        <React.Fragment>
            <Box display="flex" component="header" alignItems="center" p={2}>
                <Box>
                    <Typography component="span" color="textSecondary">
                        {(Object.keys(selectedRowIds).length > 0) ? Object.keys(selectedRowIds).length + " Selected" :
                            "Showing " + page.length + " results out of " + data.length
                        }
                    </Typography>
                    <Box mt={1}>
                        <Typography component="span" color="textSecondary">Filters :{' '}</Typography>
                        {/* render filters here */}
                        {headerGroups.map(headerGroup => headerGroup.headers.map((column, i) => (
                            <span key={i}>{column.canFilter ? column.render('Filter') : null}</span>
                        )))}
                    </Box>
                </Box>
                {(Object.keys(selectedRowIds).length > 0) &&
                    <Box ml="auto">
                        <Button onClick={e => handleRowSelection("publish")} color="primary" variant="outlined" startIcon={<Icon fontSize="small">publish</Icon>}>
                            <i className="fas fa-camera"></i> Publish Result to LMS
                        </Button>
                        {/* <Button onClick={e => handleRowSelection("reschedule")} style={{ marginLeft: "10px" }} color="primary" variant="outlined" startIcon={<FontAwesomeIcon style={{ fontSize: "12px" }} icon={faSync} />}>Regenerate Test Link</Button> */}
                    </Box>
                }
            </Box>
            <Divider></Divider>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
            <Table {...getTableProps()} >
                <TableHead className="bg-light-gray" >
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell className={classes.tableCell} align={column.id === "score" || column.id === "attempt" ? "right" : "left"}
                                    {...column.getHeaderProps(column.getSortByToggleProps())} >
                                    {column.render('Header')}
                                    {
                                        column.canSort &&
                                        <Box component="span" ml={1}><FontAwesomeIcon icon={faSort} /> </Box>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>

                <TableBody className="striped-rows" {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell className={classes.tableCell} {...cell.getCellProps()} align={cell.column.id === 'score' || cell.column.id === 'attempt' ? 'right' : 'left'}>
                                                <Typography title={['course', 'email', 'activity'].indexOf(cell.column.id) >= 0 ? cell.value.toString() : ""} component="div" noWrap>{cell.render('Cell')}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        }
                    )}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 55 * emptyRows }}>
                            <TableCell align="center" style={{ background: "#fff", verticalAlign: 'top' }} colSpan="10" >{rows.length === 0 ? 'No Records Found !' : ''}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            </TableContainer>
            </Paper>
            <Box p={2} display="flex" alignItems="center" justifyContent="center">
                <Button color="primary" onClick={() => previousPage()} disabled={!canPreviousPage}>{'< Prev'}</Button>{' '}
                {createPaginationButtons(pageCount)}
                <Button color="primary" onClick={() => nextPage()} disabled={!canNextPage}>{'Next >'}</Button>{' '}
            </Box>
        </React.Fragment >
    )
}
