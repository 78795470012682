import { Box, Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import ArrowTooltip from '../../../ui-components/tooltip/Tooltip';
import Paragraph from '../../../ui-components/typography/Paragraph';
import ReactChip from '../../../ui-components/chip/Chip';
import MORE_APPS_CONFIG from '../../../moreApps';
import PaginationComponent from '../../../ui-components/pagination/Pagination';
import DeleteAssessmentDialog from './DeleteAssessmentDialog';
import Toast from '../../../ui-components/snackbar/Snackbar';



const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    activeChip: {
        backgroundColor: '#F8FAFC',
        color: '#00968F',
    },
    inActiveChip: {
        backgroundColor: '#8C9FC11A',
        color: '#526381',
    },
});

function Row(props) {

    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [assessmentDataForRow, setAssessmentDataForRow] = useState([]);
    const[deleteAssessmentData,setDeleteAssessmentData]=useState(null);
    const classes = useRowStyles();
    const colors = ["#5C6BC0", "#002C77", "#009DE0", "#00AC41"];
    const[openDeleteDialog,setOpenDeleteDialog]=useState(false)
    const [showToast,setShowToast]=useState(false);
    const [toastMessage,setToastMessage]=useState("");
    const [toastStatus,setToastStatus]=useState("SUCCESS");

    const handleJobClick = async (id, jobSource) => {
        setOpen(!open);
        if (!open) {
            try {
                const assessmentDataForRowResponse = await fetch(`/integrations/ripplehire/assessmentDataForJobId?jobId=${id}&jobSource=${jobSource}`);
                if (!assessmentDataForRowResponse.ok) {
                    throw new Error('Failed to fetch excel data');
                }
                const assessmentDataForRow = await assessmentDataForRowResponse.json();
                setAssessmentDataForRow(assessmentDataForRow);
                console.log("assessment data is", assessmentDataForRow)
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    const handleDelete=(jobRow)=>{
        setToastMessage("");
        setShowToast(false);
        setToastStatus("SUCCESS")
        setOpenDeleteDialog(true)
        setDeleteAssessmentData(jobRow);
    }
    
    const handleDeleteDialogClose =(message,isOpen,toastColor)=>{
        setOpenDeleteDialog(false)
        setToastMessage(message);
        setShowToast(isOpen);
        setToastStatus(toastColor)
        // if(message==="Job Succefully Deleted"){
        //     setTimeout(() => {
        //         window.location.href='/integrations/ripplehire'
        //     }, 4000);
        // }
        
    }

    let avatarText = row.rhJobName.replace(/[^a-zA-Z ]/g, '').toUpperCase().split(' ').map(word => word.charAt(0)).join('').slice(0, 2);

    return (
        <React.Fragment>
             {showToast && <Toast message={`${toastMessage}`} open={showToast} status={toastStatus} />}
            {openDeleteDialog && <DeleteAssessmentDialog open={openDeleteDialog} handleDeleteDialogClose={handleDeleteDialogClose} deleteAssessmentData={row}></DeleteAssessmentDialog>} 
            <TableRow className={classes.root} style={{ backgroundColor: open ? "#F8FAFC" : "", cursor: "pointer" }} onClick={() => handleJobClick(row.rhJobId, row.rhJobSource)} key={props.index}>
                <TableCell >
                    <IconButton aria-label="expand row" size="small" onClick={() => handleJobClick(row.rhJobId, row.rhJobSource)}>
                        {open ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" width="20%">
                    <Box display="flex" alignItems="center">
                        <Avatar style={{ marginRight: "10px", backgroundColor: colors[(props.index) % colors.length], fontSize: 14 }}>{avatarText}</Avatar>
                        <Box display="flex" flexDirection="column">
                            <p style={{ marginBottom: 2, marginTop: 0 }}>
                                {row.rhJobName}
                            </p>
                            {`Id: ${row.rhJobId}`}
                        </Box>
                    </Box>
                </TableCell>
                {/* <TableCell align="left" width="15%"><ReactChip text={row.status}
                    className={row.status == "Active" ? classes.activeChip : classes.inActiveChip}
                /></TableCell> */}
                <TableCell align="left" width="15%">{row.rhJobSource}</TableCell>
                <TableCell align="left" width="15%">{row.assessmentCount}</TableCell>
                <TableCell align="left" style={{ color: "#0b41ad" }} >
                    <Box display="flex" alignItems="center">
                        {open ? <VisibilityOffIcon style={{ width: "16px", height: "16px" }} /> : <VisibilityIcon style={{ width: "16px", height: "16px" }} />}
                        <p style={{ display: "inline", marginLeft: "8px" }}>{open ? "Hide Details" : "View Details"}</p>
                    </Box>
                </TableCell>
                <TableCell align="left" >
                <Box display="flex" alignItems="center">
                 <img style={{ width: "24px", height: "24px", color: "#4E6287" }} onClick={()=>handleDelete(row)}  src={MORE_APPS_CONFIG.lmsFullPath + '/assets/ripplehire/delete.svg'} alt="error" />
                </Box>
                </TableCell>
            </TableRow>
            <TableRow style={{ background: "#E5EDF4" }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={4}>

                            <Table size="small" aria-label="purchases">
                                <TableHead >
                                    <TableRow style={{
                                        background: "#F8FAFC", fontFamily: "NotoSans-Medium", fontSize: "14px",

                                    }}>
                                        <TableCell >Assessment Details</TableCell>
                                        <TableCell style={{ display: "flex", alignItems: "center" }}>Pass / Fail Threshold
                                            <ArrowTooltip text="Criteria of Pass or Fail for a candidate will be defined based on this threshold."><HelpOutlineIcon style={{ width: "16px", height: "16px", color: "#767676", marginLeft: "10px", cursor: "pointer" }} /></ArrowTooltip>

                                        </TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                {assessmentDataForRow && (
                                    <TableBody>
                                        {assessmentDataForRow.map((assessment, index) => (
                                            <TableRow key={index} style={{
                                                background: index % 2 == 0 ? 'white' : "#F8FAFC",
                                            }}
                                            >
                                                <TableCell component="th" scope="row" width="30%" style={{
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px"
                                                }}>
                                                    <Box id={index}>
                                                        <p style={{ marginBottom: "0px" }}>{assessment.assessmentName}</p>
                                                        <p style={{ marginTop: "6px" }}>ID: <span style={{ color: "#8096B2" }}>{assessment.assessmentId}</span></p>
                                                    </Box>

                                                </TableCell>
                                                <TableCell width="22%">{assessment.passPer}</TableCell>
                                                <TableCell align="left" >
                                                    <Box width={50} display="flex" justifyContent="space-between">
                                                        {/* <EditOutlinedIcon style={{ width: "16px", height: "16px", color: "#4E6287" }} />*/}
                                                       
                                                    </Box>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>

                                )}

                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default function JobDetailsTable(props) {

    // const [filterJob, setFilterJob] = useState(props.searchedJob);

    // let filteredJobData = jobData.filter(item => item.rhJobName.toLowerCase().includes(filterJob.toLowerCase()));
    // useEffect(() => {
    //     setFilterJob(props.searchedJob);
    //     console.log("we are on page ", page)
    // }, [props.searchedJob])

    const [page, setPage] = useState(0);
    const [jobData, setJobData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [totalCountForJobs, setTotalCountForJobs] = useState(0);
    const [upArrow,setUpArrow]=useState(true);

    async function fetchTableData(newOffset) {
        try {
            // let clientResponse = await fetch(`/session/redis-test`);
            // let clientResponseData = await clientResponse.json();
            // let clientId = clientResponseData.corporateUser.associatedClient['clientId'];
            let jobResponse = await fetch(`/integrations/ripplehire/getAllJobData?limit=10&offSet=${newOffset}&jobFilter=${props.searchedJob}`);
            let jsonJobData = await jobResponse.json();
            let ascdata= jsonJobData.JobDetails.sort((a,b) => (a.rhJobName > b.rhJobName) ? 1 : ((b.rhJobName > a.rhJobName) ? -1 : 0))
           
            setJobData(jsonJobData.JobDetails);
            setTotalCountForJobs(jsonJobData.TotalJobs)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchTableData(offset);
        console.log("count", jobData)
    }, [page]);

    useEffect(() => {
        fetchTableData(offset);
        console.log("count", jobData)
    }, [props.searchedJob]);



    const handleChangePage = (event, newPage) => {
        const newOffset = newPage * 10;
        setPage(newPage);
        setOffset(newOffset);
        // console.log("page data", newPage);
    };

    const sortJobDetails=(sortName)=>{
        setUpArrow(!upArrow)
        if(sortName==="asc"){
           setJobData( jobData.sort((a,b) => (a.rhJobName > b.rhJobName) ? 1 : ((b.rhJobName > a.rhJobName) ? -1 : 0)))
           
        }else{
            setJobData( jobData.sort((a,b) => (a.rhJobName < b.rhJobName) ? 1 : ((b.rhJobName < a.rhJobName) ? -1 : 0)))
        }
    }

    return (
        <Box
            mt={3}
        >
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow style={{ background: "#F8FAFC" }}>
                            <TableCell style={{
                                paddingTop: "22px",
                                paddingBottom: "22px"
                            }} width="1%" />
                            <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <Box style={{display:'flex',alignItems:'center'}}>
                                <Paragraph paragraphvariant="body1" text="Job Details" />
                               
                            <Button onClick={()=>{sortJobDetails(upArrow?"desc":"asc")}}>
                          {upArrow && <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/up_arrow.svg`} alt="Image Description" style={{ width: 16, height: 16, paddingLeft:'10px' }} /> }
                          {!upArrow && <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/up_arrow.svg`} alt="Image Description" style={{ width: 16, height: 16, paddingLeft:'10px',transform:'rotate(180deg)' }} /> }
                           </Button> </Box>
                                 </TableCell>
                            {/* <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }} align="left"><Paragraph paragraphvariant="body1" text="Status" /></TableCell> */}
                            <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }} align="left"><Paragraph paragraphvariant="body1" text="Source" /></TableCell>
                            <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }} align="left"><Paragraph paragraphvariant="body1" text="Total Assessments" /></TableCell>
                            <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }} align="left"></TableCell>
                            <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }} align="left"></TableCell>
                           
                        </TableRow>
                    </TableHead>
                            {jobData &&
                                <TableBody id="table-data" data-job-length={jobData.length}>
                                                    {jobData.map((row, index) => (
                                                        <Row index={index} row={row} />
                                                    ))}
                                                </TableBody>
                            }
                    
                </Table>
            </TableContainer>
            <PaginationComponent
                count={totalCountForJobs}
                page={page}
                rowsPerPage={10}
                rowsPerPageOptions={[]}
                handleChangePage={handleChangePage}
            />
        </Box>
    );
}