// App.jsx / App.tsx

import React, { useEffect, useState } from 'react';
import {Box} from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './content-styles.css';
const CustomEditor = (props) => {

    const [editorData, setEditorData] = useState(props.Data);

    const editorConfiguration = {
        styles: [
            'content-styles.css'
        ],
       
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote'
        ],
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            ]
        }
      };

      const onChangeEditorData=(event,editor)=>{
        
            const data = editor.getData();
            setEditorData(data)
            console.log({ event, editor, data });
            console.log(data);
            props.onChangeCkeData(data,props.type)
        }

        
      

    return (
        <div>
            <Box>
               
                <CKEditor 
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={editorData}

                   
                    onChange={onChangeEditorData}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
            </Box>
        </div>
    )
}

export default CustomEditor;