import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grey } from '@material-ui/core/colors';

export default function SupportDashboard() {
    return (
        <React.Fragment>
            <Box p={3} mt={2} display="flex" alignItems="center" justifyContent="center" color={grey[200]}>
                <FontAwesomeIcon size="7x" icon={faSearch} />
            </Box>
            <Box textAlign="center" mt={1} mb={5}><Typography>Please use the Search feature on top right to populate data</Typography></Box>
        </React.Fragment>
    )
}