import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import {Dialog,DialogActions,DialogContent,DialogTitle,
    Typography,Button,IconButton,Box,Input,Radio,RadioGroup,FormControl,FormControlLabel} from '@material-ui/core';
import http from 'axios';



    const BootstrapDialog =  makeStyles((theme) => ({
        dialogClass:{
                '& .MuiDialogContent-root': {
                    padding: theme.spacing(2),
                },
                '& .MuiDialogActions-root': {
                    padding: theme.spacing(1),
                },
                '& .MuiDialog-paperWidthSm':{
                    maxWidth:'500px',
                    minWidth:'500px',
                    flexDirection:'column',
                    display:'flex',
                    boxShadow:'none'
                    
                },
               '& .MuiBackdrop-root':{
                backgroundColor:'rgba(52, 64, 84, 0.70)'
                }
                },
                button: {
                    backgroundColor: '#ffffff',
                    color: '#0B41AD',
                    border:'1px solid #0B41AD',
                    "&:hover": {
                        backgroundColor: "#0B41AD",
                        color: "#ffffff"
                    }
                },
                radio:{
                    
                    '& .MuiRadio-colorSecondary.Mui-checked':{
                        color: '#2C6EF2',
                        background:'transparent'
                    }
                }
            }))


export default function Oauth2WebhookDialog(props) {

    const customDialog=BootstrapDialog();
    const [open, setOpen] = useState(props.open);
    const[clientSecretData,setClientSecretData]=useState({
        clientSecret:"",
        grantType:"",
        authUrl:"",
        callType:"Post",
        clientId:"",
        scope:"",
        clientOauthId:""
    });
    const [errors, setErrors] = useState({});
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" });
    //const[isClientIdEmpty,setIsClientIdEmpty]=useState(false)
 
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

   useEffect(()=>{
    getClientSecretData()
   },[])

   const getClientSecretData=()=>{
    const formData=new FormData();
    
    http.get('/integrations/auth_webhook/get_client_secret')
    .then((response)=>{
        if (response.status == 200) {
            console.log("response from mettl Api",JSON.stringify(response.data))
           //let jsonString=JSON.stringify(response.data);
         //  if(response.data!=" "){
            setClientSecretData(response.data)
          // }
            
        }
    })
   }

   const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if(!clientSecretData.authUrl){
        newErrors.authUrl = "Please enter Auth URL";
      isValid = false;
    }
    if(!clientSecretData.clientOauthId){
        newErrors.clientOauthId = "Please enter ClientID";
      isValid = false;
    }
    if(!clientSecretData.clientSecret){
        newErrors.clientSecret = "Please enter Client Secret";
      isValid = false;
    }
    if(!clientSecretData.grantType){
        newErrors.grantType = "Please enter Grant Type";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
   }
   const handleSave=()=>{

    if (validateForm()) {
    const headers = {
        "Content-Type": "application/json" // Set the appropriate content type
      };
   // if(clientSecretData.clientId!=""){
   // formData.append("clientSecretData",clientSecretData)
    http.post('/integrations/auth_webhook/save_client_secret',clientSecretData,{headers})
    .then((response)=>{
        if (response.status == 200) {
            console.log("response from mettl Api",JSON.stringify(response.data))
            setSnackbarProps({open: true, message: "Oauth2 data saved successfully", variant: "success"})
            props.handleOauth2DialogClose({open: true, message: "Oauth2 data saved successfully", variant: "success"})
           //let jsonString=JSON.stringify(response.data);  
        }
    })
    }
    // }else{
    //     setIsClientIdEmpty(true);
    // }
   }

    const handleClose = () => {
        setOpen(false);
        setSnackbarProps({open: false, message: "", variant: "success"})
        props.handleOauth2DialogClose(snackbarProps)
    };

    const handleChange=(event)=>{
        const { name, value, type, checked } = event.target;

        // if(name==="clientId" && value !==""){
        //     setIsClientIdEmpty(false);
        // }
        setClientSecretData(
            { ...clientSecretData, [name]: value }
        )

    }
    return(
        <div>

        <Dialog
            open={open}
            onClose={handleClose}
          className={customDialog.dialogClass}
        >
            <DialogTitle style={{padding:'20px 32px'}}>
                <Box style={{display:'flex',justifyContent:'space-between'}}>
                <Typography style={{color:'#001F52',fontSize:'16px',fontFamily:'NotoSans-Regular'}}>Manage Outh2.0 in Webhook URL </Typography>
                <IconButton aria-label="close" style={{padding:'0px'}} onClick={ handleClose}  >
                  <CloseIcon />
                 </IconButton>
                 </Box>
            </DialogTitle>
        <DialogContent style={{padding:'24px 32px',flexDirection: 'column',alignItems:'center',gap:'24px',alignSelf:'stretch',display:'flex',borderTop:'1px solid #EBEBEB',borderBottom:'1px solid #EBEBEB',background:'#FFF'}}>
          <Box  style={{width:'100%'}}>
            <Typography style={{fontSize:'12px',fontFamily:'NotoSans-Regular',color:'#4E6287'}} >Auth Url<span> *</span></Typography>
                <Box style={{width:'100%',height:'40px',flexDirection:'column',display:'flex',paddingTop:'8px'}}>
                <Input type='text' name="authUrl" value={clientSecretData.authUrl} onChange={handleChange}
                 disableUnderline inputProps={{style: { border: 'solid 1px', borderColor: '#D0D5DD', borderRadius: '4px', padding: '10px 16px', color: '#344054', fontSize: '14px', fontFamily: 'NotoSans-Regular',background:'#fff'}}} ></Input>
                </Box>  
                {errors.authUrl && <Typography style={{fontSize:'10px',fontFamily:'NotoSans-Regular',color:'red'}}>{errors.authUrl}</Typography>}                                         
          </Box>

          <Box  style={{width:'100%',display:'flex',alignItems:'center'}}>
          <Typography style={{fontSize:'12px',fontFamily:'NotoSans-Regular',color:'#4E6287',width:'85px'}}>Call Type:<span> *</span></Typography>
                <Box style={{width:'100%',height:'40px',flexDirection:'column',display:'flex',paddingLeft:'24px'}}>
                <FormControl>
                    <RadioGroup
                    row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={clientSecretData.callType}
                        value={clientSecretData.callType}
                        name="callType"
                        onChange={handleChange}
                    >
                        <FormControlLabel  className={customDialog.radio} value="Post" control={<Radio/>} label="Post" />
                        <FormControlLabel className={customDialog.radio} value="Get" control={<Radio  />} label="Get" />
                    </RadioGroup>
                </FormControl>
                </Box>                                           
          </Box>

          <Box  style={{width:'100%'}}>
            <Typography style={{fontSize:'12px',fontFamily:'NotoSans-Regular',color:'#4E6287'}}>Client ID<span> *</span></Typography>
                <Box style={{width:'100%',height:'40px',flexDirection:'column',display:'flex',paddingTop:'8px'}}>
                <Input type='text' name="clientOauthId"value={clientSecretData.clientOauthId}  onChange={handleChange}
                disableUnderline inputProps={{style: { border: 'solid 1px', borderColor: '#D0D5DD', borderRadius: '4px', padding: '10px 16px', color: '#344054', fontSize: '14px', fontFamily: 'NotoSans-Regular',background:'#fff'}}} ></Input>
                </Box> 
                {errors.clientOauthId && <Typography style={{fontSize:'10px',fontFamily:'NotoSans-Regular',color:'red'}}>{errors.clientOauthId }</Typography>}                                            
          </Box>

          <Box  style={{width:'100%'}}>
            <Typography style={{fontSize:'12px',fontFamily:'NotoSans-Regular',color:'#4E6287'}}>Client Secret<span> *</span></Typography>
                <Box style={{width:'100%',height:'40px',flexDirection:'column',display:'flex',paddingTop:'8px'}}>
                <Input type='text' name="clientSecret" value={clientSecretData.clientSecret}  onChange={handleChange}
                disableUnderline inputProps={{style: { border: 'solid 1px', borderColor: '#D0D5DD', borderRadius: '4px', padding: '10px 16px', color: '#344054', fontSize: '14px', fontFamily: 'NotoSans-Regular',background:'#fff'}}} ></Input>
                </Box>
                {errors.clientSecret && <Typography style={{fontSize:'10px',fontFamily:'NotoSans-Regular',color:'red'}}>{errors.clientSecret}</Typography>}                                             
          </Box>

          <Box  style={{width:'100%'}}>
            <Typography style={{fontSize:'12px',fontFamily:'NotoSans-Regular',color:'#4E6287'}}>Grant Type<span> *</span></Typography>
                <Box style={{width:'100%',height:'40px',flexDirection:'column',display:'flex',paddingTop:'8px'}}>
                <Input type='text' name="grantType" value={clientSecretData.grantType}  onChange={handleChange}
                disableUnderline inputProps={{style: { border: 'solid 1px', borderColor: '#D0D5DD', borderRadius: '4px', padding: '10px 16px', color: '#344054', fontSize: '14px', fontFamily: 'NotoSans-Regular',background:'#fff'}}} ></Input>
                </Box>  
                {errors.grantType && <Typography style={{fontSize:'10px',fontFamily:'NotoSans-Regular',color:'red'}}>{errors.grantType}</Typography>}                                                                                      
          </Box>
          <Box  style={{width:'100%'}}>
            <Typography style={{fontSize:'12px',fontFamily:'NotoSans-Regular',color:'#4E6287'}}>Scope</Typography>
                <Box style={{width:'100%',height:'40px',flexDirection:'column',display:'flex',paddingTop:'8px'}}>
                <Input type='text' name="scope"value={clientSecretData.scope}  onChange={handleChange}
                disableUnderline inputProps={{style: { border: 'solid 1px', borderColor: '#D0D5DD', borderRadius: '4px', padding: '10px 16px', color: '#344054', fontSize: '14px', fontFamily: 'NotoSans-Regular',background:'#fff'}}} ></Input>
                </Box>                                           
          </Box>
        </DialogContent>
        <DialogActions style={{padding:'16px 32px',gap:'12px'}}>
          <Button className={customDialog.button} style={{padding:'10px 16px',textTransform:'capitalize'}} onClick={handleClose}><Typography style={{fontSize:'12px',fontFamily:'NotoSans-Regular'}}>Cancel</Typography></Button>
          <Button style={{padding:'10px 16px',textTransform:'capitalize', backgroundColor: "#0B41AD",color: "#ffffff"}} onClick={handleSave} autoFocus>
          <Typography style={{fontSize:'12px',fontFamily:'NotoSans-Regular'}}>Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>

        </div>
    )
}