import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Box, Input } from '@material-ui/core';
import MORE_APPS_CONFIG from '../../moreApps';
import http from 'axios';
import CloseIcon from '@material-ui/icons/Close';


const BootstrapDialog = makeStyles((theme) => ({
  dialogClass: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paperWidthSm': {
      minWidth: '480px',
      flexDirection: 'column',
      display: 'flex',
      boxShadow: 'none'

    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(52, 64, 84, 0.70)'
    }
  },
  buttonClass: {
    backgroundColor: '#ffffff',
    color: '#0B41AD',
    "&:hover": {
      backgroundColor: "#0B41AD",
      color: "#ffffff"
    },
    border: '1px solid #0B41AD',
    borderRadius: '4px',
    fontFamily: 'NotoSans-Regular',
    fontSize: '14px'

  },
}));

export default function ProceedIntegrationDialog(props) {
  const [open, setOpen] = React.useState(props.openDialog);
  const customDialogClass = BootstrapDialog();
  const [selectedApps, setSelectedApps] = useState(props.selectedApps)








  useEffect(() => {
    console.log("selected apps", props.selectedApps)

  }, []);


  const handleClose = (snackProp) => {
    setOpen(false);
    props.proceedIntegrationDialogClose(snackProp)
  };


  const handleProceedClick = () => {
    const formData = new FormData();
    formData.append('appList', selectedApps);


    const timeout = setTimeout(() => {
      window.location.href = "/integrations/"
    }, 4000);

    http.post('/integrations/apps/add-client', selectedApps)
      .then((response) => {
        if (response.status == 200) {
          console.log("client added successful.")
          let snackProp = { open: true, message: "Your request has been submitted successfully", variant: "success" }

          handleClose(snackProp);
          timeout();


        }
      }).catch((error) => {

      })
  }



  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog className={customDialogClass.dialogClass}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{ padding: '0px' }}>
          <Box style={{ padding: '20px 32px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch' }}>

            <Typography style={{ fontFamily: 'NotoSans-Medium', fontSize: '16px', color: '#001F52' }}> Map Client Integrations </Typography>

            <IconButton style={{ fontFamily: 'NotoSans-Regular', color: '#001F52', padding: '0px', borderRadius: '0px' }} onClick={handleClose}><CloseIcon /></IconButton>
          </Box>
        </DialogTitle>


        <DialogContent style={{ padding: '16px 32px', alignSelf: 'stretch', gap: '16px' }} dividers>

          <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#001F52', paddingBottom: '16px' }}>
            Partner integrations have been enabled for the following :-
          </Typography>
          <Box style={{
            display: 'flex', width: '417px', padding: '16px 8px 16px 16px', flexDirection: 'column', alignItems: 'flex-start', gap: '10px',
            background: '#F8FAFC', borderRadius: '4px', height: '100px', overflow: 'auto'
          }}>
            {selectedApps.map((val, index) => (
              <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#001F52', paddingBottom: '8px' }}>
                {index + 1}. {val}
              </Typography>
            ))}
          </Box>
          <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#001F52', padding: '16px 0px' }}>
            Do you want to proceed further ?
          </Typography>



        </DialogContent>

        <DialogActions style={{ display: 'flex', padding: '16px 32px', alignItems: 'center', gap: '12px', alignSelf: 'stretch', justifyContent: 'flex-end' }}>
          <Button className={customDialogClass.buttonClass} style={{ padding: '10px 16px', gap: '8px', alignItems: 'center', display: 'flex', textTransform: 'capitalize', }} autoFocus onClick={handleClose}>
            Cancle
          </Button>
          <Button style={{
            padding: '10px 16px', gap: '8px', alignItems: 'center', display: 'flex', textTransform: 'capitalize', backgroundColor: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', border: '1px solid #0B41AD',
            borderRadius: '4px', color: '#ffffff'
          }} autoFocus onClick={() => { handleProceedClick() }}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}