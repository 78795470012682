import React, { Component } from 'react';
import './App.css';
import './fonts/Noto_Sans/NotoSans-Medium.woff';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Header from './components/shared/Header';
import Error from './components/shared/Error';
import InstructorComponent from './components/Instructor';
import LTISupport from './components/support/LTISupport';
import Dashboard from './components/dashboard';
import Authorize from './components/Authorize';
import Stages from './components/lever/Stages';
import Integrations from './components/Integrations';
import Aicc from './components/Aicc';
import SmartRecruitersAuthorize from './components/SmartRecruitersAuthorize';
import RippleHire from './components/rippleHire';
import { ThemeProvider } from '@material-ui/core';
import mettlTheme from './custom-theme/mettl-theme';
import AppConfig from './components/app_integration/AppConfig';
import { SnackbarProvider } from 'notistack';
import AppClientMapping from './components/app_integration/AppClientMapping';

class App extends Component {
  render() {
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/map-assessment" component={InstructorComponent} />
          <Route exact path="/support" component={LTISupport} />
          <Route exact path="/mettl-ats/authorize/:clientId" component={Authorize}></Route>
          <Route exact path="/mettl-ats/lever/stages" component={Stages}></Route>
          <Route exact path="/integrations/smart-recruiters/authorize" component={SmartRecruitersAuthorize}></Route>
          <Route exact path="/integrations" component={Integrations} />
          <Route exact path="/integrations/aicc" component={Aicc}></Route>
          <Route exact path="/integrations/apps" component={AppConfig}></Route>
          <Route exact path="/integrations/map-client" component={AppClientMapping}></Route>
          <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={mettlTheme}>
              <Route exact path="/integrations/ripplehire" component={RippleHire}></Route>
            </ThemeProvider>


          </SnackbarProvider>
          <Route path="*" component={Error} />
        </Switch>
      </Router>
    );
  }
}

export default App;