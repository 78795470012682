import React, { useState, useEffect  } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper, { Typography,InputAdornment,InputLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import { Box,Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MORE_APPS_CONFIG from '../../../moreApps';

import DatePicker from "react-datepicker";
import '../activitylog/calender.css'
import "react-datepicker/dist/react-datepicker.css";

const activityFilterOptions = [
    //{ id: '1', label: 'File upload successful' },
    { id: '1', label: 'File upload successful' },
    { id: '2', label: 'File Upload Failed' },
    { id: '3', label: 'File processing successful' },
    { id: '4', label: 'File processing successful (with Error)' },
    { id: '5', label: 'File processing successful (duplicate row)' },
];
const useStyles = makeStyles({
    select: {
        border: '1px solid #BED3E4',
        padding: '8px 12px',
        borderRadius: "4px",
        paddingRight: "2px",
        width:'140px',
        '&:before': {
            borderBottom: 'none',
        }
        
        
    },
    checkBox:{
        inputProps:{
       style:{
        borderRadius: '4px',
        border: '1px, #BED3E4',
        background: '#FFF'
       }},
          '&.Mui-checked': {
            color: '#2C6EF2',
          },
    }
   
});

const MenuProps = {
    PaperProps: {
      style: {
        padding:'0px 12px',
        borderRadius: '4px',
        border: '1px solid var(--Borders-Blue-Gray-300, #BED3E4)',
        background: 'var(--Background-White, #FFF)',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
        marginTop:'50px'
      },
    },
    MenuListProps:{
        display: 'inline-flex',
        padding: '8px 12px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '12px'
       
    }
  };

function FilterDropdown(props) {
    const classes = useStyles();
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedFiltersValues, setSelectedFiltersValues] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [personFilteredData, setPersonFilteredData] = useState([]);
    const [selectedPersonData, setSelectedPersonData] = useState([]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
   

    useEffect(()=>{
        setPersonFilteredData(props.personData)
    },[props.personData])
    
    const handleFilterChange = (event,filterName) => {

        let activityTypeArray=[]
        if(filterName==="Activity_Type"){
            setSelectedFilters(event.target.value);

            activityTypeArray=event.target.value;
       
            let arr= activityFilterOptions.filter((actFilter)=>{
               return  activityTypeArray.some(val => val===actFilter['id'] )
                })
            
       
            //setSelectedFiltersValues(arr)
            props.onFilterChange(arr,filterName);
        }else if(filterName==="Person"){
            setSelectedPersonData(event.target.value)
            props.onFilterChange(event.target.value,filterName);
        }
       

        
       
    };

    const handleDateChange = (date,filterName) => {
        setIsDatePickerOpen(!isDatePickerOpen);
        
            setSelectedDate(date);
        console.log("date...",date)
        props.onFilterChange(date,filterName);
    };

    
const onClickCalenderDate =(date)=>{
    if(selectedDate===date){
        setSelectedDate(null);
    }else{
        setSelectedDate(date);
    }
}

    const handleClick = (e) => {
        e.preventDefault();
        setIsDatePickerOpen(!isDatePickerOpen);
      };

    
    return (

        <Box  style={{ display: "flex" ,paddingLeft:'8px',gap:'8px'}}>
        <FormControl>
        
            <Select
                multiple
                displayEmpty
                disableUnderline
                value={selectedFilters}
                onChange={(event)=>{handleFilterChange(event,"Activity_Type")}}
                renderValue={(selected) => <Typography style={{display:'flex',alignItems:'center',fontSize:'14px',color:'#4E6287',fontFamily:'NotoSans-Regular'}}>Activity Type <img  src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/up_arrow.svg`} alt="Image Description" style={{ width: 16, height: 16,paddingRight:'10px',transform:'rotate(180deg)' }} /></Typography>}
                className={classes.select}
                MenuProps={MenuProps}
                IconComponent={() =>null }
                SelectDisplayProps={{ style: { padding: '0px'}}}
           >
               
                <Box style={{ marginLeft: "8px" }}>
                    <p style={{ color: "#8096B2", fontFamily: "NotoSans-Regular", fontSize: 12 }}>Activity Type</p>
                </Box>
                {/* <Box style={{ display: 'inline-flex',padding: '8px 12px',flexDirection: 'column',justifyContent: 'center',alignItems: 'flex-start',gap: '12px'}}> */}
                {activityFilterOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id} style={{padding: '0px', background:'#fff'}} >
                        <Checkbox type="checkbox" className={classes.checkBox} checked={selectedFilters.indexOf(option.id) > -1} style={{
                            width: '10px', height: '2px'}}  />
                        {(option.id==="1"||option.id==="3") &&<img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/upload-successful.svg`} alt="Image Description" style={{ width: 20, height: 20, marginTop: 2,padding:'0px 10px' }} />}
                        {option.id==="2" &&<img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/error.svg`} alt="Image Description" style={{ width: 20, height: 20, marginTop: 2 ,padding:'0px 10px'}} />}
                        {(option.id==="4"||option.id==="5") &&<img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/processing-successful-with-error.svg`} alt="Image Description" style={{ width: 20, height: 20, marginTop: 2 ,padding:'0px 10px'}} />}
                        <ListItemText primary={option.label} />
                    </MenuItem> 
                ))}
                {/* </Box> */}
                
            </Select>

        </FormControl >
        
        <FormControl>
        <Button disableRipple endIcon={ <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/up_arrow.svg`} alt="Image Description" style={{ width: 16, height: 16, paddingLeft:'4px',paddingRight:'4px',transform:'rotate(180deg)' }} />} style={{display:'flex',width: '140px',padding:'8px 12px',justifyContent:'center',alignItems: 'center',gap: '4px',borderRadius:'4px',border:'1px solid #BED3E4',background: '#FFF'}} onClick={handleClick}>
        <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'14px',color:'#4E6287'}}>Activity Date</Typography>
      </Button>
      {isDatePickerOpen && (
        <DatePicker selected={selectedDate} weekStartsOn={1} onClickOutside={(event)=>isDatePickerOpen?setIsDatePickerOpen(false):setIsDatePickerOpen(true)} onChange={(event)=>{handleDateChange(event,"Activity_Date")}} inline />
      )}
      </FormControl>

       {personFilteredData.length>0 &&
        <FormControl>
            <Select
                multiple
                displayEmpty
                disableUnderline
                value={selectedPersonData}
                onChange={(event)=>{handleFilterChange(event,"Person")}}
                renderValue={(selected) => <Typography style={{display:'flex',alignItems:'center',fontSize:'14px',color:'#4E6287',fontFamily:'NotoSans-Regular'}}> Person <img  src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/up_arrow.svg`} alt="Image Description" style={{ width: 16, height: 16,paddingRight:'10px',transform:'rotate(180deg)' }} /></Typography>}
                className={classes.select}
                style={{width:'110px'}}
                MenuProps={MenuProps}
                IconComponent={() => null}
                SelectDisplayProps={{ style: { padding: '0px'}}}
                startAdornment={
                    <InputAdornment position="start">
                     {selectedPersonData.length> 0  &&
                      <svg width="8" height="8">
                        <circle cx="4" cy="4" r="4" fill="#00AC41"/>
                        </svg>}
                    </InputAdornment>
                  }
            >
               
                <Box style={{ marginLeft: "4px" }}>
                    <Typography style={{ color: "#8096B2", fontFamily: "NotoSans-Regular", fontSize: 12 }}>Person</Typography>
                </Box>
                {/* <Box style={{ display: 'inline-flex',padding: '8px 12px',flexDirection: 'column',justifyContent: 'center',alignItems: 'flex-start',gap: '12px'}}> */}
                {personFilteredData.map((option) => (
                    <MenuItem key={option.roleId} value={option.roleId} style={{padding: '10px 4px',gap:'12px', background:'#fff'}} >
                        <Checkbox className={classes.checkBox} inputProps={classes.checkBox} checked={selectedPersonData.indexOf(option.roleId) > -1} style={{
                            width: '10px', height: '2px'}}  />
                        
                        <Box >
                        <Typography style={{ fontFamily:'NotoSans-Regular',color:'#001F52',fontSize:'14px'}}>{option.clientName}</Typography>
                            <Typography style={{ fontFamily:'NotoSans-Regular',color:'#8096B2',fontSize:'12px'}}>{option.clientEmail}</Typography>
                        </Box>
                       
                    </MenuItem> 
                ))}

                    
                
                {/* </Box> */}
                
            </Select>

        </FormControl >}
       
       
        </Box>


    );
}

export default FilterDropdown;
