import React, { useEffect } from 'react'
import { Box, Container, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";

export default function Dashboard(props) {

    return (
        <Container maxWidth="md" >
            <Box mt={15}>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={10} sm={5}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <Link to={"/support"} style={{ textDecoration: "none", color: "inherit" }}>
                                    <Box px={2} py={6} textAlign="center">
                                        <img height="88" width="88" src="/support_login_icon.png" alt="error" />
                                        <Box mt={4}>
                                            <Typography variant="h5">Login as Support User</Typography>
                                            <Typography variant="subtitle1">Use this to handle support operations</Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <Link to={"/map-assessment"} style={{ textDecoration: "none", color: "inherit" }}>
                                    <Box px={2} py={6} textAlign="center">
                                        <img height="88" width="88" src="/instructor_login_icon.png" alt="error" />
                                        <Box mt={4}>
                                            <Typography variant="h5">Login as Test Creator</Typography>
                                            <Typography variant="subtitle1">Use this to move to the test creation</Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container >
    )
}