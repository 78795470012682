import { createTheme } from '@material-ui/core/styles';

const mettlOriginalTheme = createTheme({
  palette: {
    primary: {
      main: '#0180b2',
    },
    secondary: {
      main: '#d93025'
    }
  },
  typography: {
    htmlFontSize: 16,
    fontWeight: 300,
    fontSize: 12,
    subtitle1: {
      fontSize: '0.75rem',
    },
    h5: {
      fontWeight: 300
    },
    h6: {
      fontWeight: 300
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiInputLabel: {
      // Name of the rule
      root: {
        // Some CSS
        fontSize: '0.75rem',
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          background: '#f5fcfd',
          color: '#0180b2',
        },
      }
    }
  },
});

export default mettlOriginalTheme;