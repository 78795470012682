import React from 'react';
import {
    Button, Icon, Box, List, ListItem, ListItemText, Popper, Fade, Paper, TextField, InputAdornment, Divider,
    ClickAwayListener
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CandidateStatusMap } from './LTISupport'


const useStyles = makeStyles(theme => ({
    root: {
        '&[x-placement*="bottom"]': {
            '& .arrow': {
                position: "absolute",
                fontSize: "7px",
                width: 0,
                height: 0,
                borderLeft: "1em solid transparent",
                borderRight: "1em solid transparent",
                borderBottom: "1em solid #fff",
                marginTop: "-0.9em",
            }
        },
        "& .MuiListItem-root": {
            padding: theme.spacing(0.5, 1),
            '&:hover': {
                background: '#f5fcfd',
                color: '#0180b2',
            },
        },
        "& .listWrapper": {
            maxHeight: "180px",
            overflowY: "auto"
        },
    },
}));

const StatusEventMap = new Map([
    ["Not Taken", "LMS_TEST_START_EVENT"],
    ["In Progress", ["METTL_TEST_STARTED","METTL_TEST_FINISHED"]],
    ["Completed", ["METTL_TEST_GRADED", "LMS_RESULT_PUSH_EVENT"]],
    ["Expired", "METTL_TEST_EXPIRED"]
]);

export function SupportTableFilter({ column: { filterValue, setFilter, preFilteredRows, id }, }) {

    const classes = useStyles();

    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            if (id !== "status") {
                options.add(row.values[id])
            }
            else {
                options.add(CandidateStatusMap.get(row.values[id]).status)
            }
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [arrowRef, setArrowRef] = React.useState(null);
    const [listItems, setlistItems] = React.useState(options);

    const open = Boolean(anchorEl);
    const popperId = open ? 'filter-popper' : undefined;

    const handleOpenPopper = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        if (!open) {
            setlistItems(options);
        }
    }

    const handleArrowRef = node => {
        setArrowRef(node);
    }

    const handleSearch = (event) => {
        let currentOptions = [];
        let filteredOptions = [];
        if (event.target.value !== "") {
            currentOptions = options;
            filteredOptions = currentOptions.filter(item => {
                const lc = item.toLowerCase();
                const searchText = event.target.value.toLowerCase();
                return lc.includes(searchText);
            })
        } else {
            filteredOptions = options;
        }
        setlistItems(filteredOptions);
    }

    const handleSelection = (option, event) => {
        if (id === "status") {
            setFilter(StatusEventMap.get(option) || undefined);

        } else {
            setFilter(option || undefined);
        }
        setAnchorEl(null);
    }

    return (
        <Box component="span">
            <Button disableRipple onClick={handleOpenPopper} endIcon={<Icon color="primary">keyboard_arrow_down</Icon>}>
                {filterValue ? (id === "status" ? (Array.isArray(filterValue) ? CandidateStatusMap.get(...filterValue).status : CandidateStatusMap.get(filterValue).status) : filterValue) : "Select " + id}
            </Button>
            <Popper id={popperId} open={open} anchorEl={anchorEl} className={classes.root} transition
                modifiers={{
                    arrow: {
                        enabled: true,
                        element: arrowRef
                    },
                }}>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={e => setAnchorEl(null)}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper elevation={5}>
                                <Box component="span" className="arrow" ref={handleArrowRef}></Box>
                                <Box py={1} px={2}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        onChange={handleSearch}
                                        InputProps={{
                                            placeholder: "Search...",
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon color="primary" fontSize="small">search</Icon>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <Divider variant="middle" />
                                <Box p={1} className="listWrapper">
                                    <List disablePadding>
                                        <ListItem onClick={(event) => handleSelection("", event)} disableGutters button>
                                            <ListItemText primary="All" />
                                        </ListItem>
                                        {listItems.map((item, index) =>
                                            <ListItem key={index} onClick={(event) => handleSelection(item, event)} disableGutters button>
                                                <ListItemText primary={item} />
                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </Box>
    )
}

export default SupportTableFilter