import React from 'react';
import { Box, Grid, Container, Typography, Button,Toolbar,IconButton,AppBar } from '@material-ui/core';
import MORE_APPS_CONFIG from '../../moreApps';

const Error = (props) => (
    <Container maxWidth="lg" style={{paddingLeft:"0px",paddingRight:"0px",background:"#ffffff",height:"100vh"}} >
        {console.log(props)}
        {console.log(props.status)}
        {console.log(props.page)}
        {console.log(props.message)}
        <Box>
            <Grid container  alignItems="center">
                
                <Grid container className='text-center' alignItems="center"  >
                <Grid  item sm={12} style={{textAlign:"center",height:"40vh",paddingTop:"130px"}}>
                    <img height="80" width="80" src={props.status=="401"? MORE_APPS_CONFIG.lmsFullPath + "/error.png":MORE_APPS_CONFIG.lmsFullPath + "/404.png"} alt="error" />
                </Grid>
                <Grid  item sm={12} style={{textAlign:"center",height:"40vh",paddingTop:"25px"}}>
                {!props.status &&
                        <Box pl={2}>
                            <Typography style={{fontSize:"18px",color:"#002c77",textAlign:"center"}} variant="h5"><Box fontWeight="fontWeightMedium">Page Not Found</Box></Typography>

                                <Box my={2}>
                                    <Typography style={{fontSize:"15px",color:"#8ca0ba",textAlign:"center", fontWeight:"500"}} >We can't find the page you are looking for. </Typography>
                                    <Typography style={{fontSize:"15px",color:"#8ca0ba",textAlign:"center",fontWeight:"500"}}>You can either return to the previous page, or contact our support team </Typography>
                                    <Typography style={{fontSize:"12px",color:"#bccbdb",textAlign:"center",fontWeight:"500"}}>Error Code:404 </Typography>
                                </Box>
                                <Button href="mailto:mettl-support@mercer.com?subject=lms application error!" variant="contained"  style={{background:"#0b41ad",color:"#ffff"}} size="large">Contact Support</Button>
                        </Box>
                    }
                    {props.status == "401" &&
                        <Box pl={2}>
                            <Typography style={{fontSize:"18px",color:"#002c77",textAlign:"center"}} variant="h5"><Box fontWeight="fontWeightMedium">User Session Expired</Box></Typography>
                            <Box my={2}>
                                {props.page==undefined && <Typography style={{fontSize:"15px",color:"#8ca0ba",textAlign:"center",fontWeight:"500"}}>Please access this page again from LMS application</Typography>}
                                {props.page=="corporate" && <Typography style={{fontSize:"15px",color:"#8ca0ba",textAlign:"center",fontWeight:"500"}}>Redirecting to corporate login</Typography>}
                                <Typography style={{fontSize:"12px",color:"#bccbdb",textAlign:"center",fontWeight:"500"}}>Error Code:401 </Typography>
                            </Box>
                        </Box>
                    }
                    {props.status == "403" &&
                        <Box pl={2}>
                            <Typography style={{fontSize:"18px",color:"#002c77",textAlign:"center"}} variant="h5"><Box fontWeight="fontWeightMedium">Access Forbidden</Box></Typography>
                            <Box my={2}>
                                <Typography style={{fontSize:"15px",color:"#8ca0ba",textAlign:"center",fontWeight:"500"}}>{props.message}</Typography>
                                <Typography style={{fontSize:"12px",color:"#bccbdb",textAlign:"center",fontWeight:"500"}}>Error Code:403 </Typography>
                            </Box>
                        </Box>
                    }
                </Grid>
                </Grid>
                {/* <Grid item sm={6}>
                    {props.status != "401" &&
                        <Box pl={2}>
                            <Typography variant="h5"><Box fontWeight="fontWeightMedium">Page Not Found</Box></Typography>

                                <Box my={2}>
                                    <Typography color="textSecondary">We can't find the page you are looking for. </Typography>
                                    <Typography color="textSecondary">You can either return to the previous page, or contact our support team </Typography>
                                </Box>
                                <Button href="mailto:support@mettl.com?subject=lms application error!" variant="contained" color="primary" size="large">Contact Support</Button>
                        </Box>
                    }
                    {props.status == "401" &&
                        <Box pl={2}>
                            <Typography variant="h5"><Box fontWeight="fontWeightMedium">User Session Expired</Box></Typography>
                            <Box my={2}>
                                <Typography color="textSecondary">Please access this page again from LMS application</Typography>
                            </Box>
                        </Box>
                    }
                </Grid> */}
            </Grid>
        </Box>
    </Container>
);

export default Error;
