import React, { Component } from 'react';
import { Container, CircularProgress, Box } from '@material-ui/core';

import http from 'axios';
import AddedAssessment from './addedAssessment';
import AddAssessmentComponent from './addAssessment';
import ScheduleSettingUI from './scheduleSettingUI';
import CustomizedSnackbar from './shared/CustomizedSnackbar'
import Error from './shared/Error'

class InstructorComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      error: "",
      success: false,
      isLoading: false,
      scheduledAssessment: undefined,
      assessments: undefined,
      showScheduleSettingModal: false,
      selectedAssessment: undefined,
      assessmentSetting: {},
      ltiInfo: { user: "", activity: "", course: "" },
      snackbarProps: { open: false, message: "", variant: "" },
      loggedIn:true
    }
  }

  componentDidMount = () => {
    document.title = 'Mettl Lms : Integrate Test';
    this.getAssessmentData();
  }

  handleCancelAssessment = (assessmentId) => {
    var _this = this;
    http.post("/lti/cancel-schedule")
      .then((response) => {
        _this.setState(prevState => ({
          scheduledAssessment: null,
          assessmentSetting: {
            ...prevState.assessmentSetting,
            proctoring: {
              ...prevState.assessmentSetting.proctoring,
              mode: "OFF",
              options:{
                ...prevState.assessmentSetting.proctoring.options,
                candidateAuthorization:false,
                candidateScreenCapture:false,
                check360:false,
                mobileProctoring:false
              }
            },
            apiWebProctoring: {
              ...prevState.assessmentSetting.apiWebProctoring,
              count: 0,
              enabled: false,
              showRemainingCounts: false
            },
            secureBrowser:{
              ...prevState.assessmentSetting.secureBrowser,
              enabled: false
            },
            maxReattemptAllowed:1,
            passPercentage:0,
            cooldownPeriod:0
          }
        }), () => { console.log("updated") });
      })
      .catch((error) => {
        this.setState({
          snackbarProps: { open: true, message: error.response.data.message, variant: "error" }
        });
      });

  }

  handlePickAssessment = (assessment) => {
    this.setState({
      showScheduleSettingModal: true,
      selectedAssessment: assessment
    });
  }

  getAssessmentData = () => {
    this.setState({ isLoading: true });

    http.get("/lti/assessment-data")
      .then((response) => {
        if(response.data.success){
            this.setState({
              isLoading: false,
              scheduledAssessment: response.data.scheduledAssessment,
              assessmentSetting: response.data.assessmentSetting,
              assessments: response.data.assessments,
              ltiInfo: {
                user: response.data.user,
                course: response.data.course,
                activity: response.data.activity,
              },
              success: response.data.success,
            });
        }else{
            this.setState({
              isLoading: false,
              snackbarProps: { open: true, message: response.data.error, variant: "error" }
            });
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status==401){
          this.setState({isLoading: false,loggedIn:false});
        }else{
          this.setState({
            isLoading: false,
            snackbarProps: { open: true, message: error.response.data.message, variant: "error" }
          });
        }
      });
  }

  closeScheduleSetting = (assessment, assessmentSetting) => {
    if (assessment && assessmentSetting) {
      this.setState({
        scheduledAssessment: assessment,
        assessmentSetting: assessmentSetting,
        showScheduleSettingModal: false,
        snackbarProps: { open: true, message: `You have successfully added ${assessment.name} to ${this.state.ltiInfo.course}. You may close this window now.`, variant: "success" }
      });
    }
    else {
      this.setState(prevState => ({
        showScheduleSettingModal: false,
        assessmentSetting: {
          ...prevState.assessmentSetting
        },
        scheduledAssessment: undefined,
      }));
    }
  }

  handleCloseSnackbar = () => {
    this.setState({
      snackbarProps: { open: false, message: "", variant: "" }
    })
  }

  render() {

    return (
      <Container maxWidth="lg" >
        {!this.state.loggedIn &&  <Error status="401"></Error>}

        {this.state.isLoading &&
          <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
            <CircularProgress />
          </Box>
        }

        {this.state.snackbarProps.open &&
          <CustomizedSnackbar onClose={this.handleCloseSnackbar} {...this.state.snackbarProps}></CustomizedSnackbar>}

        {!this.state.isLoading && this.state.scheduledAssessment &&
          <AddedAssessment assessment={this.state.assessments.filter(assessment => assessment.id === this.state.scheduledAssessment.id)[0]} assessmentSetting={this.state.assessmentSetting} handleCancelAssessment={this.handleCancelAssessment}></AddedAssessment>
        }

        {!this.state.isLoading && !this.state.scheduledAssessment && this.state.assessments &&
          <AddAssessmentComponent assessments={this.state.assessments} handlePickAssessment={this.handlePickAssessment} ltiInfo={this.state.ltiInfo}></AddAssessmentComponent>
        }



        {this.state.showScheduleSettingModal &&
          <ScheduleSettingUI isScheduled={Boolean(this.state.scheduledAssessment)} assessmentSetting={this.state.assessmentSetting} isOpen={this.state.showScheduleSettingModal} onClose={this.closeScheduleSetting}
            assessment={this.state.selectedAssessment}></ScheduleSettingUI>}

      </Container >
    )

  };
}


export default InstructorComponent;