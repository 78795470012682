import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import http from 'axios';
import React, { useEffect, useState } from 'react';
import AssessmentList from './AssessmentList';
import CustomizedSnackbar from './shared/CustomizedSnackbar';
import Error from './shared/Error';

export default function Aicc(props) {

    const [isNextAvailable, setIsNextAvailable] = useState(false);
    const [showAssessmentList, setShowAssessmentList] = useState(false);
    const [allAssessments, setAllAssessments] = useState([])
    const [loggedIn, setLoggedIn] = useState(true)
    const [corporateUserPresent, setCorporateUserPresent] = useState(true)
    const [corporateUser, setCorporateUser] = useState({});
    const [status, setStatus] = useState()
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" });


    const handleConfigurationButtonClick = (val) => {
        let filterdAssessments = val.assessments.filter(val1 => {
            return val1.assessmentType != "TASK" && val1.assessmentType != "PRACTICE"
        })
        setAllAssessments(filterdAssessments);
        //setAllAssessments(val.assessments);
        setIsNextAvailable(val.nextAvailable)
        setShowAssessmentList(true)
    }


    const isUserLoggedIn = (val) => {
        console.log("is user logged in??", val)

        let loggedIn = val
        if (!loggedIn) {
            setStatus("401")
            setLoggedIn(val);
            setCorporateUser({})
        }
    }

    const redirectToLogin = () => {
        const loginUrl = "https://" + window.location.hostname + "/corporate/login?returnUrl=" + encodeURIComponent(window.location.href);
        console.log('Redirecting in 1 second to:' + loginUrl);
        setTimeout(() => {
            window.location.assign(loginUrl);
        }, 1000);
    }

    useEffect(() => {
        console.log("props", props)
        http.get('/integrations/user')
            .then((response) => {
                if (response.data) {
                    setCorporateUser(response.data)
                    setCorporateUserPresent(true);
                    if (!response.data.clientDto.integrationProvisionEnabled) {
                        setStatus("403")
                        setMessage("Please contact your account manager to enable integration provision")
                    } else if (!response.data.clientEnabled) {
                        setStatus("403")
                        setMessage("Please contact your Account Manager to enable Integrations")
                    } else if (!response.data.validIntegrationsUser) {
                        setStatus("403")
                        setMessage("Please contact your Administrator to enable role permissions to access Integrations")
                    } else if (!response.data.clientDto.appsAllowed.slice(1,-1).split(", ").includes("AICC")) {
                        setStatus("403")
                        setMessage("Currently, integration is not enabled in your account. Please contact your account manager to enable it")
                    } else {
                        getFirstAssessments()
                    }

                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    console.log("error==>", error)
                    setLoggedIn(false)
                    setStatus("401")
                    setCorporateUser({})
                    redirectToLogin()
                }
                // getFirstAssessments()
            })
    }, []);



    const getFirstAssessments = () => {
        console.log("inside Aicc component")
        setLoading(true)
        http.get('/integrations/aicc/assessments', { params: { limit: 20, offset: 0 } })
            .then((response) => {
                if (response.data.assessments) {
                    console.log("first 20 assessments==>", response.data)

                    let filterdAssessments = response.data.assessments.filter(val => {
                        return val.assessmentType != "TASK" && val.assessmentType != "PRACTICE"
                    })
                    setAllAssessments(filterdAssessments);
                    //setAllAssessments(response.data.assessments);
                    setLoading(false)
                    handleConfigurationButtonClick(response.data)

                } else {
                    setSnackbarProps({
                        open: true,
                        message: response.data.message,
                        variant: "error"
                    })
                    setLoading(false)
                }
            }).catch((error) => {
                setSnackbarProps({
                    open: true,
                    message: error.response.data.message,
                    variant: "error"
                })
                setLoading(false)
                // props.isUserLoggedIn(false)

            });

    }


    const handleCloseSnackbar = () => {
        setSnackbarProps({ ...snackbarProps, open: false, variant: "", message: "" });

    }
    return (
        <div>
            {snackbarProps.open &&
                <CustomizedSnackbar onClose={handleCloseSnackbar} {...snackbarProps}></CustomizedSnackbar>}

            <Backdrop style={{ zIndex: 2 }}
                sx={{ color: '#fff', zIndex: 2 }}
                open={loading}>
                <CircularProgress style={{ color: "white" }} />
            </Backdrop>

            {/* {corporateUserPresent && <IntegrationHeader corporateUser={corporateUser}></IntegrationHeader>} */}
            {loggedIn && corporateUserPresent && showAssessmentList && <AssessmentList corporateUser={corporateUser} allAssessments={allAssessments} isUserLoggedIn={isUserLoggedIn} isNextAvailable={isNextAvailable} redirectToLogin={redirectToLogin}></AssessmentList>}
            {(!loggedIn || status) && <Error status={status} page="corporate" message={message}></Error>}
        </div>
    );

}