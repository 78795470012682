import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { withStyles, alpha } from "@material-ui/core/styles";
import { AppBar, Container, Toolbar, IconButton, Box, Card, Typography, Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntegrationHeader from '../IntegrationHeader';
import MORE_APPS_CONFIG from '../../moreApps'
import http from 'axios';

const Header = () => {

  //const [corporateUser, setCorporateUser] = React.useState({});
  // const [anchorElUser, setAnchorElUser] = React.useState(null);
  // const settings = ['Examine', '360View', 'X-ATHON', 'CertiCheck', 'ExamineER', 'Interviews', 'Integration'];

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  //   console.log(event.currentTarget)
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  // useEffect(()=>{
  //   http.get('/integrations/user')
  //   .then((response)=>{
  //     if(response.data){
  //       setCorporateUser(response.data);
  //     }
  //   }).catch((error)=>{
  //     if(error.response.status== 401){

  //     }
  //   })
  // },[])

  const location = useLocation();
  console.log(location.pathname);

  return (
    (location.pathname.indexOf("/mettl-ats") == 0) && (
      <AppBar position='static' className="bg-mettl-blue authorize justify-content-center">
        <Toolbar px={10} className='flex-column'>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img height="26" src="/Merecer-Mettl-Reverse-Logo.svg" alt="mettl-logo" />
          </IconButton><br></br>
          <b>Industry leaders in integrated ATS and LMS</b>
        </Toolbar>

      </AppBar>
    ) ||
     (location.pathname.indexOf("/integrations/smart-recruiters/") == 0) && (
     
<AppBar position="static" style={{ background: "#002c77" }}>
      <Container maxWidth="xl">
        <Box px={5}>
          
          <Toolbar >
            <IconButton edge="start" color="#002c77" aria-label="menu">
              <img height="23px" width="130px" src={MORE_APPS_CONFIG.lmsFullPath + "/mercer-mettl_logo_colored.png"} alt="mettl-logo" />
            </IconButton>
          </Toolbar>
        </Box>
      </Container>
    </AppBar>
     ) ||

    (location.pathname.indexOf("/integrations") == 0) && (<IntegrationHeader ></IntegrationHeader>)
    ||
    (<AppBar position="static" style={{ background: "#002c77" }}>
      <Container maxWidth="xl">
        <Box px={5}>
          {/* <Toolbar >
                    <IconButton edge="start" color="inherit" aria-label="menu">
                        <img height="26" src="/mercer_mettl_logo.png" alt="mettl-logo" />
                    </IconButton>
                </Toolbar> */}
          <Toolbar >
            <IconButton edge="start" color="#002c77" aria-label="menu">
              <img height="23px" width="130px" src={MORE_APPS_CONFIG.lmsFullPath + "/mercer-mettl_logo_colored.png"} alt="mettl-logo" />
            </IconButton>
          </Toolbar>
        </Box>
      </Container>
    </AppBar>)
  )
};

export default Header;