import React, { useState, useEffect } from 'react';
import http from 'axios';
import IntegrationHeader from "./IntegrationHeader";
import IntegrationsList from './IntegrationsList';
import AssessmentList from './AssessmentList';
import Error from './shared/Error';
import MORE_APPS_CONFIG from '../moreApps';




export default function Integrations(props) {
    const [corporateUser, setCorporateUser] = useState({});
    const [rowData, setRowData] = useState(null);
    const [allAssessments, setAllAssessments] = useState([])
    const [corporateUserPresent, setCorporateUserPresent] = useState(true)
    const [showAssessmentList, setShowAssessmentList] = useState(false);
    const [loggedIn, setLoggedIn] = useState(true)
    const [status, setStatus] = useState()
    const [message,setMessage]=useState("")
    const [isValidIntegration,setIsValidIntegration]=useState(false)
    const[isNextAvailable,setIsNextAvailable]=useState(false)
    const [addData, setData] = useState("");
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" });

    const handleConfigurationButtonClick = (val) => {
        setRowData(val)
        setAllAssessments(val.assessments);
        setIsNextAvailable(val.nextAvailable)
        setShowAssessmentList(true)
    }

    const isUserLoggedIn = (val) => {
        console.log("is user logged in??", val)

        let loggedIn = val
        if (!loggedIn) {
            setStatus("401")
            setLoggedIn(val);
            setCorporateUser({})
        }
    }





    useEffect(() => {

        http.get('/integrations/user')
            .then((response) => {
                if (response.data) {
                    setCorporateUser(response.data)
                    setCorporateUserPresent(true);
                    setIsValidIntegration(response.data.validIntegrationsUser)
                    if (!response.data.validIntegrationsUser) {
                        setStatus("403")
                        setMessage("Please contact your Administrator to enable role permissions to access Integrations")
                    } 

                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    setLoggedIn(false)
                    setStatus("401")
                    setCorporateUser({})
                }
            })
    }, []);

    // useEffect(() => {
    //     console.log("new hook for cpUser==>",corporateUser);
    // },[corporateUser,corporateUserPresent])

    return ( <div>
       
            {/* {corporateUserPresent && <IntegrationHeader corporateUser={corporateUser}></IntegrationHeader>} */}
            {loggedIn && corporateUserPresent && !showAssessmentList &&isValidIntegration && <IntegrationsList corporateUser={corporateUser} handleConfigurationButtonClick={handleConfigurationButtonClick} isUserLoggedIn={isUserLoggedIn}></IntegrationsList>}
            {/* {loggedIn && corporateUserPresent && showAssessmentList && <AssessmentList corporateUser={corporateUser} allAssessments={allAssessments} isUserLoggedIn={isUserLoggedIn} isNextAvailable={isNextAvailable}></AssessmentList>} */}
            {(!loggedIn || status)  && <Error status={status} message={message} page="corporate"></Error>}
        </div>
    );


}