import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import Paragraph from '../typography/Paragraph';
import FullWidthContainerWrapper from '../wrapper/FullWidthContainerWrapper';
import { Box } from '@material-ui/core';

export default function Breadcrumb(props) {
    return (
        <FullWidthContainerWrapper sx={{ mt: 2, borderBottom: "solid 1px #E5EDF4", pb: 2 }}>

            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} display="flex" style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Box display="flex" alignItems="center">
                    <Link color="inherit" href="/" >
                        <HomeOutlinedIcon style={{ width: "16px", height: "16px" }} />

                    </Link>
                </Box>
                <Paragraph text={props.text} paragraphvariant="body2" sx={{ mb: 0 }} />
            </Breadcrumbs>

        </FullWidthContainerWrapper>
    );
}
