import React, { useState, useEffect }  from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Typography,IconButton,Box,Input} from '@material-ui/core';
import MORE_APPS_CONFIG from '../../moreApps';

import CloseIcon from '@material-ui/icons/Close';
import http from 'axios';
import axios from 'axios';

const BootstrapDialog =  makeStyles((theme) => ({
    dialogClass:{
            '& .MuiDialogContent-root': {
                padding: theme.spacing(2),
            },
            '& .MuiDialogActions-root': {
                padding: theme.spacing(1),
            },
            '& .MuiDialog-paperWidthSm':{
                minWidth:'480px',
                flexDirection:'column',
                display:'flex',
                boxShadow:'none'
                
            },
           '& .MuiBackdrop-root':{
            backgroundColor:'rgba(52, 64, 84, 0.70)'
            }
            },
            buttonClass: {
                backgroundColor: '#ffffff',
                color: '#0B41AD',
                "&:hover": {
                    backgroundColor: "#0B41AD",
                    color: "#ffffff"
                },
                border:'1px solid #0B41AD',
                borderRadius:'4px',
                fontFamily:'NotoSans-Regular',
                fontSize:'14px'
                
            },
}));

export default function AtsMandatoryFieldsDialog(props) {
  const [open, setOpen] = React.useState(props.openResponseDialog);
  const [mandatoryFields,setMandatoryFields]=React.useState({
    callbackUsername:"",
    callbackPassword:"",
    partnerClientId:""
  });
  const [validResp,setValidResp]=useState("");
  const [isValidate,setIsValidate]=React.useState(false)
  const customDialogClass = BootstrapDialog();
  

  
  




  


  const handleClose = () => {
    setOpen(false);
    props.responseDialogHandleClose()
  };

  const handleValidateClick = () => {
    
    setValidResp("")
    var basicAuth =  mandatoryFields.callbackUsername + ':' + mandatoryFields.callbackPassword;
    // const encodedToken = Buffer.from(basicAuth).toString('base64');
    http.post(`/integrations/apps/validate?appId=${props.selectedAppData.id}&apiKey=${props.selectedAppData.name.toLowerCase()==="ashby"?mandatoryFields.callbackUsername:mandatoryFields.partnerClientId}`)
    .then((response) => {
        console.log("response==>",response)
        setIsValidate(true)
        props.validateDialogHandle(mandatoryFields)
      })
       
    .catch((error) => {
      if (error.response.status == 401 ) {
        setValidResp("please enter valid key");
      }
        console.log(error);
    });
  }


  const handleOnchange=(event)=>{
    const { name, value, type, checked } = event.target;
    setMandatoryFields((prevState)=>{
        return({
            ...prevState,
            [name]:value
        })
    })
    
  }

useEffect(()=>{
    console.log("inside response==>",props.selectedAppData)
    console.log("inside response==>",mandatoryFields)
})



  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog className={customDialogClass.dialogClass}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{padding:'0px'}}>
         <Box style={{padding:'20px 32px',display:'flex',alignItems:'center',justifyContent:'space-between',alignSelf:'stretch'}}>
         <Typography style={{fontFamily:'NotoSans-Medium',fontSize:'16px',color:'#001F52'}}> Integration with {props.selectedAppData.name}</Typography>
         <IconButton style={{fontFamily:'NotoSans-Regular',color:'#001F52',padding:'0px',borderRadius:'0px'}} onClick={handleClose}><CloseIcon /></IconButton>
         </Box>
        </DialogTitle>
        
        <DialogContent style={{padding:'24px 32px',alignSelf:'stretch'}} dividers>
        {/* <Box style={{ display:'flex', alignItems:'center',justifyContent:'space-between',alignSelf:'stretch',paddingBottom:'24px'}}>
         <Typography style={{fontFamily:'NotoSans-Medium',fontSize:'16px',color:'#001F52'}}> {props.atsDialogData.name} Integration </Typography>
         <IconButton style={{fontFamily:'NotoSans-Regular',color:'#001F52',padding:'0px',borderRadius:'0px'}} onClick={handleClose}><CloseIcon /></IconButton>
         </Box>
         */}
        
          <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'14px',color:'#001F52',paddingBottom:'24px'}}>
          {props.selectedAppData.configFieldsJson.message}
          </Typography>
          
          { props.mandatoryFields.map((fields)=>(
          <Box > 
        <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'12px',color:'#4E6287',paddingBottom:'8px'}}>
         {fields.label} </Typography>
         {validResp!=="" && <Typography style={{fontFamily:'NotoSans-Regular',fontSize:'10px',color:'red',paddingBottom:'8px'}}>{validResp}</Typography>}
          <Box style={{width:'100%',height:'40px',flexDirection:'column',display:'flex'}}>
                            <Input type={fields.type} name={fields.name} disableUnderline inputProps={{style: { border: 'solid 1px', borderColor: '#BED3E4', borderRadius: '4px', padding: '10px 16px', color: '#8096B2', fontSize: '14px', fontFamily: 'NotoSans-Regular'}}}
                             value={mandatoryFields[fields.name]} onChange={(event)=>handleOnchange(event)}/>
                             </Box>                                            
         
      </Box>))}
                </DialogContent>
       
        <DialogActions style={{display:'flex',padding:'16px 32px',alignItems:'center',gap:'12px',alignSelf:'stretch',justifyContent:'flex-end'}}>
          <Button className={customDialogClass.buttonClass}style={{padding:'10px 16px',gap:'8px',alignItems:'center',display:'flex',textTransform: 'capitalize',}} autoFocus onClick={handleClose}>
            Close
          </Button>
         
          <Button  style={{padding:'10px 16px',gap:'8px',alignItems:'center',display:'flex',textTransform: 'capitalize',backgroundColor:'#0B41AD',fontFamily:'NotoSans-Regular',fontSize:'14px', border:'1px solid #0B41AD',
                borderRadius:'4px',color:'#ffffff'}} autoFocus onClick={()=>{handleValidateClick()}}>
           Validate
          </Button>
        </DialogActions>
      </Dialog>

    
    </React.Fragment>
  );
}