import { Box } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import FullWidthContainerWrapper from '../wrapper/FullWidthContainerWrapper';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({

    customButton: {
        color: '#001F52 !important',
        fontSize: "14px !important",
        borderRadius: "4px",
        border: "1px solid var(--Borders-Blue-Gray-300, #BED3E4)",
        padding: "10px 16px",

    },
    hideLabel: {
        display: 'none',
    },
});
export default function PaginationComponent(props) {
    const classes = useStyles();
    const { count, page, rowsPerPage, handleChangePage, rowsPerPageOptions } = props;
    const handleNextButtonClick = (event) => {
        handleChangePage(event, page + 1);
    };

    const handleBackButtonClick = (event) => {
        handleChangePage(event, page - 1);
    };

    return (
        <FullWidthContainerWrapper sx={{ position: "relative", bottom: 0, left: "30%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ py: 0.75 }}>
                <Typography>{`Page ${page + 1} of ${Math.ceil(count / rowsPerPage)}`}</Typography>
                <TablePagination
                    component="div"
                    count={props.count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage=""
                    nextIconButtonText="Next"
                    prevIconButtonText="Previous"
                    ActionsComponent={(props) => (
                        <Box display="flex" gridGap={"0.5rem"} paddingRight="20px">
                            <IconButton className={classes.customButton} onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                                Previous
                            </IconButton>
                            <IconButton className={classes.customButton} onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
                                Next
                            </IconButton>
                        </Box>
                    )}
                    classes={{
                        caption: classes.hideLabel,
                        select: classes.hideLabel,
                        selectIcon: classes.hideLabel,
                        input: classes.hideLabel,
                    }}
                />

            </Box>

        </FullWidthContainerWrapper >
    );
}
