import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Box, Input } from '@material-ui/core';
import MORE_APPS_CONFIG from '../../moreApps';

import CloseIcon from '@material-ui/icons/Close';
import http from 'axios';

const BootstrapDialog = makeStyles((theme) => ({
  dialogClass: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paperWidthSm': {
      minWidth: '480px',
      flexDirection: 'column',
      display: 'flex',
      boxShadow: 'none'

    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(52, 64, 84, 0.70)'
    }
  },
  buttonClass: {
    backgroundColor: '#ffffff',
    color: '#0B41AD',
    "&:hover": {
      backgroundColor: "#0B41AD",
      color: "#ffffff"
    },
    border: '1px solid #0B41AD',
    borderRadius: '4px',
    fontFamily: 'NotoSans-Regular',
    fontSize: '14px'

  },
}));

export default function AtsIntegrationKeyDialog(props) {
  const [open, setOpen] = React.useState(props.openResponseDialog);
  const customDialogClass = BootstrapDialog();
  const [isCopied, setIsCopied] = useState(false);

  const [buttonObj, setButtonObj] = useState({
    name: "",
    buttonText: "",
    url: "",
  })

  
  






  const handleClose = () => {
    setOpen(false);
    props.responseDialogHandleClose()
  };

  const handleButtonClick = (obj) => {
    if (obj.name === "GREENHOUSE") {
      window.open(obj.url, '_blank')
    }
    if (obj.name === "WORKABLE") {
      window.open(obj.url, '_blank')
    }
  };

  useEffect(() => {
    console.log("inside response==>", props.atsDialogData)
    if (props.atsDialogData.name.toLowerCase() === "greenhouse") {
      setButtonObj((prevState) => {
        return ({
          ...prevState,
          name: "GREENHOUSE",
          buttonText: "raise ticket with GreenHouse",
          url: "https://support.greenhouse.io/hc/en-us"
        })
      })
    } else if (props.atsDialogData.name.toLowerCase() === "ashby") {
      setButtonObj((prevState) => {
        return ({
          ...prevState,
          name: "ASHBY",
          buttonText: "",
          url: ""
        })
      })
    } else if (props.atsDialogData.name.toLowerCase() === "workable") {
      setButtonObj((prevState) => {
        return ({
          ...prevState,
          name: "WORKABLE",
          buttonText: "Go to Workable",
          url: "https://workable.com/backend/settings/integrations"
        })
      })
    }
  }, [])

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog className={customDialogClass.dialogClass}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{ padding: '0px' }}>
          <Box style={{ padding: '20px 32px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch' }}>
            {props.atsDialogData.name.toLowerCase() != "icims" &&
              <Typography style={{ fontFamily: 'NotoSans-Medium', fontSize: '16px', color: '#001F52' }}> Integration with {props.atsDialogData.name}</Typography>
            }
            {props.atsDialogData.name.toLowerCase() === "icims" &&
              <Typography style={{ fontFamily: 'NotoSans-Medium', fontSize: '16px', color: '#001F52' }}> Mercer| Mettl Basic Auth Credentials</Typography>
            }
            <IconButton style={{ fontFamily: 'NotoSans-Regular', color: '#001F52', padding: '0px', borderRadius: '0px' }} onClick={handleClose}><CloseIcon /></IconButton>
          </Box>
        </DialogTitle>

        {props.atsDialogData.builtBy.toLowerCase()==="mettl" &&
        <DialogContent style={{ padding: '24px 32px', alignSelf: 'stretch' }} dividers>
          {/* <Box style={{ display:'flex', alignItems:'center',justifyContent:'space-between',alignSelf:'stretch',paddingBottom:'24px'}}>
         <Typography style={{fontFamily:'NotoSans-Medium',fontSize:'16px',color:'#001F52'}}> {props.atsDialogData.name} Integration </Typography>
         <IconButton style={{fontFamily:'NotoSans-Regular',color:'#001F52',padding:'0px',borderRadius:'0px'}} onClick={handleClose}><CloseIcon /></IconButton>
         </Box>
         */}
          <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '12px', color: '#001F52', paddingBottom: '8px' }}>
            {props.atsDialogData.name.toLowerCase() === "icims" ? "Username" : "Mercer | Mettl API Key"}
          </Typography>


          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch', padding: '10px 16px', gap: '10px', border: '1px solid #D0D5DD', borderRadius: '4px' }}>
            <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#344054' }}>
              {props.atsDialogData.apiKey}
            </Typography>
            <IconButton variant="outlined" size="medium" onClick={() => { navigator.clipboard.writeText(props.atsDialogData.apiKey) }} style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular', borderRadius: '0px', padding: '0px' }}>
              <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/copy.svg'} alt="" />
            </IconButton>

          </Box>
          {props.atsDialogData.name.toLowerCase() === "icims" &&
            <Box style={{ paddingTop: "30px" }}>
              <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '12px', color: '#001F52', paddingBottom: '8px' }}>
                Password
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch', padding: '10px 16px', gap: '10px', border: '1px solid #D0D5DD', borderRadius: '4px' }}>
                <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#344054' }}>
                  {props.atsDialogData.password}
                </Typography>
                <IconButton variant="outlined" size="medium" onClick={() => { navigator.clipboard.writeText(props.atsDialogData.password) }} style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular', borderRadius: '0px', padding: '0px' }}>
                  <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/copy.svg'} alt="" />
                </IconButton>

              </Box></Box>}
        </DialogContent>}
        {props.atsDialogData.builtBy.toLowerCase()==="partner" &&
        <DialogContent style={{ padding: '24px 32px', alignSelf: 'stretch' }} dividers>
          <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '12px', color: '#001F52', paddingBottom: '8px' }}>
            Mercer | Mettl Public API Key
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch', padding: '10px 16px', gap: '10px', border: '1px solid #D0D5DD', borderRadius: '4px' }}>
            <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#344054' }}>
              {props.atsDialogData.publicKey}
            </Typography>
            <IconButton variant="outlined" size="medium" onClick={() => { navigator.clipboard.writeText(props.atsDialogData.publicKey) }} style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular', borderRadius: '0px', padding: '0px' }}>
              <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/copy.svg'} alt="" />
            </IconButton>
          </Box>
          <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '12px', color: '#001F52', paddingBottom: '8px', paddingTop:'30px' }}>
            Mercer | Mettl Private API Key
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch', padding: '10px 16px', gap: '10px', border: '1px solid #D0D5DD', borderRadius: '4px' }}>
            <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#344054' }}>
              {props.atsDialogData.privateKey}
            </Typography>
            <IconButton variant="outlined" size="medium" onClick={() => { navigator.clipboard.writeText(props.atsDialogData.privateKey) }} style={{ fontSize: '14px', fontFamily: 'NotoSans-Regular', borderRadius: '0px', padding: '0px' }}>
              <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/copy.svg'} alt="" />
            </IconButton>
          </Box>
        </DialogContent>}
        <DialogActions style={{ display: 'flex', padding: '16px 32px', alignItems: 'center', gap: '12px', alignSelf: 'stretch', justifyContent: 'flex-end' }}>
          <Button className={customDialogClass.buttonClass} style={{ padding: '10px 16px', gap: '8px', alignItems: 'center', display: 'flex', textTransform: 'capitalize', }} autoFocus onClick={handleClose}>
            Close
          </Button>
          {buttonObj.buttonText != "" &&
            <Button style={{
              padding: '10px 16px', gap: '8px', alignItems: 'center', display: 'flex', textTransform: 'capitalize', backgroundColor: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', border: '1px solid #0B41AD',
              borderRadius: '4px', color: '#ffffff'
            }} autoFocus onClick={() => { handleButtonClick(buttonObj) }}>
              {buttonObj.buttonText}
            </Button>}

        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}