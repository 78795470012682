import { createTheme } from '@material-ui/core/styles';
const mettlDarkBlue = "#002C77";
const mettlDarkerBlue = "#001F52";
const mettlSlightDarkBlue = "#0B41AD";
const mettlGreen = "#14853D";
const mettlRed = "#C53532";
const mettlYellow = "#FFECB3";
const mettlDarkGray = "#4E6287";
const mettlLightGray = "#8096B2";
const mettlBlack = "#000000";
const mettlWhite = "#ffffff";
const mettlTurquoise = "#00968f";
const mettlTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1390,
      xl: 1536,
    },
  },
  spacing: 8,
  palette: {
    common: {
      darkblue: mettlDarkBlue,
      darkerblue: mettlDarkerBlue,
      slightdarkblue: mettlSlightDarkBlue,
      green: mettlGreen,
      red: mettlRed,
      yellow: mettlYellow,
      darkgray: mettlDarkGray,
      lightgray: mettlLightGray,
      black: mettlBlack,
      white: mettlWhite,
      // gray1: mettlGray1,
      // gray2: mettlGray2,
      // gray3: mettlGray3,
      // gray4: mettlGray4,
      // pink: mettlPink,
      // teal: mettlTeal,
      // purple: mettlPurple,
      turquoise: mettlTurquoise,
      // lightpurple: mettlLightPurple,
      // lightteal: mettlLightTeal,
      // lightgreen: mettlLightGreen,
    },
    primary: {
      main: mettlSlightDarkBlue,
      dark: mettlDarkBlue,
    },
    // secondary: {
    //   main: mettlGreen
    // },
    error: {
      light: '#e57373',
      main: mettlRed,
      dark: '#d32f2f',
    },
    info: {
      light: "",
      main: mettlYellow,
    },
    warning: {
      light: "",
      main: "#FFBE00",
    },
    success: {
      light: "#FFBE00",
      main: mettlGreen,
      dark: "#FFBE00"
    },
    text: {
      primary: mettlDarkGray,
      secondary: mettlLightGray,
      disabled: "",
    },
    background: {
      lightblue: '#F8FAFC'
    }
  },
  h5: {
    fontWeight: 300
  },
  h6: {
    fontWeight: 300
  },
  typography: {
    htmlFontSize: 16,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "NotoSans-Regular",
    subtitle1: {
      fontFamily: "NotoSans-SemiBold",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0em",
      textAlign: "left"
    },
    subtitle2: {
      fontFamily: "NotoSans-Medium",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "left"
    },
    body1: {
      fontFamily: "NotoSans-Medium",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0em",
      textAlign: "left",
      color: mettlDarkGray
    },
    body2: {
      fontFamily: "NotoSans-Regular",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
      textAlign: "left",
      color: mettlDarkerBlue
    },
    caption: {
      fontFamily: "NotoSans-Medium",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "12px",
      letterSpacing: "0em",
      textAlign: "center"
    },
    button: {
      fontFamily: "NotoSans-Medium",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0em",
      textAlign: "left",
      textTransform: "capitalize",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiInputLabel: {
      // Name of the rule
      root: {
        // Some CSS
        fontSize: '0.75rem',
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          background: '#f5fcfd',
          color: '#0180b2',
        },
      }
    }
  },
});

export default mettlTheme;