import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import {
    Box, Button, FormControl, InputLabel, Select, Container, MenuItem, Row,Col, Backdrop, CircularProgress
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { useAbsoluteLayout } from 'react-table';


export default function Stages(props){

       const [stage, setStage] = useState(null);
        const location = useLocation();
        const [stages, setStages] = useState([]);
        const [stageId, setStageId] = useState(null);
        const [stageEditEnabled,setStageEditEnabled] = useState(true);
        const [showCancelEdit,setShowCancelEdit] = useState(false);
        const [showAlert,setShowAlert] = useState(false);
        const [alertSeverity,setAlertSeverity] = useState("");
        const [alertMessage,setAlertMessage] = useState("");
        const [alertStyle,setAlertStyle] = useState({});
        const [showLoader,setShowLoader] = useState(false);
        console.log("location pathname",location.pathname);  
        let _stages = [];      
        let _stageId = null;
        useEffect(()=>{
            getLeverStages();
            // getClientSavedStage();
        },[]);

        const onStageChange = (event)=>{
            console.log("stage:",event.target.value);
            setStage(event.target.value)
        }

        const getLeverStages = () =>{
            console.log("inside getLeverStages");
            setShowLoader(true);
            axios.get("/lever/getLeverstages").then((response)=>{
                let data = response.data;
                setShowLoader(false);
                console.log(data);
                if(data.status){
                    let stages = data.data.data;
                    console.log("stages :",stages);
                    setStages(stages);
                    _stages=stages;
                    filterandSetStage();
                }else{  
                    // alert(data.message);
                }
            }).catch(()=>{
                setShowLoader(false);
            });
        };

        const getClientSavedStage = ()=>{
            console.log("inside getClientSavedStage");
            axios.get("/lever/getClientSavedStage").then((response)=>{
                let data = response.data;
                console.log(data);
                if(data.status){
                    let stId = data.client.atsStageTrigger;
                    console.log("stages :",stId);
                    setStageEditEnabled(false);
                    setStageId(stId);
                    _stageId = stId;
                    filterandSetStage();
                }else{  
                    // alert(data.message);
                }
            });
        };
        
        const saveStage = (event)=>{
            console.log(event,"stage",stage);
            setShowLoader(true);
            axios.get(`/lever/saveLeverTriggerStage?stage=${stage}`)
            .then((response)=>{
                let data = response.data;
                console.log(data);
                setShowLoader(false);
                if(data.status){
                    setStageEditEnabled(false);  
                    setShowCancelEdit(false);
                    setShowAlert(true);
                    setAlertSeverity("success");
                    setAlertMessage(data.message);
                    setAlertStyle({color:"white", backgroundColor:"#15853d"});
                    setTimeout(()=>{
                        setShowAlert(false);    
                    },5000);
                }else{
                    setStageEditEnabled(true);   
                    setShowAlert(true);
                    setAlertSeverity("error");
                    setAlertMessage(data.message);
                    setAlertStyle({color:"white", backgroundColor:"#b92424"})
                    setTimeout(()=>{
                        setShowAlert(false);    
                    },5000);
                }
            }).catch(()=>{
                setShowLoader(false);
            });
        }

        let filterandSetStage = ()=>{
            if(_stageId && _stages && _stages.length && _stages.length > 0){
                let stage = _stages.find(stg=>stg.id === _stageId);
                if(stage){
                    setStage(stage);
                }
            }
        }

        const enableStageEdit = ()=>{
            setStageEditEnabled(true);
            setShowCancelEdit(true);
        }

        const cancelStageEdit = ()=>{
            setStageEditEnabled(false);
            setShowCancelEdit(false);
        };

        return (
        <Container className="authorize-box">
            <Box component="div" sx={{
                    width: 350,
                    height: 'auto',
                    minHeight:100,
                    marginTop: -75,
                    borderBottom: '4px solid #002C77',
                    boxShadow: '0px 0px 5px grey',
                    borderRadius: 5,
                    backgroundColor: 'white',
                    textAlign : 'center'
                }
            }>    
            <Box px={5} py={2} >
                    <Box component="span" sx={{color:'#002C77', fontWeight:800}}>Credentials Authorised</Box>
                    <br></br>
                    <small>Please select a Lever Trigger from options below to proceed</small>
                    <br></br>
                    <Button variant="contained" className="authorize-button bg-mettl-blue" mx={2} disabled>Authorised</Button>
                

            </Box>
            <Box  px={-5} sx={{
                    borderTop: '1px solid grey',
                    textAlign : 'left',
                    padding: '0px 30px;'
                    }
                }>
                    <Box sx={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
                            <h5>  Lever Trigger Stage</h5>
                        {( !stageEditEnabled && 
                        <Box py={3} onClick={enableStageEdit} sx={{display:"flex",alignItems:"center"}}>
                        <EditOutlinedIcon></EditOutlinedIcon>
                            <Box> Edit</Box>
                        </Box>
                        )}
                        {( showCancelEdit && 
                        <Box py={3} onClick={cancelStageEdit} sx={{display:"flex",alignItems:"center"}}>
                        <CloseIcon></CloseIcon>
                        <Box> Cancel</Box>
                        </Box>
                        )}
                    </Box>
                    
                <FormControl fullWidth>
                <InputLabel id="stage-select-label">Select Stage</InputLabel>
                <Select
                    labelId="stage-select-label"
                    id="stage-select"
                    value={stage}
                    label="Stage"
                    onChange={onStageChange}
                    disabled ={(!stageEditEnabled)}
                >
                    {
                    stages.map((stage)=>{
                       
                       return( 
                       <MenuItem value={stage.id}>{stage.text}</MenuItem>
                       )
                    })
                   
                    }
                </Select>
                </FormControl>
                <div className="d-grid gap-2">
                <Box sx={{
                        textAlign : 'center',
                        padding: '10px 0px'
                    }}>
                    {( stageEditEnabled &&    
                    <Button variant="primary" size ="sm" className="authorize-button bg-mettl-blue btnwdth" mx={2} onClick={saveStage} >Save Selection</Button>
                    )}
                </Box>
                </div>
                </Box>
            </Box>

            {(
                showAlert &&
                <Box sx={{position:"absolute",bottom:0}}>
                <Alert className={`customAlert-`+alertSeverity} severity={alertSeverity} sx={alertStyle}>{alertMessage}</Alert>
                </Box>
            )}
            {(
                showLoader &&
                <Box sx={{position:"absolute", zIndex : 1000}}>
                    <Backdrop open={showLoader} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            )}
        </Container>
        
        )
}