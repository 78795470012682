import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { ThemeProvider } from '@material-ui/core/styles';
import mettlOriginalTheme from './custom-theme/mettl-original-theme';

ReactDOM.render(
  <ThemeProvider theme={mettlOriginalTheme}><App /></ThemeProvider>,
  document.getElementById('root')
);
