import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Typography,Button,InputBase } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import FilterDropdown from './FilterDropdown';
import { useState, useEffect } from 'react';
import MORE_APPS_CONFIG from '../../../moreApps';
import Toast from '../../../ui-components/snackbar/Snackbar';
import http from 'axios';



export default function DeletionLog(props) {

    const [searchValue, setSearchValue] = useState('');
    const[groupDataByDate,setGroupDataByDate]=useState({});
    const [offset, setOffset] = React.useState(0);
    const limit = 10;
    const [loading, setLoading] = React.useState(false);
    const [deletedData,setDeletedData]=useState([]);
    const [isOffset,setIsOffset]=useState(props.isOffset);
    const [deletionLogEnd,setDeletionLogEnd]=useState(false);
    const [hasMoreDeleteLogData, setHasMoreDeleteLogData] = React.useState(true);
    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
       
    };

    // useEffect(()=>{
    //     props.resetLoadMore(true,false)
    //     setOffset(0);
    //     fetchDeletionLogData(0);  
    // },[])

    useEffect(() => {
        props.resetLoadMore(false)
        setIsOffset(false)
        setOffset(0);   
        fetchDeletionLogData(0)   
    }, [searchValue]);

    useEffect(() => {
        if(props.isOffset){
            let addOffset=offset+Number(limit)
            setOffset(addOffset);
            fetchDeletionLogData(addOffset)
        }
    }, [props.isOffset]);



    let fetchDeletionLogData=(newOffset)=>{

        // let newOffset=0;
        // if(offset!=0){
        //     newOffset=offset+limit
        // }
        // if (loading || !hasMore) return;
        setHasMoreDeleteLogData(true)
        http.get(`/integrations/ripplehire/getDeletedJobs?limit=${limit}&offSet=${newOffset}&jobFilter=${searchValue}`)
        .then((response) => {
            if (response.data) {
                const groupedData={};
                if (response.data.length > 0) {
                    props.resetLoadMore(true)
                    
                    let newData=[];
                    if(newOffset===0){
                        setDeletedData(response.data);
                        newData.push(response.data)
                    }else{
                        let newData1=response.data
                        newData.push(newData1.concat(deletedData))
                           
                    }
                  
                  
                   console.log("new data added",newData)
                    //setDeletedData((prevData) => [...prevData, response.data]);
                    console.log()
                    newData[0].sort((a, b) => new Date(b['createdOn']) - new Date(a['createdOn']));
                   newData[0].forEach((item) => {
                    const date = new Date(item.createdOn).toDateString();
                    // console.log("date ", date)
                    if (!groupedData[date]) {
                        groupedData[date] = [];
                    }
        
                    groupedData[date].push(item);

                });
        
                setGroupDataByDate(groupedData)
                console.log("group data",groupedData)
            }else{
                props.resetLoadMore(false)
                setHasMoreDeleteLogData(false)
                if(groupDataByDate=={}){
                    setGroupDataByDate(groupedData)
                    setOffset(0);
                    
                }
                if(searchValue!="" && newOffset===0 ){
                    setGroupDataByDate(groupedData)
                    setOffset(0);
                    
                }
                // let hasMore=false
                //     let loading=false
                // props.resetLoadMore(hasMore,loading)
            }
        }
        }).catch((error) => {
            console.error('Error fetching data:', error);
        })
        
    }

    const handleRefresh=()=>{
       // props.resetLoadMore(true,true)
        setOffset(0)
        fetchDeletionLogData(0)
    }


    
    return (
        <React.Fragment>
           
                    <Box style={{ marginTop: '75px', display: 'flex',width: "540px",justifyContent:'space-between' }}>
                                <Box width={300} display="flex" sx={{ border: "1px solid #BED3E4", borderRadius: "4px", padding: "10px 16px" }} justifyContent="space-between" alignItems="center">
                                <Box>
                                <InputBase
                                                placeholder="Search by Job Id/Name"
                                                inputProps={{ 'aria-label': 'search',style:{padding:'0px'} }}
                                                styles={{
                                                    fontFamily: "NotoSans-Regular", fontSize: "14px", color: "#8096B2",padding:'0px',
                                                    '.MuiInputBase-root': {
                                                        width: "250px !important"
                                                        

                                                    }
                                                }}
                                                value={searchValue}
                                                onChange={handleSearchInputChange}
                                            />
                                            </Box>
                                            <Box>
                                                <SearchIcon style={{ color: "#8096B2", width: "16px", height: "16px" }} />
                                            </Box>
                                </Box>
                                <Box >
                                <IconButton onClick={handleRefresh} style={{border:'1px solid #BED3E4',borderRadius:'4px'}} >
                                <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/refresh.svg`} alt="Refresh Description" style={{ width: '16px', height: '16px' }} />
                    </IconButton>
                                </Box>
                    </Box>
                    <Box width={536} >
                        
                    {Object.entries(groupDataByDate).map(([date,deletedData], index, array) => (
                            <React.Fragment key={date}>

                                <p style={{ fontSize: "12px", fontFamily: "NotoSans-Regular", fontWeight: 600 }}>{date}</p>

                                {deletedData.map((deletedData, index) => {

                                    
                                    let currentTimestamp = deletedData.createdOn;
                                    let date = new Date(currentTimestamp);
                                    let hours = date.getHours();
                                    let minutes = date.getMinutes().toString().padStart(2, '0');
                                    

                                    return (
                                        <Box key={index} marginTop={1} style={{ border: "1px solid var(--background-blue-gray-100, #E5EDF4)", borderRadius: "4px", boxShadow: "gb(50 57 64 / 14%) 0px 1px 5px 0px" }}>
                                            <Box padding={2} display="flex" flexDirection="column">
                                                <Box display="flex">
                                                <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/jobDeleted.svg`} alt="Image Description" style={{ width: 16, height: 16, marginTop: 2 }} />
                                                    <Box marginLeft={1}>
                                                        <p style={{ color: "#8096B2", fontFamily: "NotoSans-Regular", fontSize: 12, marginTop: 0, marginBottom: 4 }}>{deletedData.rhJobId}</p>
                                                        <p style={{ color: "#4E6287", fontFamily: "NotoSans-Regular", fontSize: 14, marginTop: 0, marginBottom: 0 }}>{deletedData.rhJobName}</p>
                                                        
                                                       

                                                    </Box>
                                                </Box>
                                                <Box display="flex" paddingLeft="16px" paddingTop="12px">
                                                    {/* <p style={{ color: "#8096B2", fontSize: 12, fontFamily: "NotoSans-Regular", marginTop: 0, marginBottom: 0, display: "flex" }}><ScheduleIcon style={{ width: 15, height: 15, marginTop: "1px", color: "#8096B2" }} />&nbsp;{`${hours}:${minutes}`}</p> */}
                                                    <p style={{ color: "#8096B2", fontSize: 12, fontFamily: "NotoSans-Regular", marginTop: 0, marginBottom: 0, display: "flex" }}><ScheduleIcon style={{ width: 15, height: 15, marginTop: "1px", color: "#8096B2" }} />&nbsp;{`${hours}:${minutes}`}</p>
                                                    <p style={{ color: "#8096B2", fontSize: 12, fontFamily: "NotoSans-Regular", marginTop: 0, marginBottom: 0, display: "flex", paddingLeft: "12px"}}><img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/import.svg`} alt="Image Description" style={{ width: '14px', height: '16px' }} />&nbsp;{deletedData.rhJobSource}</p>
                                                    <p style={{ color: "#8096B2", fontSize: 12, fontFamily: "NotoSans-Regular", marginTop: 0, marginBottom: 0, display: "flex", paddingLeft: "12px" }}><PersonOutlineIcon style={{ width: 15, height: 15, marginTop: "1px", color: "#8096B2" }} />&nbsp;{deletedData.clientName}</p>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })}

                                {index !== array.length - 1 && <Divider style={{ backgroundColor: "#BED3E4", marginLeft: '15px', width: '1px', marginTop: "30px", width: "200px" }} />}
                            </React.Fragment>
                        ))}
                    {!hasMoreDeleteLogData && <p style={{ color: "#4e6287", fontFamily: "NotoSans-Regular", fontSize: 14, marginBottom: 4, marginTop: "10px", textAlign: "center" }}>no more data.</p>}
                    </Box>
                    
        </React.Fragment>)
}