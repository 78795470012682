import React, { Component } from 'react';
import {
Box, Card, CardHeader, CardContent, Button, Typography,Input, Grid, Icon, Divider, Switch, 
Select, Checkbox, Hidden,IconButton,Tooltip
} from '@material-ui/core';
import {  withStyles } from "@material-ui/core/styles";

import { grey } from '@material-ui/core/colors';
import DialogComponent from './shared/dialogComponent';
import ScheduleSettingUI from './scheduleSettingUI';
import CustomizedSnackbar from './shared/CustomizedSnackbar'


class AddedAssessment extends Component {

constructor(props) {
super(props);

this.state = {
showRemoveAssessmentModal: false,
showScheduleSettingModal: false,
assessmentSetting: this.props.assessmentSetting,
days:0,
hours:0,
snackbarProps: { open: false, message: "", variant: "" }
}
}

componentDidMount = () => {

if(this.props.assessmentSetting.cooldownPeriod!=0){
let cooldownP=Number(this.props.assessmentSetting.cooldownPeriod);
if(cooldownP % 24 ===0){
this.setState({
    days: Math.trunc(Number(cooldownP/24)),
    hours:0
});
}else{
this.setState({
    days:Math.trunc(Number(cooldownP/24)),
    hours:Number(cooldownP % 24)
});
}
}
}
openRemoveAssessmentModal = (isOpen) => {
this.setState({
showRemoveAssessmentModal: isOpen
})
}

openScheduleSettingModal = (isOpen) => {
this.setState({
showScheduleSettingModal: isOpen
})
}

closeRemoveAssessmentModal = (isSaved) => {
this.openRemoveAssessmentModal(false);

if (isSaved) {
this.props.handleCancelAssessment(this.props.assessment.id);
}
};

closeScheduleSetting = (assessment, updatedAssessmentSettings,days,hours) => {
this.openScheduleSettingModal(false);
if (updatedAssessmentSettings) {
this.setState({
assessmentSetting: updatedAssessmentSettings,
days:days,
hours:hours,
snackbarProps: { open: true, message: "Settings Updated Sucessfully", variant: "success" }
});
}
}

handleCloseSnackbar = () => {
this.setState({
snackbarProps: { open: false, message: "", variant: "" }
})
}



render() {

let settings = this.state.assessmentSetting;

return (
<Box mt={6}>
<Card>
    <CardHeader disableTypography title={
        <Box display="flex" alignItems="center">
            <Typography variant="h5" color="textPrimary">
                Added Assessment
            </Typography>
        </Box>
    }>
    </CardHeader>
    <CardContent style={{ padding: 0 }} className="assessment-list">

        <Box mb={2} className="bg-light-gray">
            <Divider></Divider>
            <Box display="flex" fontSize="small" alignItems="center">
                <Box p={2}>
                    Assessment Name
                </Box>
                <Box p={2} ml="auto">
                    Actions
                </Box>
            </Box>
            <Divider></Divider>
        </Box>

        <Box mb={2}>
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" py={2} px={2} fontSize={13}>
                        <Icon>insert_drive_file</Icon>
                        <Typography style={{ paddingLeft: 5 }}>{this.props.assessment.name}</Typography>
                        <Box ml="auto">
                            <Button onClick={this.openScheduleSettingModal.bind(this, true)}
                                variant="outlined" style={{ minWidth: "auto", padding: "5px 8px" }}>
                                <Icon>edit</Icon>
                            </Button>
                            <Button onClick={this.openRemoveAssessmentModal.bind(this, true)}
                                variant="outlined" style={{ minWidth: "auto", padding: "5px 8px", marginLeft: "10px" }}>
                                <Icon>delete</Icon>
                            </Button>
                        </Box>
                    </Box>

                    <Box alignItems="center" border={1} borderColor={grey[300]} bgcolor={grey[100]} py={2} px={3} mx={2}>
                        <Grid container spacing={1}>
                        <Grid item xs={6} sm={4}>
                                <Box display="flex" alignItems="center" pt={1}>
                                    <Icon>web</Icon>
                                    <Typography style={{ paddingLeft: 5 }}>Mettl Secure Browser</Typography>
                                    <MsbTooltip  placement="top" interactive title={
                <React.Fragment>
                    <Typography style={{fontSize:"12px"}}>This is a custom browser which turns candidate's system into a lock-down mode when the 
                        assessment is running. It enables multiple security features which prevents candidates to access unauthorized resources on his/her machine</Typography>
                        <Typography style={{textAlign:"center",color:"#0af3f7",fontSize:"12px",textDecoration:'none'}}>{<a style={{color:'#f44336'}} target="_blank" href='https://support.mettl.com/portal/en/kb/articles/mettl-secure-browser-msb' >Know More</a>}</Typography>
                </React.Fragment>}
                arrow>
            <IconButton aria-label="info" size="small" color="disabled">
                                <Icon fontSize="inherit">info</Icon>
                                </IconButton>
            </MsbTooltip>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                                <Box display="flex" alignItems="center">
                                    <Switch
                                        disabled
                                        checked={settings.secureBrowser.enabled}
                                        name="secureBrowser"
                                        edge="start"
                                        color="primary"
                                        inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Box display="flex" alignItems="center" pt={0.5}>
                                    <Icon>credit_card</Icon>
                                    <Typography style={{ paddingLeft: 5 }}>Browsing Tolerance</Typography>
                                    <MsbTooltip interactive placement='top' sx={{style:{color:"black"}}}  
                                title={<Typography style={{fontSize:"12px"}}>Not recommended for Upload Type questions in test
                                </Typography>} arrow>
                                    <IconButton aria-label="info" size="small" color="disabled">
                                    <Icon fontSize="inherit">info</Icon>
                                    </IconButton>
                                    
                    </MsbTooltip>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                                <Select
                                    disabled
                                    native
                                    displayEmpty={true}
                                    value={settings.apiWebProctoring.enabled ? settings.apiWebProctoring.count : ""}
                                    name="browsingToleranceCount"
                                >
                                    <option value="">Not Applicable</option>
                                    <option value={0}>No Warning</option>
                                    <option value={1}>Very low (1 warning)</option>
                                    <option value={2}>Low (2 warnings)</option>
                                    <option value={4}>Medium (4 warnings)</option>
                                    <option value={10}>High (10 warnings)</option>
                                    <option value={settings.apiWebProctoring.count}>{settings.apiWebProctoring.count} Warnings</option>
                                </Select>

                                {(settings.apiWebProctoring.count > 0) &&
                                    <React.Fragment>
                                        <Checkbox
                                            disabled
                                            size="small"
                                            checked={settings.apiWebProctoring.showRemainingCounts}
                                            name="remainingCountEnabled"
                                            color="primary"
                                            style={{ padding: 0 }}
                                            inputProps={{
                                                'aria-label': 'remaining warnings checkbox',
                                            }}
                                        />
                                        <Box ml={1} component="span" fontSize={11}>Show remaining</Box>
                                    </React.Fragment>
                                }

                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <Box display="flex" alignItems="center" pt={1}>
                                    <Icon>videocam</Icon>
                                    <Typography style={{ paddingLeft: 5 }}>Advanced Visual Proctoring</Typography>
                                    <MsbTooltip interactive placement='top'
                                title={<Typography style={{fontSize:"12px"}}>
                                    You can watch candidate's live while taking the test or view 
                                 recorded snapshots in the candidate's report on demand. Mettl marks instances of candidate's suspicious activities using it's Artificial Intelligence algorithm
                                 </Typography>} arrow>
                                    <span>
                                    <IconButton aria-label="info" size="small" color="disabled" >
                                    <Icon fontSize="inherit">info</Icon>
                                    </IconButton>
                                    </span>
                                </MsbTooltip>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                                <Box display="flex" alignItems="center">
                                    <Switch
                                        disabled
                                        checked={settings.proctoring.mode === "OFF" ? false : true}
                                        name="visualProctring"
                                        edge="start"
                                        color="primary"
                                        inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                                    />
                                    <Typography>{settings.proctoring.mode === "OFF" ? "Disabled" : "Enabled"}</Typography>

                                    {settings.proctoring.mode !== "OFF" &&
                                        <Hidden only="xs">
                                            <Box display="flex" alignItems="center" ml={1} fontSize={11}>
                                            
                                                <span>Runs on :</span>
                                                <span>
                                                <MsbTooltip placement="top" interactive title={
                                                    <React.Fragment>
                                                        <Typography style={{fontSize:"12px"}}>We recommend candidates take the test in latest versions of Firefox, Chrome and Safari to provide a seamless experience. Candidates using older
                                                         versions of Firefox and Internet Explorer would be required to download a java applet.</Typography>
                                                         <Typography style={{textAlign:"center",color:"#0af3f7",fontSize:"12px",textDecoration:'none'}}>{<a style={{color:'#f44336'}} target="_blank" href='https://support.mettl.com/portal/en/kb/articles/system-requirement-for-mettl-assessment-platform' >Know More</a>}</Typography>
                                                    </React.Fragment>}
                                                arrow>
                                                <Button style={{fontSize:"11px"}}>
                                                <span className="icon-browser icon-chrome"></span>
                                                <span className="icon-browser icon-firefox"></span>
                                                <span className="icon-browser icon-ie"></span>
                                                <span className="icon-browser icon-safari"></span>
                                                </Button>
                                                </MsbTooltip>
                                                </span>
                                            </Box>
                                        </Hidden>
                                    }

                                </Box>
                                {settings.proctoring.mode !== "OFF" &&
        <React.Fragment>
        <Grid container>
            <Grid item xs={9} md={12}>
                <Box display="flex" alignItems="center" >
                    <Checkbox  disabled size="small" name="candidateScreenCapture"  
                    color="primary" checked={settings.proctoring.options.candidateScreenCapture} onChange={this.handleChange}  style={{padding: "1px",width: "10px", height: "10px"}}/>
                    <Typography style={{ fontSize:"10px",paddingLeft:"2px"}}>Capture Candidate Screen </Typography>
                    <MsbTooltip interactive  title={<Typography style={{fontSize:"12px"}}>
                        Best viewed in latest versions of Firefox and Chrome. The test will not run 
                            on mobile devices and safari browser if the capture candidate's screen option is selected.
                            </Typography>} arrow>
                                <IconButton aria-label="info" size="small" color="disabled">
                                <Icon fontSize="inherit">info</Icon>
                                </IconButton>
                                
                            </MsbTooltip>
                </Box>
            </Grid>
            <Grid item xs={12} pt={1}>
                <Box display="flex" alignItems="center"  >
                    <Checkbox  disabled size="small" name="candidateAuthorization" 
                    color="primary" checked={settings.proctoring.options.candidateAuthorization} onChange={this.handleChange} style={{padding: "1px",width: "10px", height: "10px"}}/>
                    <Typography style={{ fontSize:"10px",paddingLeft:"2px"}}>Candidate Authorization </Typography>
                    <IconButton aria-label="info" size="small" color="disabled">
                    <Icon fontSize="inherit">person</Icon>
                    </IconButton>
                    
                <Typography style={{ fontSize:"10px",color:"grey"}}>Requires Proctor Surveillance</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} style={{paddingTop:"2px"}}>
                <Box display="flex" alignItems="center"  >
                    <Checkbox  disabled size="small" name="check360" 
                    color="primary" checked={settings.proctoring.options.check360} onChange={this.handleChange} style={{padding: "1px",width: "10px", height: "10px"}}/>
                    <Typography style={{ fontSize:"10px",paddingLeft:"2px"}}>Mandatory 360 Check </Typography>
                     </Box>
            </Grid>
            <Grid item xs={12} style={{paddingTop:"2px"}}>
                <Box display="flex" alignItems="center"  >
                    <Checkbox  disabled size="small" name="mobileProctoring" 
                    color="primary" checked={settings.proctoring.options.mobileProctoring} onChange={this.handleChange} style={{padding: "1px",width: "10px", height: "10px"}}/>
                    <Typography style={{ fontSize:"10px",paddingLeft:"2px"}}>Mobile Camera Proctoring </Typography>
                    <MsbTooltip interactive  title={<Typography style={{fontSize:"12px"}}>
                        Candidate will be required to setup a mobile camera,
                        Audio/Video from mobile would be recorded on demand
                        and will be available in reports.
                            </Typography>} arrow>
                                <IconButton aria-label="info" size="small" color="disabled">
                                <Icon fontSize="inherit">info</Icon>
                                </IconButton>
                                
                            </MsbTooltip>
                   </Box>
            </Grid>
            {/* <Grid item xs={6} pt={1}>
                <Box display="flex" alignItems="center"  ml={1}>
                <Icon sx={{size:"10px"}}>person</Icon>
                <Typography style={{ fontSize:"8px"}}>Requires Proctor Surveillance</Typography>
                </Box>
            </Grid> */}
        </Grid>
        </React.Fragment> 
}
                            </Grid>
                            
                            <Grid item xs={6} sm={4}>
                                <Box display="flex" alignItems="center" pt={0.5}>
                                <Icon>autorenew</Icon>
                                    <Typography style={{ paddingLeft: 5 }}>Attempt(s) Allowed</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display="flex" alignItems="center" sx={{width:40}}>
                                    <Input disabled type="number" min="0" name= "maxReattemptAllowed" inputProps={{ style:{textAlign: 'center',paddingBottom:"3px",width:'35px'}}}
                                    value={settings.maxReattemptAllowed}  />
                                </Box>

                                    { settings.maxReattemptAllowed >1 &&
                                    <React.Fragment>
                                    <Grid container spacing={1}>
                            <Grid item xs={3.5} sm={3.5}>
                                <Box display="flex" alignItems="center"  pt={0.5}>
                                <Icon fontSize="small">percent</Icon>
                                <Typography style={{ paddingLeft: 5,fontSize:"10px"}}>Pass Percentage </Typography>
                                <MsbTooltip interactive placement='top' sx={{style:{color:"black"}}}  
                                title={<Typography style={{fontSize:"12px"}}>Entering a non-zero pass percentage means that if the candidate fails, they are allowed to attempt the assessment again until they exhaust the available attempts. No further attempts are allowed if the candidate passes.
                                <br></br>To allow multiple attempts regardless of the pass percentage, please enter a value of zero.</Typography>} arrow>
                                    <IconButton aria-label="info" size="small" color="disabled" style={{fontSize:"12px"}}>
                                    <Icon fontSize="inherit">info</Icon>
                                    </IconButton>
                                    
                                </MsbTooltip>
                                </Box>
                            </Grid> 
                            <Grid item xs={6}>
                                <Box display="flex" alignItems="center"  sx={{fontSize:"10px",width:40}}>
                                    <Input disabled  inputProps={{ style: {fontSize:"10px", textAlign: 'center',paddingBottom:"6px",fontWeight:410 }}} type="number" min="0"  name= "passPercentage" 
                                    value={settings.passPercentage}  />
                                </Box>
                            </Grid> 
                            </Grid>
                            <Grid container  spacing={1}>
                            <Grid item xs={3.5} sm={3.5}>
                                <Box display="flex" alignItems="center" pt={0.5}>
                                <Icon fontSize="small">access_time</Icon>
                                <Typography style={{ paddingLeft: 5,fontSize:"10px" }}>Cooldown Period</Typography>
                                </Box>
                            </Grid> 
                            {/*  { settings.maxReattemptAllowed >1 &&
                            <Grid item xs={6}>
                                <Box display="flex" alignItems="center">
                                    <Input type="number" min="0" name= "cooldownPeriod" 
                                    value={settings.cooldownPeriod}  />
                                </Box>
                            </Grid> } */}
                            
                                <Grid item xs={2}sm={2}>
                                    <Box display="flex" alignItems="center" sx={{ width:70,paddingLeft:"15px" }}>
                                        <Input disabled type="number"  name= "days" inputProps={{ style: {fontSize:"10px", textAlign: 'center',paddingBottom:"6px",fontWeight:410 }}}
                                        value={this.state.days} onChange={this.handleChange} />
                                        <Typography style={{fontSize:"10px"}}>Day(s)</Typography>
                                    </Box>
                                </Grid>
                                
                                <Grid item xs={3} sm={3}>
                                    <Box display="flex" alignItems="center" sx={{ width:70,paddingLeft:"15px" }}>
                                        <Input disabled type="number"  name= "hours" inputProps={{ style: {fontSize:"10px", textAlign: 'center',paddingBottom:"6px",fontWeight:410 }}}
                                        value={this.state.hours} onChange={this.handleChange} />
                                        <Typography style={{fontSize:"10px"}}>Hour(s)</Typography>
                                    </Box>
                                </Grid>
                                </Grid>
                                </React.Fragment>}
                            
                            </Grid>
                        
                            </Grid>
                    </Box>

                </Grid>
            </Grid>
        </Box>

    </CardContent>
</Card>

{this.state.showRemoveAssessmentModal &&
    <DialogComponent
        dialogTitle="Remove Assessment"
        dialogContent="Are you sure you would like to remove this assessment from the activity ?"
        dialogButtonText="Remove"
        dialogButtonColor="secondary"
        open={this.state.showRemoveAssessmentModal} onClose={this.closeRemoveAssessmentModal}
    ></DialogComponent>}

{this.state.showScheduleSettingModal &&
    <ScheduleSettingUI isScheduled={Boolean(this.props.assessment)} assessment={this.props.assessment} assessmentSetting={settings}
        isOpen={this.state.showScheduleSettingModal} onClose={this.closeScheduleSetting} ></ScheduleSettingUI>}

{this.state.snackbarProps.open &&
    <CustomizedSnackbar onClose={this.handleCloseSnackbar} {...this.state.snackbarProps}></CustomizedSnackbar>}

</Box>
)
}

}


export default AddedAssessment;


const MsbTooltip = withStyles((theme)=>({
    tooltip: {
        backgroundColor: '#3d3d40',
        color: '#fffff',
         boxShadow: theme.shadows[1],
        fontSize: 11,
        transparent:0.2
    }
  }))(Tooltip);