import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Typography,Button,Paper,Tabs,Tab } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import FilterDropdown from './FilterDropdown';
import { useState, useEffect,useRef} from 'react';
import MORE_APPS_CONFIG from '../../../moreApps';
import Toast from '../../../ui-components/snackbar/Snackbar';
import http from 'axios';
import DeletionLog from './DeletionLog';

export default function ActivityLog(props) {
    const [open, setOpen] = React.useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(true);
    const [offset, setOffset] = React.useState(0);
    const [corporateUser, setCorporateUser] = useState(null);
    const [personData, setPersonData] = useState([]);
    const [selectedPersonFilter, setSelectedPersonFilter] = useState([]);
    const [activityDateFilter, setActivityDateFilter] = useState(null);
    const limit = 10;
    const [reUploadInProgress, setReUploadInProgress] = useState(false);
    const [reProcessInProgress, setReProcessInProgress] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastStatus, setToastStatus] = useState('');
    const [uploadLogSelected, setUploadLogSelected] = useState(true);
    const [tabValue, setTabValue] = React.useState(0);
    const [isOffset,setIsOffset]=useState(false);
    const [hasMoreDeleteLogData, setHasMoreDeleteLogData] = React.useState(true);
    


    useEffect(() => {
        let activityDataForFilter = filteredData;
        if (selectedFilters.length > 0) {
            activityDataForFilter = activityDataForFilter.filter((row) => {
                return selectedFilters.some(sf => sf['label'].toLowerCase() === row.statusMessage.toLowerCase())
            });

        }
        if (selectedPersonFilter.length > 0) {
            activityDataForFilter = activityDataForFilter.filter((row) => {
                return selectedPersonFilter.some(pf => pf === row.roleId)
            });

        }
        if (activityDateFilter != null) {
            activityDataForFilter = activityDataForFilter.filter((row) => {
                const parsedDate = new Date(row.createdOn);
                let rowDate = parsedDate.getDate() + "-" + (parsedDate.getMonth() + 1) + "-" + parsedDate.getFullYear();
                let filterDate = new Date(activityDateFilter).getDate() + "-" + (new Date(activityDateFilter).getMonth() + 1) + "-" + new Date(activityDateFilter).getFullYear();
                return rowDate === filterDate;
            });
        }
        setActivityData(activityDataForFilter)
    }, [selectedFilters, selectedPersonFilter, activityDateFilter]);

    useEffect(() => {

        http.get('/integrations/user')
            .then((response) => {
                if (response.data) {
                    setCorporateUser(response.data)
                }
            }).catch((error) => {
                if (error.response.status == 401) {

                    setCorporateUser({})
                }
            })
    }, [])


    const handleDrawerOpen = () => {
        fetchActivityLogData();
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setTabValue(0);
        setUploadLogSelected(true)
    };

    const resetLoadMore=(hasMore)=>{
        setHasMoreDeleteLogData(hasMore)
    }

    const fetchActivityLogData = async () => {
        if (loading || !hasMore) return;

        try {
            setLoading(true);
            const activityResponse = await fetch(`/integrations/ripplehire/getExcelDetails?limit=${limit}&offSet=${offset}`);
            const jsonActivityData = await activityResponse.json();

            if (jsonActivityData.length > 0) {
                setActivityData((prevData) => [...prevData, ...jsonActivityData]);
                setFilteredData((prevData) => [...prevData, ...jsonActivityData]);
                setOffset((prevOffset) => prevOffset + limit);

                if (corporateUser.roleId === 1) {


                    let personList = jsonActivityData.filter((val, index, self) => {
                        return index === self.findIndex((o) => o.roleId === val.roleId)
                    })
                    console.log("person list....", personList)
                    setPersonData(personList);
                }
            } else {
                // If no more data, set hasMore to false
                setHasMore(false);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    const groupDataByDate = (data) => {
        const groupedData = {};
        const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
        data.forEach((item) => {
            const date = new Date(item.updatedOn).toDateString();
            // console.log("date ", date)
            if (!groupedData[date]) {
                groupedData[date] = [];
            }

            groupedData[date].push(item);
        });

        return groupedData;
    };
    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return `${month}/${day}/${year}`;
    };
    const handleScroll = (event) => {
        // Check if the scroll has reached the bottom
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        setIsOffset(false);
        // Check if the user has scrolled close to the bottom and there's more data
        if(!uploadLogSelected){
            handleScrollOnDelation(event);
        }
        if (scrollHeight - scrollTop <= (clientHeight + 100) && hasMore &&!loading ) {
            if(uploadLogSelected){
                fetchActivityLogData();
            }
            // else{
            //     setIsOffset(true);
            //      }
            }
    };

    const handleScrollOnDelation = (event) => {
        // Check if the scroll has reached the bottom
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        setIsOffset(false);
        // Check if the user has scrolled close to the bottom and there's more data
        if (scrollHeight - scrollTop <= (clientHeight + 100) && hasMoreDeleteLogData  ) {
             setIsOffset(true);    
           
        }
    };

    async function handleDownloadErrorLogFile(errorFileUrl) {
        try {
            const response = await fetch(errorFileUrl);

            if (!response.ok) {
                throw new Error('Some error occurred');
            }

            const excelBlob = await response.blob();
            const excelUrl = window.URL.createObjectURL(excelBlob);
            const anchor = document.createElement('a');
            anchor.href = excelUrl;
            anchor.click();
            window.URL.revokeObjectURL(excelUrl);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    function handleReuploadFile(id) {
        const fileInput = document.getElementById('reUploadFileInput');
        fileInput.click();
        fileInput.addEventListener('change', (event) => handleFileSelect(event, id));

        async function handleFileSelect(event, id) {
            const fileInput = event.target;

            if (fileInput.files.length === 0) {
                return;
            }

            const selectedFile = fileInput.files[0];
            const formData = new FormData();
            formData.append('excelFile', selectedFile);

            console.log('Selected file:', selectedFile);
            console.log('Reupload file id:', id);

            try {
                const reuploadDataApiUrl = `/integrations/ripplehire/uploadJobListWithCorrectionForRippleHire?excelId=${id}`;
                setReUploadInProgress(true);

                const response = await fetch(reuploadDataApiUrl, {
                    method: 'POST',
                    body: formData,
                });

                const responseText = await response.text();
                console.log('Response text:', responseText);

                if (response.ok) {
                    console.log('Response ok');
                    setToastMessage("File reuploaded successfully.");
                    setToastStatus("SUCCESS")
                    setShowToast(true);

                } else {
                    console.log('Response not okay');
                    setToastMessage(responseText);
                    setToastStatus("FAIL")
                    setShowToast(true);
                }
            } catch (error) {
                console.error('Error occurred while reuploading:', error);
                setToastMessage("Error while ReUpload the error excelFile");
                setToastStatus("FAIL")
                setShowToast(true);

            } finally {
                setShowToast(false);
                setReUploadInProgress(false);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            }
        }
    }

    async function handleReProcessFile(id) {

        try {
            const reProcessDataApiUrl = `/integrations/ripplehire/uploadExcelCronJob?excelId=${id}`;
            setReProcessInProgress(true);
            const response = await fetch(reProcessDataApiUrl, {
                method: 'POST',
            });

            const responseText = await response.text();
            console.log('Response text:', responseText);
            if (response.ok) {
                console.log('Response ok');
                setToastMessage("File reprocessed successfully.");
                setToastStatus("SUCCESS")
                setShowToast(true);

            } else {
                console.log('Response not okay for reprocess');
                setToastMessage(responseText);
                setToastStatus("FAIL")
                setShowToast(true);
            }

        } catch (error) {
            console.error('Error occurred while reprocessing:', error);
            setToastMessage("Error while reprocessing. Please try again.");
            setToastStatus("FAIL")
            setShowToast(true);

        } finally {
            setShowToast(false);
            setReProcessInProgress(false);
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        }

    }

    const handleFilterChange = (filters, filterName) => {
        if (filterName === "Activity_Type") {
            setSelectedFilters(filters);
        } else if (filterName === "Person") {
            setSelectedPersonFilter(filters);
        } else if (filterName === "Activity_Date") {
            setActivityDateFilter(filters);
        }


    };
   

  const onClickTab = (event, newValue) => {
    setTabValue(newValue);
   
    if(newValue==0){
        setUploadLogSelected(true)
    }else{
        setUploadLogSelected(false)
    }
  };

   
    // console.log("activity data", activityData)
    const groupedData = groupDataByDate(activityData);

    return (
        <React.Fragment>
            <Box sx={{ background: "#4E6287", width: "32px", height: "100vh", position: "fixed", right: 0, display: "flex", cursor: "pointer" }} onClick={handleDrawerOpen} >
                <Box sx={{ background: "#4E6287", position: "absolute", right: 30, top: 40, width: "11px", height: "42px", borderBottomLeftRadius: "15px", borderTopLeftRadius: "15px" }}>
                    <Box sx={{ position: "absolute", top: 7 }}>
                        <KeyboardArrowLeftIcon
                            htmlColor='white'
                        />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <p style={{
                        writingMode: "vertical-rl", whiteSpace: "nowrap", color: "white", fontSize: "12px", letterSpacing: "2.88px", right: "-3px", position: "absolute"
                    }}><FontAwesomeIcon icon={faDownload} className="outlined-icon" />&nbsp;&nbsp; ACTIVITY LOG</p>
                </Box>
            </Box>
            <Drawer
                anchor="right"
                open={open}
                // onScroll={handleScroll}
                onClose={handleDrawerClose}
            >
                <div onScroll={handleScroll} style={{ overflowY: 'auto', height: '100%' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ position: "fixed", background: "#ffffff", width: "540px", padding: "0px 30px", borderBottom: "1px solid #E5EDF4", zIndex: 1 }}>
                    {/* <p style={{ color: "#001F52", fontFamily: "NotoSans-Regular" }}>Activity Log</p> */}
                    <Box >
                {/* <Button disableRipple style={{color: "#001F52", fontFamily: "NotoSans-Regular",borderBottom:'3px solid',borderRadius:'0px',background:'transparent'}}>
                    <Typography  style={{color: "#001F52", fontFamily: "NotoSans-Regular",fontSize:'16px',padding:'8px'}} onClick={onClickUploadLog}>Upload Log</Typography>
                    </Button>

                    <Button disableRipple  style={{color: "#001F52", fontFamily: "NotoSans-Regular",borderBottom:'3px solid',borderRadius:'0px',background:'transparent'}}  onClick={onClickDeletionLog}>
                    <Typography  style={{color: "#001F52", fontFamily: "NotoSans-Regular",fontSize:'16px',padding:'8px'}}>Deletion Log</Typography>
                    </Button> */}
                   
                        <Tabs
                           
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={onClickTab}
                            aria-label="disabled tabs example"
                        >
                            <Tab disableRipple style={{padding:'15px 16px',fontSize:'16px', fontFamily: "NotoSans-Regular",'MuiTab-textColorPrimary': {color: '#0B41AD'}}} label="Upload Log" />
                            
                            <Tab disableRipple style={{padding:'15px 16px',fontSize:'16px', fontFamily: "NotoSans-Regular",'MuiTab-textColorPrimary': {color: '#0B41AD'}}}  label="Deletion Log" />
                        </Tabs>
                        
                    </Box>
                    
                    <IconButton onClick={handleDrawerClose}
                        style={{
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <CloseIcon style={{
                            width: "20px", height: "20px", color: "#001F52", marginRight: "-24px"
                        }} />
                    </IconButton>
                </Box>
                { uploadLogSelected &&<Box width={536} px={4}>

                    <Box style={{ marginTop: '75px', display: 'flex' }}>
                        <Typography style={{ fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#8096B2', display: 'flex', paddingTop: '6px' }}>Filter by</Typography>
                        <FilterDropdown onFilterChange={handleFilterChange} personData={personData} />

                    </Box>
                    <Box style={{ marginTop: "30px" }}>
                        {Object.entries(groupedData).map(([date, activityData], index, array) => (
                            <React.Fragment key={date}>

                                <p style={{ fontSize: "12px", fontFamily: "NotoSans-Regular", fontWeight: 600 }}>{date}</p>

                                {activityData.map((activity, index) => {

                                    function getActivityIcon(activityStatus) {
                                        switch (activityStatus) {
                                            case "SUCCESSFULLY_UPLOADED":
                                                return <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/processing-successful-with-error.svg`} alt="Image Description" style={{ width: 16, height: 16, marginTop: 2 }} />
                                            case "SUCCESS_WITH_ERROR":
                                                return <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/processing-successful-with-error.svg`} alt="Image Description" style={{ width: 11, height: 11, marginTop: 2 }} />
                                            case "SUCCESS":
                                                return <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/upload-successful.svg`} alt="Image Description" style={{ width: 16, height: 16, marginTop: 2 }} />
                                            case "FAIL":
                                                return <img src={`${MORE_APPS_CONFIG.lmsFullPath}/assets/ripplehire/error.svg`} alt="Image Description" style={{ width: 11, height: 11, marginTop: 2 }} />
                                        }
                                    }

                                    let currentTimestamp = activity.updatedOn;
                                    let date = new Date(currentTimestamp);
                                    let hours = date.getHours();
                                    let minutes = date.getMinutes().toString().padStart(2, '0');
                                    // console.log("activity", activity)

                                    return (
                                        <Box key={index} marginTop={1} style={{ border: "1px solid var(--background-blue-gray-100, #E5EDF4)", borderRadius: "4px", boxShadow: "gb(50 57 64 / 14%) 0px 1px 5px 0px" }}>
                                            <Box padding={2} display="flex" flexDirection="column">
                                                <Box display="flex">
                                                    {getActivityIcon(activity.status)}
                                                    <Box marginLeft={1}>
                                                        <p style={{ color: "#8096B2", fontFamily: "NotoSans-Regular", fontSize: 12, marginTop: 0, marginBottom: 4 }}>{activity.excelFileName}</p>
                                                        <p style={{ color: "#4E6287", fontFamily: "NotoSans-Regular", fontSize: 14, marginTop: 0, marginBottom: 0 }}>{activity.statusMessage}</p>
                                                        {activity.errorFileUrl &&
                                                            <Box marginTop={1}>
                                                                <p style={{ color: "#8096B2", fontFamily: "NotoSans-Regular", fontSize: 12, marginTop: 0, marginBottom: 4 }}>Rows processed: <span style={{ color: "#202020" }}>{`${activity.successCount}/${activity.totalCount}`}</span></p>
                                                                {(activity.successCount != activity.totalCount) &&
                                                                    <Box style={{ marginTop: "20px", marginBottom: 5 }} >
                                                                        <p style={{ color: "#0B41AD", fontFamily: "NotoSans-Regular", fontSize: 12, marginTop: 0, marginBottom: 4, border: "1px solid #0B41AD", borderRadius: "4px", padding: "4px 16px", display: "inline", cursor: "pointer" }} onClick={() => handleDownloadErrorLogFile(activity.errorFileUrl)}>Download Error Log</p>
                                                                        <p style={{ color: "#001F52", fontFamily: "NotoSans-Regular", fontSize: 12, marginTop: 0, marginBottom: 4, border: "1px solid #BED3E4", borderRadius: "4px", padding: "4px 16px", display: "inline", marginLeft: "10px", cursor: "pointer" }} onClick={() => handleReuploadFile(activity.id)} >Re-upload File</p>
                                                                        <input
                                                                            accept=".xlsx, .xls, .csv"
                                                                            id="reUploadFileInput"
                                                                            type="file"
                                                                            style={{ display: 'none' }}
                                                                            onChange={() => handleReuploadFile(activity.id)}
                                                                        />
                                                                    </Box>
                                                                }

                                                            </Box>
                                                        }
                                                        {activity.status === "SUCCESSFULLY_UPLOADED" &&
                                                            <Box marginTop={1}>
                                                                <p style={{ color: "#8096B2", fontFamily: "NotoSans-Regular", fontSize: 12, marginTop: 0, marginBottom: 4 }}>Rows processed: <span style={{ color: "#202020" }}>{`${activity.successCount}/${activity.totalCount}`}</span></p>
                                                                <Box style={{ marginTop: "20px", marginBottom: 5 }} >
                                                                    <button
                                                                        style={{ color: "#001F52", fontFamily: "NotoSans-Regular", fontSize: 12, marginTop: 0, marginBottom: 4, border: "1px solid #BED3E4", borderRadius: "4px", padding: "4px 16px", display: "inline", marginLeft: "10px", cursor: "pointer", background: "white" }} onClick={() => handleReProcessFile(activity.id)}
                                                                    >
                                                                        Re-Process File
                                                                    </button>
                                                                </Box>
                                                            </Box>
                                                        }

                                                    </Box>
                                                </Box>
                                                <Box display="flex" paddingLeft="16px" paddingTop="12px">
                                                    <p style={{ color: "#8096B2", fontSize: 12, fontFamily: "NotoSans-Regular", marginTop: 0, marginBottom: 0, display: "flex" }}><ScheduleIcon style={{ width: 15, height: 15, marginTop: "1px", color: "#8096B2" }} />&nbsp;{`${hours}:${minutes}`}</p>
                                                    <p style={{ color: "#8096B2", fontSize: 12, fontFamily: "NotoSans-Regular", marginTop: 0, marginBottom: 0, display: "flex", paddingLeft: "12px" }}><PersonOutlineIcon style={{ width: 15, height: 15, marginTop: "1px", color: "#8096B2" }} />&nbsp;{activity.clientName}</p>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })}

                                {index !== array.length - 1 && <Divider style={{ backgroundColor: "#BED3E4", marginLeft: '15px', width: '1px', marginTop: "30px", width: "200px" }} />}
                            </React.Fragment>
                        ))}

                        {/* Show a loading indicator if loading */}
                        {loading && <p style={{ color: "#4e6287", fontFamily: "NotoSans-Regular", fontSize: 14, marginBottom: 4, marginTop: "10px", textAlign: "center" }}>loading...</p>}

                        {/* Show a message when no more data is available */}
                        {!loading && !hasMore && <p style={{ color: "#4e6287", fontFamily: "NotoSans-Regular", fontSize: 14, marginBottom: 4, marginTop: "10px", textAlign: "center" }}>no more data.</p>}

                    </Box>

                    {reUploadInProgress && <Toast message="File reupload in progress." status={"IN_PROGRESS"} />}
                    {reProcessInProgress && <Toast message="File reprocess in progress." status={"IN_PROGRESS"} />}
                    {showToast &&
                        <Toast message={toastMessage} status={toastStatus} />
                    }
                </Box>}
                
                 { !uploadLogSelected &&
                <Box width={536} px={4} >
                    <DeletionLog resetLoadMore={resetLoadMore}  isOffset={isOffset}></DeletionLog>
                </Box>}
                </div>
            </Drawer>
        </React.Fragment >

    );
}
