import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Container, Box, Input, Icon, IconButton, Tooltip, Typography, Button, Grid, Paper, Checkbox } from '@material-ui/core';
import http from 'axios';
import SerachIcon from '@material-ui/icons/Search';
import MORE_APPS_CONFIG from '../../moreApps';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ProceedIntegrationDialog from './ProceedIntegrationDialog';
import CustomizedSnackbar from '../shared/CustomizedSnackbar';
import Error from '../shared/Error';
const useStyles = makeStyles({
    card: {
        display: 'flex',
        width: '366px',
        padding: '16px 24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        borderRadius: '4px',
        border: '1px solid #D1E0EC',
        background: ' #FFF',
        boxShadow: 'none'
    },
    checkboxColor: {
        "&.MuiCheckbox-colorPrimary.Mui-checked": {
            color: '#2C6EF2'
        },
        '&.MuiSvgIcon-root': { fontSize: '18' }
    },
    table: {
        minWidth: 650
    },
    searchContainer: {
        marginBottom: 1
    },
    cell: {
        padding: '14px 45px', // Adjust the padding values here
    }
});

export default function AppClientMapping(props) {
    const [appList, setAppList] = useState([{
        appName: "",
        checked: false
    }]);
    const [corporateUser, setCorporateUser] = useState({});
    const [openPopup, setOpenPopup] = useState(false);
    const [count, setCount] = useState(0);
    const [selectedApps, setSelectedApps] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" });
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const [status, setStatus] = useState(null)
    const [message, setMessage] = useState("")




    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const proceedIntegrationDialogClose = (snackProp) => {
        setSnackbarProps(snackProp)
        setOpenPopup(false)

    }

    const proceedButtonClicked = (event) => {
        if (selectedApps.length > 0) {
            setOpenPopup(true);
        } else {
            setOpenPopup(false);
        }
    }
    const handleChange = (event, appName) => {
        const { name, value, type, checked } = event.target;
        const itemIndex = filteredData.findIndex(item => item.appName === appName);
        const newArray = [...appList];

        if (selectedApps.includes(appName)) {
            newArray[itemIndex].checked = false;

            let array = selectedApps.filter(item => item !== appName);
            setSelectedApps(array);
            //selectedApps.splice(itemIndex, 1);
            setAppList(newArray);
            setCount(count - 1);
        } else {
            newArray[itemIndex].checked = true;
            setAppList(newArray);
            selectedApps.push(appName);
            setCount(count + 1);
        }
    }


    useEffect(() => {
        getUserInfo();
    }, [])

    const getAllApps = (cUser) => {
        http.get('/integrations/apps/all-apps')
            .then((response) => {
                if (response.data) {
                    response.data.sort(function (a, b) {
                        return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
                    })
                    let extractedData = [];
                    let appsAllowed=null;
                    if('appsAllowed' in cUser.clientDto){
                         appsAllowed = cUser.clientDto.appsAllowed.match(/\w+(?:\.\w+)*(?:\s+\w+)?/g);
                    }

                    //let appsAllowed=cUser.clientDto.appsAllowed.match(/\w+(?:\s+\w+)*/g);
                     
                    if (!cUser.clientEnabled) {
                        extractedData = response.data.map(item => ({ appName: item.name, checked: false, logoUrl: item.logoUrl }));
                    } else {
                        if (appsAllowed != null) {
                            extractedData = response.data.map(item => ({ appName: item.name, checked: appsAllowed.includes(item.name) ? true : false, logoUrl: item.logoUrl }));
                            setSelectedApps(appsAllowed);
                            setCount(appsAllowed.length)
                        }
                        else {
                            extractedData = response.data.map(item => ({ appName: item.name, checked: false, logoUrl: item.logoUrl }));
                            setSelectedApps([]);
                            setCount(0)
                        }

                    }
                    setAppList(extractedData)
                    console.log("extracted data...", extractedData)
                    setFilteredData(extractedData)
                }
            }).catch((error) => {

            })
    }

    const getUserInfo = () => {
        http.get('/integrations/user')
            .then((response) => {
                if (response.data) {
                    setCorporateUser(response.data);
                    if (response.data['supportUser']) {
                        getAllApps(response.data);
                    } else {
                        setStatus("403")
                        setMessage("You don't have permission to access this page")
                    }
                }
            }).catch((error) => {

            })
    }
    useEffect(() => {
        console.log("inside serch term")
        var filterdList = [...appList];
        filterdList = appList.filter((row) =>
            row.appName.toLowerCase().includes(searchTerm.toLowerCase()));

        setFilteredData(filterdList);
    }, [searchTerm]);

    const handleCloseSnackbar = () => {
        setSnackbarProps({ ...snackbarProps, open: false, variant: "", message: "" });

    }
    return (
        <div>
            {snackbarProps.open &&
                <CustomizedSnackbar onClose={handleCloseSnackbar} {...snackbarProps}></CustomizedSnackbar>}

            {corporateUser.supportUser && <div>
                <Container component={Paper} maxWidth="false" style={{ position: 'sticky', top: '64px', zIndex: '1', height: '62px', padding: '11px 40px', borderBottom: 'solid 1px', borderBottomColor: '#BED3E4', boxShadow: 'none' }} >
                    <div className={classes.searchContainer}>


                        <Box style={{ display: "flex", justifyContent: 'space-between' }} >
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/home.svg'} style={{ paddingRight: '2px' }} alt="home"></img>
                                <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/chevron-right.svg'} style={{ paddingRight: '2px' }} alt=""></img>
                                <Typography style={{ color: "#001F52", fontFamily: 'NotoSans-Regular', fontSize: '14px' }}>Global Settings</Typography>
                                <img height="16px" width="16px" src={MORE_APPS_CONFIG.lmsFullPath + '/images/integration/chevron-right.svg'} style={{ paddingRight: '2px' }} alt=""></img>
                                <Typography style={{ color: "#001F52", fontFamily: 'NotoSans-Regular', fontSize: '14px' }}>Map Client Integrations</Typography>
                            </Box>
                            <Box display="flex" mx={2} alignItems="center" flexWrap="nowrap" border={1} borderColor="#BED3E4" borderRadius={5} style={{ width: '460px', height: '40px', margin: '0px' }} >

                                <Box px={1} py="3px" minWidth={300} flexGrow={1} >


                                    <Input
                                        id="input-with-icon-adornment"
                                        onChange={handleSearchChange}
                                        disableUnderline
                                        placeholder={`Search By Integration `}
                                        fullWidth
                                        value={searchTerm}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SerachIcon color='#4E6287' />
                                            </InputAdornment>
                                        }
                                        color='#8096B2'
                                        style={{ fontFamily: 'NotoSans-Medium', color: '#8096B2' }}
                                    />

                                </Box>

                            </Box>
                        </Box>
                    </div>
                </Container>
                <Container style={{ padding: '24px 46px 0px 46px', maxWidth: '100%' }}>
                    <Box style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'flex-start', gap: '32px', width: '100%' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flexStart', gap: '8px' }}>
                            <Typography style={{ color: '#001F52', fontFamily: 'NotoSans-Regular', fontSize: '16px' }}>Partner Integrations</Typography>
                            <Typography style={{ color: '#4E6287', fontFamily: 'NotoSans-Regular', fontSize: '12px' }}>Select the partner to enable integration</Typography>
                        </Box>

                        <Grid container style={{ gap: '24px',marginBottom:'90px' }}>

                            {filteredData.map((val, index) => (
                                // <Grid item xs={4} spacing={3} style={{ flexBasis: 0 }}>
                                    <Card className={classes.card} style={{ borderColor: val.checked ? '#2C6EF2' : '#D1E0EC' }}>
                                        <CardContent style={{ padding: '0px', width: '100%' }}>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box style={{ display: 'flex' }}>
                                                    <img height="24px" width="24px" src={val.logoUrl != null ? val.logoUrl : `${MORE_APPS_CONFIG.lmsFullPath}/Rectangle.svg`} alt="error" />
                                                    {val.logoUrl === null && <Typography style={{
                                                        color: '#001F52', fontFamily: 'inherit',
                                                        fontSize: '16px', paddingLeft: '7px', position: 'absolute'
                                                    }}>{val.appName.charAt(0).toUpperCase()}</Typography>}

                                                    <Typography style={{ color: '#001F52', fontFamily: 'NotoSans-Regular', fontSize: '14px', paddingLeft: '12px' }}>{val.appName}</Typography>
                                                </Box>
                                                <Checkbox className={classes.checkboxColor} name="appSelect" color="primary" onChange={(e) => handleChange(e, val.appName)}
                                                    checked={val.checked} style={{ padding: "0px", fontSize: '20px' }} />

                                            </Box>

                                        </CardContent>
                                    </Card>

                                // </Grid>
                            ))}
                        </Grid>


                    </Box>

                </Container>
                <Container component={Paper} maxWidth="false" style={{
                    position: 'fixed', bottom: 0, zIndex: '1', height: '66px', padding: '11px 40px',
                    boxShadow: 'none', borderRadius: '0px 0px 4px 4px', backgroundColor: '#fff', borderTop: 'solid 1px', borderColor: '#BED3E4', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                }} >
                    <Box style={{ display: 'inline-flex', gap: '11px', alignItems: 'center' }}>
                        <Typography style={{ color: '#001F52', fontFamily: 'NotoSans-Regular', fontSize: '14px' }}>Total clients selected:</Typography>
                        <Box style={{ display: 'flex', padding: '4px 16px', alignItems: 'center', gap: '10px', borderRadius: '16px', background: '#0e2b721a' }}>
                            {count.toString().padStart(2, '0')}
                        </Box>
                    </Box>
                    <Button onClick={proceedButtonClicked} style={{
                        display: 'flex', padding: '10px 16px', justifyContent: 'center', alignItems: 'center', gap: '8px', borderRadius: '4px',
                        background: '#0B41AD', fontFamily: 'NotoSans-Regular', fontSize: '14px', color: '#fff', textTransform: 'capitalize'
                    }}>
                        Proceed
                    </Button>
                </Container>
                {openPopup && <ProceedIntegrationDialog openDialog={openPopup} selectedApps={selectedApps} proceedIntegrationDialogClose={proceedIntegrationDialogClose} ></ProceedIntegrationDialog>}

            </div>}
            {status && <Error status={status} page="corporate" message={message}></Error>}
        </div>
    )
}