import React, { useState, useEffect } from 'react';
import AppList from './appList';
import AddApp from './AddApp';
import http from 'axios';
import Error from '../shared/Error';
import CustomizedSnackbar from '../shared/CustomizedSnackbar';


export default function AppConfig(props){
const[showList,setShowList]=useState(true)
const[isEditApp,setIsEditApp]=useState(false)
const[appEditData,setAppEditData]=useState({})
const[open,setOpen]=useState(false)
const [status, setStatus] = useState(null)
const [message, setMessage] = useState("")
const [snackbarProps, setSnackbarProps] = useState({ open: false, message: "", variant: "" });

const addButtonClicked = () => {
    console.log("add button clicked")
    setShowList(false);
    setIsEditApp(false);
    setOpen(true)
   
}

const editButtonClicked = (val) => {
    console.log("edit button clicked",val)
    setOpen(true);
    setAppEditData(val);
    setIsEditApp(true);
    setShowList(false);
    

   
}

const handleCloseDialog = (val) => {
    setOpen(val)
    setShowList(true);
    setIsEditApp(false);
    

}


useEffect(() => {
    console.log("props", props)
    http.get('/integrations/user')
        .then((response) => {
            if (response.data) {
               
                if (!(response.data.appPageAccessible)) {
                    setStatus("403")
                    setMessage("You don't have permission to access this page")
                }

            }
        }).catch((error) => {
            if (error.response.status == 401) {
                console.log("error==>", error)
                setLoggedIn(false)
                setStatus("401")
                  redirectToLogin()
            }
            
        })
}, []);



return (
        <div>
           
             {(status!=null ) && <Error status={status} page="corporate" message={message}></Error>}
             {(status===null) &&  <AppList  addButtonClicked={addButtonClicked} editButtonClicked={editButtonClicked}></AppList>}
           {!showList && <AddApp open={open} isEditApp={isEditApp} appEditData={appEditData} handleCloseDialog={handleCloseDialog}></AddApp>}
        </div>
    )
}