import React, { Component } from 'react';
import { Box, Card, CardContent, Button, Typography, Grid, TextField, Icon, InputAdornment, Divider, CardHeader } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

class AddAssessmentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assessments: []
        }
        this.handleSearchAssessments = this.handleSearchAssessments.bind(this);
    }

    componentDidMount() {
        this.setState({
            assessments: this.props.assessments
        });
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         assessments: nextProps.assessments
    //     });
    // }

    handleSearchAssessments(e) {
        let currentList = [];
        let newList = [];
        let searchValue = e.target.value.trim();
        if (searchValue !== "") {
            currentList = this.props.assessments;
            newList = currentList.filter(item => {
                const lc = item.name.toLowerCase();
                const filter = searchValue.toLowerCase();
                return lc.includes(filter);
            });
        } else {
            newList = this.props.assessments;
        }
        this.setState({
            assessments: newList
        });
    }

    render() {

        //let assessments = this.props.assessments || [];

        return (
            <Box mt={6}>
                <Card>
                    <CardHeader disableTypography title={
                        <Box display="flex" alignItems="center">
                            <Box>
                                <Typography color="textSecondary" variant="subtitle1">Hello {this.props.ltiInfo.user},</Typography>
                                <Typography variant="h5" color="textPrimary">
                                    Add Assessment to <Typography component="span" variant="h5" color="primary">{this.props.ltiInfo.course}, {this.props.ltiInfo.activity}</Typography>
                                </Typography>
                            </Box>
                            <Box ml="auto">
                                <TextField
                                    margin="dense"
                                    variant="outlined"
                                    label="Search Assessments..."
                                    onChange={this.handleSearchAssessments}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <Icon color="primary" fontSize="small">search</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                    }>
                    </CardHeader>
                    <CardContent style={{ padding: 0 }}>

                        <Box mb={2} className="bg-light-gray">
                            <Divider></Divider>
                            <Box display="flex" fontSize="small" alignItems="center">
                                <Box p={2}>
                                    Assessment Name
                                </Box>
                                <Box p={2} ml="auto">
                                    Actions
                                </Box>
                            </Box>
                            <Divider></Divider>
                        </Box>

                        <Box mb={2}>
                            {this.state.assessments.map(assessment =>
                                <Grid container key={assessment.id} className="show-on-hover highlight-bg-lightblue">
                                    <Grid item xs={8} md={9}>
                                        <Box display="flex" px={2} py={2} alignItems="center">
                                            <Icon fontSize="small" mr={2}>insert_drive_file</Icon>
                                            <Typography style={{ paddingLeft: 5 }}>{assessment.name}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <Box py={1} px={2} textAlign="right">
                                            <Button
                                                className="fade-in"
                                                onClick={this.props.handlePickAssessment.bind(this, assessment)}
                                                variant="contained"
                                                color="primary"
                                                startIcon={<FontAwesomeIcon style={{ fontSize: "12px" }} icon={faPlus} />}
                                            >Add</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Box >
        )
    }

}

export default AddAssessmentComponent;