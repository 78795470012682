import React, { Component } from 'react'

import { Dialog, DialogActions, DialogTitle, DialogContent, Typography, Button, Box } from '@material-ui/core'

class DialogComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  componentDidMount = () => {
    this.setOpen(this.props.open)
  }

  setOpen = (isOpen) => {
    this.setState({
      open: isOpen
    })
  }

  handleClose = (isSaved) => {
    this.setOpen(false)
    this.props.onClose(isSaved)
  }

  render() {
    return (
      <Dialog PaperProps={{style:{maxWidth:"650px"}}} open={this.state.open} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography >
          <Typography variant="h6" component="div">
            <Box>{this.props.dialogTitle}</Box>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography component="div">
            <Box alignItems="center" py={2} px={3}>
              {this.props.dialogContent}
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box p={1}>
            <Button onClick={this.handleClose.bind(this, false)}>Cancel</Button>
            {' '}
            <Button onClick={this.handleClose.bind(this, true)} variant="contained" color={this.props.dialogButtonColor} >
              {this.props.dialogButtonText}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}

export default DialogComponent;