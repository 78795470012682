import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState, useEffect } from 'react';
import Breadcrumb from '../ui-components/breadcrum/Breadcrum';
import Toast from '../ui-components/snackbar/Snackbar';
import FullWidthContainerWrapper from '../ui-components/wrapper/FullWidthContainerWrapper';
import ActivityLog from './ripplehire/activitylog/ActivityLog';
import JobDetailsTable from './ripplehire/jobdetailstable/JobDetailsTable';
import http from 'axios';
import Error from './shared/Error';

export default function RippleHire(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastStatus, setToastStatus] = useState('');
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [errorStatus, setErrorStatus] = useState("")
    const [message, setMessage] = useState("")

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        console.log("props", props)
        http.get('/integrations/user')
            .then((response) => {
                if (response.data) {

                    if (!response.data.clientEnabled) {
                        setErrorStatus("403")
                        setMessage("Please contact your Account Manager to enable Integrations")
                    } else if (!response.data.validIntegrationsUser) {
                        setErrorStatus("403")
                        setMessage("Please contact your Administrator to enable role permissions to access Integrations")
                    }
                    // else if (!response.data.appEnabled) {
                    //     setErrorStatus("403")
                    //     setMessage("Please contact your Administrator to enable client app mapping")
                    // } 
                }
            })
    }, []);


    const [searchValue, setSearchValue] = useState('');
    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };
    async function handleExcelUpload(event) {
        const fileInput = event.target;

        if (fileInput.files.length === 0) {
            return;
        }

        const file = fileInput.files[0];
        const formData = new FormData();
        formData.append('excelFile', file);

        const uploadDataApiUrl = `/integrations/ripplehire/uploadJobListForRippleHire`;
        try {
            setUploadInProgress(true);
            const response = await fetch(uploadDataApiUrl, {
                method: 'POST',
                body: formData
            });
            console.log("ripplehire file upload response ", response)
            const responseText = await response.text();
            console.log('Response text for upload:', responseText);

            if (response.ok) {
                console.log("filename uploaded ", file.name);

                const toastResponse = await fetch(`/integrations/ripplehire/getExcelDetails?limit=1&offSet=0`);
                if (toastResponse.ok) {
                    const toastData = await toastResponse.json();
                    const latestToast = toastData.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
                    const toastMessage = latestToast[0].statusMessage==="File processing successful (with Error)"?"Some error occurred while uploading.Please review the error file and upload again.":latestToast[0].statusMessage;
                    const toastStatus = latestToast[0].status;
                    console.log("toast data ", toastMessage)
                    setToastMessage(toastMessage);
                    setToastStatus(toastStatus);
                    setShowToast(true);

                } else {
                    console.log('Fetching message from getExcelDetails API failed');
                }

            } else {
                console.log('Upload failed');
                setToastMessage(responseText);
                setToastStatus("FAIL");
                setShowToast(true);

            }
        } catch (error) {
            console.error('Error occurred while uploading.', error);
            setToastMessage('Error in uploading job mapping excel.');
            setToastStatus("FAIL");
            setShowToast(true);
        } finally {
            setUploadInProgress(false);
            setShowToast(false);
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        }
    }



    const handleDownloadTemplate = async () => {
        try {

            //const excelFileURL = 'http://localhost:6767/rippleHire/downloadJobMappingExcel?clientPublicKey=f208530f-1cfb-474e-8b7b-869d228d5879&clientPrivateKey=308245aa-44ec-4c95-b6ad-ef47fbd80ea4&clientId=1241561';
            const excelFileURL = `/integrations/ripplehire/downloadJobMappingExcel?`;
            const response = await fetch(excelFileURL);

            if (!response.ok) {
                throw new Error('Some error occured');
            }

            const excelBlob = await response.blob();
            const excelUrl = window.URL.createObjectURL(excelBlob);
            const anchor = document.createElement('a');
            anchor.href = excelUrl;
            anchor.download = 'RippleHire_Download_Template.xlsx'; // Set the desired file name.
            anchor.click();
            window.URL.revokeObjectURL(excelUrl);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // const getToastProps = () => {
    //     switch (true) {
    //         case uploadInProgress:
    //             return {
    //                 message: 'Upload in progress. Please wait...',
    //                 status: 'IN_PROGRESS',
    //             };
    //         // case uploadInCorrectFormat:
    //         //     return {
    //         //         message: 'Please upload in the correct file format.',
    //         //         status: 'FAIL',
    //         //     };
    //         default:
    //             return {
    //                 message: '',
    //                 status: '',
    //             };
    //     }
    // };

    // useEffect(() => {
    //     const { message, status } = getToastProps();
    //     setMessage(message);
    //     setStatus(status);
    // }, [showToast, uploadInProgress]);
    // const { message, status } = getToastProps();
    return (
        <React.Fragment>
            {(errorStatus === "") && <div>
                <Breadcrumb text="RippleHire" />
                <ActivityLog />
                <FullWidthContainerWrapper sx={{ pt: 3 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Box width={300} display="flex" sx={{ border: "1px solid #BED3E4", borderRadius: "4px", padding: "5px 16px" }} justifyContent="space-between" alignItems="center">
                            <Box >
                                <InputBase
                                    placeholder="Search by Job Name/Source"
                                    inputProps={{ 'aria-label': 'search' }}
                                    styles={{
                                        fontFamily: "NotoSans-Regular", fontSize: "14px", color: "#8096B2",
                                        ".MuiInputBase-root": {
                                            width: "250px !important"
                                        }
                                    }}
                                    value={searchValue}
                                    onChange={handleSearchInputChange}
                                />
                            </Box>
                            <Box>
                                <SearchIcon style={{ color: "#8096B2", width: "16px", height: "16px" }} />
                            </Box>
                        </Box>
                        <Button
                            variant="outlined"
                            startIcon={<AddBoxOutlinedIcon style={{ width: "16px", height: "16px", color: "#001F52" }} />}
                            endIcon={<KeyboardArrowDownOutlinedIcon style={{ width: "16px", height: "16px", color: "#001F52" }} />}
                            style={{ padding: "10px 16px", border: "1px solid #BED3E4", marginRight: 50 }}
                            onClick={handleClick}

                        >
                            <p style={{ color: "#001F52", margin: 0, fontFamily: "NotoSans-Regular" }}>Add new data</p>

                        </Button>
                    </Box>

                </FullWidthContainerWrapper>
                <Menu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}

                >
                    {/* <MenuItem style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <form id="excel-upload-form">
                        <input
                            accept=".xlsx, .xls, .csv"
                            id="upload-data"
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="upload-data" onChange={handleExcelUpload}>
                            <FontAwesomeIcon icon={faUpload} className="outlined-icon" />
                            <p style={{ paddingLeft: "10px", margin: "0px" }}>Upload Data</p>
                        </label>
                    </form>
                </MenuItem>
                <MenuItem style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <div onClick={handleDownloadTemplate}>
                        <FontAwesomeIcon icon={faDownload} className="outlined-icon" />
                        <p style={{ paddingLeft: "10px", margin: "0px", display: "inline" }}>Download Templatee</p>
                    </div>
                </MenuItem> */}
                    <MenuItem onClick={() => document.getElementById('upload-data').click()}>
                        <input
                            accept=".xlsx, .xls, .csv"
                            id="upload-data"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleExcelUpload}
                        />
                        <FontAwesomeIcon icon={faUpload} className="outlined-icon" />
                        <p style={{ paddingLeft: '10px', margin: '0px', display: 'inline' }}>Upload Data</p>
                    </MenuItem>

                    {/* Download Template Button */}
                    <MenuItem onClick={handleDownloadTemplate}>
                        <FontAwesomeIcon icon={faDownload} className="outlined-icon" />
                        <p style={{ paddingLeft: '10px', margin: '0px' }}>Download Template</p>
                    </MenuItem>

                </Menu>
                <JobDetailsTable searchedJob={searchValue} />
                {uploadInProgress && <Toast message="File upload in progress." status={"IN_PROGRESS"} />}
                {showToast && <Toast message={`${toastMessage} To view, Open Activity Log`} status={toastStatus} />}
            </div>}
            {(errorStatus != "") && <Error status={errorStatus} page="corporate" message={message}></Error>}
        </React.Fragment >
    )
}
