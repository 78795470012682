import { Typography } from "@material-ui/core";
import React from "react";
const Paragraph = (props) => {
    return (
        <Typography
            variant={props.paragraphvariant || "body2"}
            // paragraph
            // gutterBottom
            align={props.align}
            color={props.white ? "white" : props.color}
            display={props.display}
            sx={{ ...props.sx }}
            className={props.className}
        >
            {props.text}
            {props.children}
        </Typography>
    );
};

export default Paragraph;
